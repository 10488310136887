import axios from 'axios';
import { getLoginUrlFromDom } from '@/services/page-context'

/**
 *
 * @param {string} url
 * @param {object} config
 * @param {string} method
 * @param formData
 * @returns {Promise}
 */
export default (url, config, method = 'GET', formData = null) => {

    if (method === 'GET') {
        return new Promise((resolve) => {
            axios.get(url, config)
                .then(response => {
                    if (response.data === null || typeof response.data !== 'object') {
                        resolve(null);
                    }
                    if (response.status === 200) {
                        resolve(response);
                    } else {
                        console.error('[ERROR] - server bad response');
                        resolve(null);
                    }
                })
                .catch(error => {
                    if (error.response !== undefined) {
                        console.error('[ERROR]', error);
                        resolve(null);
                    } else {
                        window.location.href = '/';
                    }
                });
        })
    } else if (method === 'POST') {
        return new Promise((resolve) => {
            axios.post(url, formData, config)
                .then(response => {
                    if (response.data === null || typeof response.data !== 'object') {
                        resolve(null);
                    }

                    if (response.status === 200) {
                        resolve(response);
                    } else {
                        console.error('[ERROR] - server bad response');
                        resolve(null);
                    }
                })
                .catch(error => {
                    if (error.response !== undefined) {
                        console.error('[ERROR]', error);
                        resolve(null);
                    } else {
                        window.location.href = '/';
                    }
                });
        });
    }
};