import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/clients/list/', {params});
}

/**
 * @returns Promise
 * @param id
 */
export function detail(id) {
    const params = {id: id};
    return AxiosWrapper('/clients/detail/', {params});
}

/**
 * @returns Promise
 * @param id
 */
export function detail_current(id) {
    const params = {id: id};
    return AxiosWrapper('/clients/detail_current/', {params});
}

/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/clients/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/clients/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 * @param clientId
 */
export function listAllAccounts(clientId) {
    const params = {clientId: clientId};
    return AxiosWrapper('/clients/all_accounts/', {params});
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/clients/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/clients/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElement(formData) {
    return AxiosWrapper('/clients/enable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElements(formData) {
    return AxiosWrapper('/clients/enable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElement(formData) {
    return AxiosWrapper('/clients/disable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElements(formData) {
    return AxiosWrapper('/clients/disable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}