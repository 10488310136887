<template>
    <div class="nav-scroller at-nav-scroller bg-body shadow-sm p-2">
        <nav class="nav nav-underline" aria-label="Secondary navigation">
            <div class="at-breadcrumb" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                <h2>Help</h2>
            </div>
        </nav>
    </div>
</template>
<script>
    export default {
        name: 'NavHelp',
        data() {
            return {}
        },
    }
</script>
