<template>
    <td>
        <a href="" class="">
            <i class="fas fa-file-download me-2"></i>
            {{ value }}
        </a>
    </td>
</template>
<script>
    export default {
        name: "LinkField",
        props: {
            value: {
                type: String,
                required: false,
                default: null
            }
        }
    }
</script>