<template>
    <div>
        <h6 v-if="type==='boolean'">
            <span
                    class="badge rounded-pill bg-info text-dark"
                    title="True/false"
            >
                Boolean
            </span>
        </h6>
        <h6 v-else-if="type==='text'">
            <span
                    class="badge rounded-pill bg-light text-dark"
            >
                Text
            </span>
        </h6>
        <h6 v-else-if="type==='selection'">
            <span
                    class="badge rounded-pill bg-secondary"
            >
                Selection
            </span>
        </h6>
        <h6 v-else-if="type==='date' || type==='datetime'">
            <span
                    class="badge rounded-pill bg-warning"
            >
                Date
            </span>
        </h6>
        <h6 v-else-if="type==='number' || type==='float'">
            <span
                    class="badge rounded-pill bg-primary"
            >
                Number
            </span>
        </h6>
    </div>
</template>
<script>
    export default {
        name: "CustomTextField",
        props: {
            type: {
                type: String,
                required: true
            }
        }
    }
</script>