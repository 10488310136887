import Constants from "@/helpers/constants";

export default {
    searchTerm: null,
    message: '',
    modalClient: {'id': null, 'data': null, 'show': false, 'result': null},
    modalScript: {'id': null, 'data': null, 'show': false, 'result': null},
    modalApp: {'id': null, 'data': null, 'show': false, 'result': null},
    modalAppInClient: {'id': null, 'data': null,'show': false, 'result': null, 'appId': null},
    modalMarketplace: {'id': null, 'data': null, 'show': false, 'result': null},
    modalAppsFilter: {'id': null, 'data': null, 'show': false, 'result': null},
    modalUser: {'id': null, 'data': null, 'show': false, 'result': null},
    modalTeam: {'id': null, 'data': null, 'show': false, 'result': null},
    modalResetPassword: {'id': null, 'data': null, 'show': false, 'result': null},
    modalOptionsParameters: {'id': null, 'data': null, 'show': false, 'result': null, 'origin': null},
    modalCredentials: false,
    elementsViewMode: Constants.ELEMENTS_VIEW_MODE_ALL,
    clientTeams: Constants.ALL_TEAMS,
    appsFiltered: [],
    currentClient: null,
    user: null,
    constants: null,
    appPlatform: null,
    adBlock: true
}