<template>
    <div class="card mb-3">
        <div class="card-header at-groups-level">
            <div class="at-entity-wrapper">
                <h6 data-bs-toggle="collapse"
                    :data-bs-target="'#scheduling-app-in-client-account-'+appInClientAccount.account.id+'-collapse'">
                    Scheduling</h6>
                <div class="at-nav-controls">
                    <button
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#scheduling-app-in-client-account-'+appInClientAccount.account.id+'-collapse'"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="container mt-3 mb-3 collapse"
             :id="'scheduling-app-in-client-account-'+appInClientAccount.account.id+'-collapse'">
            <script-frequency
                    @dispatch-programming-type-change="dispatchProgrammingTypeChange"
                    @dispatch-programming-execution-change="dispatchProgrammingExecutionChange"
                    :programming-type="appInClientAccount.main_programming_type"
                    :programming-execution="appInClientAccount.main_programming_execution"
            />
        </div>
    </div>
</template>
<script>
    import ScriptFrequency from "@/components/modal/commons/script-frequency";

    export default {
        name: "AppInClientAccountScheduling",
        components: {ScriptFrequency},
        props: {
            appInClientAccount: {
                type: Object,
            }
        },
        methods: {
            dispatchProgrammingTypeChange(type) {
                this.appInClientAccount.main_programming_type = type;
            },
            dispatchProgrammingExecutionChange(execution) {
                this.appInClientAccount.main_programming_execution = execution;
            }
        }
    }
</script>