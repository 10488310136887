<template>
    <ad-blocker v-if="!adBlock"/>
    <div v-else-if="!getConstants">
        <loader/>
    </div>
    <div v-else-if="currentRouteName !== 'Login' && getConstants">
        <Header/>
        <router-view/>
        <Footer/>
        <Modal/>
    </div>
    <div v-else-if="currentRouteName === 'Login' && getConstants">
        <router-view/>
    </div>
</template>

<script>
    import Header from '@/components/commons/header'
    import Footer from '@/components/commons/footer'
    import Modal from '@/components/modal/Modal'
    import Loader from '@/components/commons/loader'

    import {mapGetters} from 'vuex'
    import AdBlocker from "@/components/commons/ad-blocker";
    import {detectAnyAdblocker} from "vue-adblock-detector";
    import {getConstantsData} from "@/services/constants";

    export default {
        name: 'Index',
        components: {
            AdBlocker,
            Header,
            Modal,
            Footer,
            Loader
        },
        computed: {
            ...mapGetters(['getMessage', 'getConstants']),
            currentRouteName() {
                return this.$route.name;
            }
        },
        mounted() {
            this.getBackendConstants();
        },
        data() {
            return {
                show: false,
                adBlock: true
            }
        },
        watch: {
            '$route': {
                handler(newValue, oldValue) {
                    if (newValue && oldValue) {
                        detectAnyAdblocker().then((detected) => {
                            if (detected) {
                                this.adBlock = false;
                            }
                        });
                    }
                },
                deep: true
            }
        },
        methods:{
            async getBackendConstants() {
                let response = await getConstantsData();
                this.$store.dispatch('setConstants', {constants: response.data});
            },
        },
    }
</script>

<style lang="scss">
@import '../assets/scss/app.scss';
</style>
