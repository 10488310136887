<template>
    <div :class="[isButtonGroup ? 'btn-group': null]" :role="[isButtonGroup ? 'group': null]">
        <button
                type="button"
                class="btn btn-outline-secondary dropdown-toggle text-end at-dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="min-width:200px"
                :disabled="disabled"
        >
            <span class="at-select-search at-truncate">
                 {{ selected ? selected.label : defaultPrint }}
            </span>
        </button>
        <div
                v-if="!disabled"
                tabindex="-1"
                role="menu"
                aria-hidden="true"
                class="at-dropdown-menu dropdown-menu dropdown-menu-end"
                style="z-index:9;"

        >
            <div class="input-group at-dropdown-menu-search">
                <input
                        :value="searchTerm"
                        @input="searchTerm = $event.target.value"
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                >
                <div v-if="searchTerm === ''"
                     class="input-group-append">
                    <span class="input-group-text">
                        <i class="fas fa-search"/>
                    </span>
                </div>
                <div v-else
                     @click="eraseSearch"
                     class="input-group-append"
                >
                    <span class="input-group-text">
                        <i class="fas fa-times"/>
                    </span>
                </div>
            </div>
            <div class="scrollbar-container ps ">
                <section class="at-dropdown-items_listWrap">
                    <div class="col-xs-12">
                        <div class="divider row"></div>
                    </div>
                    <div v-if="loading" class="text-center">
                        <i class="fas fa-spinner fa-spin"></i> Loading data
                    </div>
                    <ul v-else-if="getOptionsFiltered.length > 0"
                        class="nav flex-column">
                        <li v-for="option in getOptionsFiltered"
                            @click="selected = option"
                            :key="option.id"
                            class=""
                        >
                            <a :class="['dropdown-item', (selected && selected.name === option.name) ? 'active' : null]">
                                <span class="at-dropdown-items-item at-truncate">
                                    {{ option.label }}
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div class="text-center" v-else>
                        <span class="text-muted">
                                No elements
                        </span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from "lodash"

    export default {
        name: 'SelectLabelSearch',
        props: {
            options: {
                type: Array
            },
            value: {
                type: Object
            },
            defaultPrint: {
                type: String
            },
            isButtonGroup: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                searchTerm: '',
                selected: null
            }
        },
        computed: {
            getOptionsFiltered() {
                if (this.searchTerm === '') {
                    return this.options;
                }
                return _.filter(this.options, option => _.toLower(option.name).includes(_.toLower(this.searchTerm)));
            }
        },
        watch: {
            value: {
                handler: function (newValue,) {
                    this.selected = newValue;
                },
                deep: true
            },
            selected: {
                handler: function (newValue, oldValue) {
                    if (!_.isEqual(newValue, oldValue)) {
                        this.$emit('dispatch-select-element', newValue);
                        this.eraseSearch();
                    }
                },
                deep: true
            }
        },
        methods: {
            eraseSearch() {
                this.searchTerm = '';
            }
        }
    }
</script>