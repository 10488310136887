<template>
    <div class="card mt-1">
        <div class="card-header at-campaign-level">
            <div class="at-entity-wrapper">
                <label class="form-check-label" style="margin-right: 5px">
                    <input
                            v-model="selected"
                            :value="campaign.id"
                            type="checkbox"
                            class="form-check-input"
                    >
                </label>
                <div class="at-entity">
                    <div class="at-entity-name">
                        {{ campaign.name }}
                    </div>
                    <div class="at-entity-id">
                        {{ campaign.id }}
                    </div>
                </div>
                <div class="at-nav-controls">
                    <button
                            @click="changeSectionOpened"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#c-' + campaign.id + '-aic-' + accountGroup.id +'-ac-' + appInClientAccount.id +'-collapse'"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample">
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>

        </div>
        <div class="card-body collapse"
             :id="'c-' + campaign.id + '-aic-' + accountGroup.id +'-ac-' + appInClientAccount.id +'-collapse'">
            <set-up-ad-group-level
                    :app-in-client-account="appInClientAccount"
                    :campaign="campaign"
                    :account-group="accountGroup"
                    :platform="platform"
                    :parent-opened="campaignSectionOpened"
            />
        </div>
    </div>
</template>

<script>
    import SetUpAdGroupLevel
        from "@/components/modal/modal-app-in-client/setup/account-level/group-level/campaign-level/campaign-card/adgroup-level";

    export default {
        name: "SetUpCampaignCard",
        components: {
            SetUpAdGroupLevel,
        },
        props: {
            campaign: {
                type: Object,
            },
            appInClientAccount: {
                type: Object,
            },
            accountGroup: {
                type: Object
            },
            campaignsSelected: {
                type: Array
            },
            platform: {
                type: String
            }
        },
        data() {
            return {
                selected: this.campaignsSelected,
                campaignSectionOpened: false
            }
        },
        watch: {
            selected: {
                handler: function (newValue, ){
                    this.$emit('dispatch-elements-selected', newValue);
                }
            },
            campaignsSelected: {
                handler: function (newValue, ){
                    this.selected = newValue;
                }
            }
        },
        methods: {
            changeSectionOpened(){
                this.campaignSectionOpened = !this.campaignSectionOpened;
            }
        }
    }
</script>