<template>
  <div>
        <div class="card-header">
            <div class="at-entity-wrapper">

                <div class="at-entity">
                    <div class="at-entity-name">
                        {{ appInClientScript.name }}
                    </div>
                </div>
                <div class="at-nav-controls">
                    <div v-if="isSystemUser" class="ms-2">
                        <a :class="['dropdown-item', (!appInClientScriptExists || !isAppDebuggable) ? 'at-execute-disabled': null]"
                           @click="execute_debug()">
                            <i class="fas fa-bug" style="color: #499C54"/>
                        </a>
                    </div>
                    <div class="ms-2">
                        <a :class="['dropdown-item', !appInClientScriptExists ? 'at-execute-disabled': null]"
                           @click="execute()">
                            <i class="fas fa-bolt me-2" style="color: #0a53be"/>
                        </a>
                    </div>
                    <div v-if="isAppInClientScriptDisabled">
                        <a class="dropdown-item" @click="active">
                            <i class="fas fa-play me-2" style="color: #3A7734;"/>
                        </a>
                    </div>
                    <div v-if="isAppInClientScriptEnabled">
                        <a class="dropdown-item" @click="pause">
                            <i class="fas fa-pause me-2" style="color: #880000"/>
                        </a>
                    </div>
                    <button
                            type="button"
                            data-bs-toggle="collapse"
                            href="#script2-app-collapse"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body collapse show" id="script2-app-collapse">
            <div class="container">
                <script-frequency
                                  @dispatch-programming-type-change="dispatchProgrammingTypeChange"
                                  @dispatch-programming-execution-change="dispatchProgrammingExecutionChange"
                                  :programming-type="appInClientScript['programming_type']"
                                  :programming-execution="appInClientScript['programming_execution']"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex'
    import ScriptFrequency from "@/components/modal/commons/script-frequency/index.vue";
    import {execute as Execute, execute_debug as ExecuteDebug} from "@/services/app-in-client-script";
    import {isSystem} from "@/helpers/user-roles";

    export default {
        name: "SetUpAppInClientScriptLevel",
        components: {ScriptFrequency},
        props: {
            appInClient: {
                type: Object,
                required: true
            },
            appInClientAccount: {
                type: Object,
                required: true
            },
            appInClientScript: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedScript: null,
                inputValue: null,
                selectedItems: [],
            }
        },
        computed: {
            ...mapGetters(['getModalApp','getConstants', 'getUser']),
            isAppDebuggable() {
                return this.appInClient.app.is_debuggable;
            },
            isSystemUser() {
                return isSystem(this.getUser.role);
            },
            appInClientScriptExists() {
                return Number.isInteger(this.appInClientScript.id);
            },
            isAppInClientScriptEnabled() {
                return this.appInClientScript.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
            },
            isAppInClientScriptDisabled() {
                return this.appInClientScript.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
            },
        },
        methods: {
            dispatchProgrammingTypeChange(value) {
                this.appInClientScript.programming_type = value;
            },
            dispatchProgrammingExecutionChange(value) {
                this.appInClientScript.programming_execution = value;
            },
            async execute() {
                  let response;

                  try {
                      response = await Execute(this.appInClient.id, this.appInClientAccount.id, this.appInClientScript.id);
                  } catch (e) {
                      return e;
                  }

                  if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                      this.$toast.open({
                          message: response.data.message,
                          type: 'error',
                          position: 'top-right',
                          duration: 5000,
                      });
                      return;
                  }

                  this.$toast.open({
                      message: response.data.message,
                      type: 'success',
                      position: 'top-right',
                      duration: 5000,
                  });
                },
                async execute_debug() {
                  let response;

                  try {
                    response = await ExecuteDebug(this.appInClient.id, this.appInClientAccount.id, this.appInClientScript.id);
                  } catch (e) {
                    return e;
                  }

                  if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                      message: response.data.message,
                      type: 'error',
                      position: 'top-right',
                      duration: 5000,
                    });
                    return;
                  }

                  this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                  });
              },
              pause() {
                  this.appInClientScript.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
              },
              active() {
                  this.appInClientScript.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
              },
        }
    }
</script>