import AxiosWrapper from "@/helpers/axios-wrapper";

export function getLoggedUser(){
    const params = {};
    return AxiosWrapper('/users/session/user/', {params});
}

/**
 * @returns Promise
 */
export function login(formData) {
    return AxiosWrapper('/users/login/check/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function googleLogin(formData) {
    return AxiosWrapper('/users/login/google/check/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function logout(formData) {
    return AxiosWrapper('/users/logout/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}