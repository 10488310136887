<template>
    <div :class="[isButtonGroup ? 'btn-group': null]" :role="[isButtonGroup ? 'group': null]">
        <button
                type="button"
                class="btn btn-outline-secondary dropdown-toggle text-end at-dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="min-width:200px"
                :disabled="disabled"
        >
            <span class="at-select-search at-truncate">
                 {{ defaultPrint }}
            </span>
        </button>
        <div
                v-if="!disabled"
                tabindex="-1"
                role="menu"
                aria-hidden="true"
                class="at-dropdown-menu dropdown-menu dropdown-menu-end"
                style="z-index:9;"
                @click="stopPropagation($event)"

        >
            <div class="input-group at-dropdown-menu-search">
                <input
                        :value="searchTerm"
                        @input="searchTerm = $event.target.value"
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                >
                <div v-if="searchTerm === ''"
                     class="input-group-append">
                    <span class="input-group-text">
                        <i class="fas fa-search"/>
                    </span>
                </div>
                <div v-else
                     @click="eraseSearch"
                     class="input-group-append"
                >
                    <span class="input-group-text">
                        <i class="fas fa-times"/>
                    </span>
                </div>
            </div>
            <div class="scrollbar-container ps entity-selector">
                <section class="at-dropdown-items_listWrap">
                    <div class="col-xs-12">
                        <div class="divider row"></div>
                    </div>
<!--                    <div v-if="loading" class="text-center">-->
<!--                        <i class="fas fa-spinner fa-spin"></i> Loading data-->
<!--                    </div>-->
                    <div v-if="loading" class="loader at-inner-loader" id="preload"></div>
                    <ul v-else-if="getOptionsFiltered.length > 0"
                        class="nav flex-column">
                        <li v-for="option in getOptionsFiltered"
                            :key="option.id"
                            class=""
                        >
                            <a :class="['dropdown-item']">
                                <div class="d-flex align-items-center">
                                    <input
                                            v-model="selected"
                                            :id="option.name + '-filter-multi-select-' + option.id"
                                            :value="option"
                                            type="checkbox"
                                            class="form-check-input"
                                            style="margin-right: 10px"
                                    >
                                    <Popper
                                            class="light"
                                            arrow
                                            placement="bottom"
                                            :show="isPopperActive(option.id)"
                                    >
                                        <span class="at-dropdown-items-item at-truncate"
                                          @mouseover="addShowPopper(option.id)"
                                          @mouseleave="removeShowPopper(option.id)"
                                        >
                                                    {{ option.name }}
                                        </span>
                                        <template #content>
                                            <div>{{ option.name }}</div>
                                        </template>
                                    </Popper>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="text-center" v-else>
                        <span class="text-muted">
                                No elements
                        </span>
                    </div>
                </section>
            </div>
            <div class="dropdown-menu-footer">
                <div class="divider"></div>
                <div class="d-flex align-items-center justify-content-between mt-2">
                    <button
                            @click="changeSelect"
                            class="btn btn-secondary"
                            style="margin-left: 4px"
                            type="button"
                    >
                        {{ selected.length === options.length ? 'Unselect All' : 'Select All' }}
                    </button>
                    <button
                            @click="apply()"
                            class="btn btn-success"
                            style="margin-right: 4px"
                            type="button"
                            :disabled="selected.length === 0"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from "lodash"
    import Popper from "vue3-popper";

    export default {
        name: 'MultiSelectEntitySearch',
        components: {Popper},
        props: {
            options: {
                type: Array
            },
            value: {
                type: Object
            },
            defaultPrint: {
                type: String
            },
            isButtonGroup: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                searchTerm: '',
                selected: [],
                isCloseAllowed: false,
                showPopper: []
            }
        },
        computed: {
            getOptionsFiltered() {
                if (this.searchTerm === '') {
                    return this.options;
                }
                return _.filter(this.options, option => _.toLower(option.name).includes(_.toLower(this.searchTerm)));
            }
        },
        methods: {
            eraseSearch() {
                this.searchTerm = '';
            },
            changeSelect() {
                if (this.options.length === this.selected.length) {
                    this.selected = [];
                } else {
                    this.selected = [];
                    _.forEach(this.options, (entity) => {
                        this.selected.push(entity);
                    });
                }
            },
            apply() {
                this.$emit('dispatch-select-elements', this.selected);
                this.eraseSearch();
                this.selected = [];
                this.isCloseAllowed = true;
            },
            stopPropagation(event) {
                if (this.isCloseAllowed === true) {
                    this.isCloseAllowed = false;
                } else {
                    event.stopPropagation();
                }
            },
            addShowPopper(id){
                this.showPopper.push(id);
            },
            removeShowPopper(id){
                this.showPopper = _.filter(this.showPopper, element => element !== id);
            },
            isPopperActive(id){
                return (_.find(this.showPopper, element => element === id)) !== undefined;
            }
        }
    }
</script>
<style lang="scss">
.entity-selector {
    min-height: 100px;
}
</style>