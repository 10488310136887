<template>
    <month-picker-input
            v-if="!date"
            @input="changeDate"
            :no-default="true"
            :class="[disabled  ? 'at-disabled-month-picker' : null]"
    />
    <month-picker-input
            v-else
            @input="changeDate"
            :default-month="this.dateAux.month"
            :default-year="this.dateAux.year"
           :class="[disabled  ? 'at-disabled-month-picker' : null]"
    />
</template>
<script>
    import {MonthPickerInput} from 'vue-month-picker'
    import moment from "moment";

    export default {
        name: 'MonthlyPickerField',
        props: {
            date: {
                type: Object,
                required: false,
                default: null
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {
            MonthPickerInput
        },
        data() {
            return {
                dateAux: this.date
            }
        },
        watch: {
            date: {
                handler: 'changeDateAux'
            },
        },
        methods: {
            changeDate(date) {
                if (!this.dateAux) {
                    this.dateAux = {}
                }
                this.dateAux.month = date.monthIndex;
                this.dateAux.year = date.year;
                this.$emit('dispatch-change', this.dateAux);
            },
            changeDateAux(value) {
                this.dateAux = value;
            }
        }
    }
</script>