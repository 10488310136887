<template>
    <td>
        <h6>
            <span :class="['badge', getClassPlatform()]">
                {{ platform }}
            </span>
        </h6>
    </td>
</template>

<script>
    import _ from "lodash";
    export default {
        name: "PlatformTextField",
        props: {
            platform: {
                type: String,
                required: true
            }
        },
        methods: {
            getClassPlatform(){
                return 'bg-' + _.toLower(this.platform);
            }
        }
    }
</script>