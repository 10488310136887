<template>
    <input
            type="date"
            id="table-date"
            name="table-date"
            :value="value"
    >
</template>
<script>
    export default {
        name: 'DateField',
        props: {
            value: {
                type: String
            }
        }
    }
</script>