<template>
    <div class="nav-scroller at-nav-scroller bg-body shadow-sm p-2">
        <nav class="nav nav-underline" aria-label="Secondary navigation">
            <div class="at-breadcrumb"
                 style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item"><a>Home</a></li>
                    <li class="breadcrumb-item">System</li>
                </ol>
                <h2>Scripts</h2>
            </div>

            <div class="at-nav-controls">
                <div class="input-group me-2">
                <label for="nav-users-search"/>
                <input
                        :value="searchTerm"
                        @input="searchTerm = $event.target.value"
                        id="nav-users-search"
                        type="text"
                        class="form-control"
                        placeholder="Search scripts"
                >
                <button
                        v-if="searchTerm === ''"
                        class="btn btn-outline-secondary at-icon-search-sidebar"
                        type="button"
                        id="button-addon2"
                >
                    <i class="fas fa-search"/>
                </button>
                <button @click="eraseSearch"
                        type="button"
                        class="btn btn-outline-secondary at-restore-search-sidebar"
                        id="button-addon3"
                        v-else>
                    <i class="fas fa-times"></i>
                </button>
            </div>
                <button @click="openScriptModal()"
                        class="btn btn-primary btn-sm text-nowrap"
                        data-bs-toggle="modal"
                        data-bs-target="#script-modal">
                    <i class="fas fa-plus"></i>
                    New script
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";

    export default {
        name: 'NavScripts',
        data() {
            return {
                inputValue: null,
                searchTerm: ''
            }
        },
        computed: {
            ...mapGetters(['getModalScript'])
        },
        watch: {
            searchTerm: function (newValue) {
                this.$store.dispatch('setSearchTerm', {searchTerm: newValue});
            }
        },
        methods: {
            eraseSearch() {
                this.searchTerm = '';
            },
            openScriptModal() {
                _.set(this.getModalScript, 'show', true);
            },
            setSearchTermValue() {
                this.$store.dispatch('setSearchTerm', {searchTerm: this.inputValue});
            }
        }
    }
</script>
