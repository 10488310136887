/**
 *
 * @returns {string|null}
 */
export function getLoginUrlFromDom() {
    // return window.urlSuite;
    return 'https://cat-bounce.com/'
}

export function getUserLogged() {
    return window.userLogged;
}

export function getCredentialsFromDom() {
    return window.credentials;
}