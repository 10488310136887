<template>
    <div v-if="getModalApp.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div v-if="!data" class="loader at-inner-loader" id="preload"></div>
                        <div v-if="data" class="modal-content">
                            <div v-if="saving" class="modal-overlay"></div>
                            <div :class="['modal-header', saving ? 'modal-wt-pointer' : null]">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>Apps</a>
                                        </li>
                                    </ol>
                                    <div class="d-flex align-items-baseline">
                                        <h2 class="at-truncate">{{ data.name ? data.name : 'App name' }}</h2>
                                        <div class="d-flex ms-4 form-check form-switch">
                                            <input :value="getAppChecked"
                                                   @change="setAppStatus($event.target.checked)"
                                                   v-model="appStatus"
                                                   class="form-check-input"
                                                   type="checkbox"
                                                   role="switch"
                                                   id="flexSwitchCheckChecked"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="at-nav-controls">
                                    <button
                                            @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close">
                                    </button>
                                </div>
                            </div>
                            <div :class="['modal-body p-0', saving ? 'modal-wt-pointer' : null]">
                                <form id="app-form" @submit.prevent="send()">
                                    <app-custom-detail
                                            ref="appDetail"
                                            :app="data"
                                            :key="id"
                                            class="container p-3"
                                    />
                                    <app-global-variables
                                            :app="data"
                                            class="container mt-3 collapse show"
                                    />
                                    <app-variables
                                            :app="data"
                                            class="container mt-3 collapse show"
                                    />
                                    <app-scripts
                                            :app="data"
                                            :allScripts="allScripts"
                                            class="container mt-3 collapse show"
                                    />
                                    <app-instances
                                            :app="data"
                                            :allScripts="allScripts"
                                            class="container mt-3 collapse show"
                                    />
                                </form>
                            </div>
                            <div :class="['modal-footer', saving ? 'modal-wt-pointer' : null]">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal">
                                    <i class="fas fa-times-circle me-2"></i>
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="app-form"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                            <div v-if="saving">
                                <div class="loader at-inner-loader" id="preload"></div>
                                <span class="loading" style="">Saving changes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import {detail} from '@/services/app';
    import {edit} from '@/services/app';
    import {list} from '@/services/script';
    import {mapGetters} from "vuex";
    import AppVariables from "./app-variables/index";
    import AppCustomDetail from "./app-detail/index";
    import AppScripts from "./app-scripts/index";
    import _ from "lodash";
    import {getCookie} from "@/helpers/get-cookie";
    import {store} from "@/services/app";
    import AppInstances from "@/components/modal/modal-app/app-instances";
    import AppGlobalVariables from "@/components/modal/modal-app/app-global-variables";

    export default {
        name: "ModalApp",
        components: {AppGlobalVariables, AppInstances, AppScripts, AppVariables, AppCustomDetail},
        data() {
            return {
                id: null,
                data: null,
                allScripts: [],
                appVariables: [],
                appStatus: null,
                tableConfig: {
                    columns: [
                        {property: 'client', label: 'Client', type: 'text'},
                        {property: 'name', label: 'Name + suffix', type: 'text',},
                        {
                            property: 'actions', label: 'Actions', type: 'select', options: [{
                                name: 'See app in client',
                                value: 'go_to_app_in_client'
                            }, {name: 'See client', value: 'go_to_client'}]
                        },
                    ],
                    hasCheckbox: false,
                    hasFooter: false
                },
                tableData: [],
                submitted: false,
                saving: false
            }
        },
        computed: {
            ...mapGetters(['getModalApp', 'getConstants', 'getModalOptionsParameters'])
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape' && !this.getModalOptionsParameters.show) {
                    this.close();
                }
            });
        },
        watch: {
            appStatus: {
                handler: function (newValue,) {
                    this.setAppStatus(newValue);
                }
            }
        },
        mounted() {
            this.$store.watch(state => state.modalApp, async (value) => {
                if (value.show === true) {
                    this.id = value.id;
                    await this.loadApp();
                    await this.loadAllScripts();
                }
            }, {deep: true});
        },
        methods: {
            getAppChecked() {
                if (this.data.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED) {
                    return true;
                } else {
                    return false;
                }
            },
            setAppStatus(value) {
                if (value) {
                    this.data.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
                } else {
                    this.data.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
                }
            },
            async loadApp() {
                let response;
                try {
                    response = await detail(this.id);
                } catch (e) {
                    return;
                }
                if (this.id !== null && response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering user details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }

                this.data = response.data.result;
                this.appStatus = this.getAppChecked();
            },
            async loadAllScripts() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    return;
                }
                if (this.id !== null && response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering user details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }

                this.allScripts = response.data.result;
            },
            dispatchVariableChange(value) {
                this.appVariables = value;
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            },
            close() {
                _.set(this.getModalApp, 'id', null);
                _.set(this.getModalApp, 'show', false);
                this.data = null;
                this.deleteQueryParam('appId');
            },
            send() {
                let validForm = [];
                validForm.push(this.$refs.appDetail.validateForm());

                if (!_.includes(validForm, false)) {
                    this.save();
                }
            },
            async save() {
                this.saving = true;
                let response;
                let formElement = document.querySelector('#app-form');
                let formData = new FormData(formElement);
                formData.append('id', this.id);
                formData.append('name', this.data.name);
                formData.append('status', this.data.status);
                formData.append('is_debuggable', this.data.is_debuggable);
                formData.append('is_automatic', this.data.is_automatic)
                formData.append('type', this.data.type);
                formData.append('documentation', this.data.documentation);
                formData.append('description', this.data.description);
                formData.append('file', this.$refs.appDetail.file);
                formData.append('params', JSON.stringify(this.data.params));
                formData.append('global_params', JSON.stringify(this.data.global_params));
                formData.append('app_scripts', JSON.stringify(this.data.app_scripts));
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));

                if (this.$refs.appDetail.fileChanged) {
                    formData.append('fileChanged', this.$refs.appDetail.fileChanged);
                }

                try {
                    response = this.id === null ? await store(formData) : await edit(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed applying the changes",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                _.set(this.getModalApp, 'result', response.data.result);
                this.close();
                this.saving = false;

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            }
        }
    }
</script>