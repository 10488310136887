<template>
    <div v-if="getModalClient.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div v-if="!data" class="loader at-inner-loader" id="preload"></div>
                        <div v-if="data" class="modal-content">
                            <div v-if="saving" class="modal-overlay"></div>
                            <div :class="['modal-header', saving ? 'modal-wt-pointer' : null]">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>Clients</a>
                                        </li>
                                    </ol>
                                    <div class="d-flex align-items-baseline">
                                        <h2 class="at-truncate">{{ data.name ? data.name : 'Client name' }}</h2>
                                        <div class="d-flex ms-4 form-check form-switch">
                                            <input
                                                    v-model="clientStatus"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="at-nav-controls">
                                    <button @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                    >
                                    </button>
                                </div>
                            </div>
                            <div :class="['modal-body p-0', saving ? 'modal-wt-pointer' : null]">
                                <form id="client-form" @submit.prevent="send()">
                                    <client-detail
                                            ref="clientDetail"
                                            :client="data"
                                            :key="data.id"
                                            class="container p-3"
                                    />
                                    <client-accounts
                                            class="container mt-3 collapse show"
                                            :client="data"
                                    />
                                    <client-account-params
                                            ref="accountParams"
                                            class="container mt-3 collapse show"
                                            :client="data"
                                    />
                                    <client-apps
                                            class="container mt-3 collapse show"
                                            :client="data"
                                    />
                                </form>
                            </div>
                            <div :class="['modal-footer', saving ? 'modal-wt-pointer' : null]">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="client-form"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                            <div v-if="saving">
                                <div class="loader at-inner-loader" id="preload"></div>
                                <span class="loading" style="">Saving changes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {detail, store, edit} from '@/services/client';
    import ClientDetail from "./detail";
    import ClientAccountParams from "@/components/modal/modal-client/client-account-params";
    import {getCookie} from "@/helpers/get-cookie";
    import _ from "lodash"
    import ClientAccounts from "@/components/modal/modal-client/client-accounts";
    import ClientApps from "@/components/modal/modal-client/client-apps";

    export default {
        name: 'ModalClient',
        components: {
            ClientApps,
            ClientAccounts,
            ClientAccountParams,
            ClientDetail
        },
        data() {
            return {
                id: null,
                data: null,
                clientStatus: null,
                saving: false
            }
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalClient, (value) => {
                if (value.show) {
                    this.id = value.id;
                    this.loadClient(value.id);
                }
            }, {deep: true});
        },
        computed: {
            ...mapGetters(['getModalClient', 'getConstants'])
        },
        watch: {
            clientStatus: {
                handler: function (newValue,) {
                    this.setClientStatus(newValue);
                }
            }
        },
        methods: {
            getClientChecked() {
                if (this.data.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED) {
                    return true;
                } else {
                    return false;
                }
            },
            setClientStatus(value) {
                if (value) {
                    this.data.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
                } else {
                    this.data.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
                }
            },
            async loadClient(id) {
                let response;
                try {
                    response = await detail(id);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.data = response.data.result;
                this.clientStatus = this.getClientChecked();
            },
            async save() {
                this.saving = true;
                let response;
                let formElement = document.querySelector('#client-form');
                let formData = new FormData(formElement);
                formData.append('id', this.id);
                formData.append('client_form[name]', this.data.name);
                formData.append('status', this.data.status);
                formData.append('team', JSON.stringify(this.data.team));
                formData.append('params', JSON.stringify(this.data.params));
                formData.append('accounts', JSON.stringify(this.data.accounts));
                formData.append('file', this.$refs.clientDetail.file);
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                if (this.$refs.clientDetail.fileChanged) {
                    formData.append('fileChanged', this.$refs.clientDetail.fileChanged);
                }

                try {
                    response = this.id === null ? await store(formData) : await edit(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed applying the changes",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                _.set(this.getModalClient, 'result', response.data.result);
                this.close();
                this.saving = false;

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            },
            close() {
                _.set(this.getModalClient, 'id', null);
                _.set(this.getModalClient, 'show', false);
                this.id = null;
                this.data = null;
                this.deleteQueryParam('clientId');
            },
            send() {
                let validForm = [];
                validForm.push(this.$refs.clientDetail.validateForm());
                validForm.push(this.$refs.accountParams.validateForm());

                if (!_.includes(validForm, false)) {
                    this.save();
                } else {
                    this.$toast.open({
                        message: 'Please, for saving fill the client data and all elements parameters',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                }
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            }
        }

    }
</script>
