<template>
    <div class="card mb-3">
        <div class="card-header at-scripts-level">
           <div class="at-entity-wrapper">
                <h6 data-bs-toggle="collapse" data-bs-target="#scripts-app-collapse">Scripts</h6>
                <div class="at-nav-controls">
                  <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#scripts-app-collapse"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"/>
                    </button>
                </div>
           </div>
        </div>
        <div class="container mt-3 mb-3 collapse show" id="scripts-app-collapse">
            <div v-if="data.length === 0" class="text-center">
              <span class="text-muted">
                      No selected scripts
              </span>
            </div>
            <div v-for="(appInClientScript, index) in data"
                 :key="index"
                 class="card mb-3" >
                    <app-in-client-script-level :app-in-client="appInClient"
                                      :appInClientAccount="appInClientAccount"
                                      :appInClientScript="appInClientScript"/>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import {mapGetters} from 'vuex'
    import {detail as AppScriptDetail} from "@/services/app-script";
    import {execute as Execute, execute_debug as ExecuteDebug} from "@/services/app-in-client-script";
    import {isSystem} from "@/helpers/user-roles";
    import AppInClientScriptLevel from "@/components/modal/modal-app-in-client/setup/account-level/group-level/app-in-client-scripts-level/app-in-client-script-level.vue";

    export default {
        name: "SetUpAppInClientScriptsLevel",
        components: {AppInClientScriptLevel},
        props: {
            appInClient: {
                type: Object,
                required: true
            },
            appInClientAccount: {
                type: Object,
                required: true
            },
            data: {
                type: Array,
            },
        },
        data() {
            return {
                selectedScript: null,
                inputValue: null,
                selectedItems: [],
            }
        },
        computed: {
            ...mapGetters(['getModalApp','getConstants', 'getUser']),
            isAppDebuggable() {
                return this.appInClient.app.is_debuggable;
            },
            isSystemUser() {
                return isSystem(this.getUser.role);
            },

        },
        methods: {
            appScriptExists(appScript) {
                return Number.isInteger(appScript.id);
            },
            isAppScriptEnabled(appScript) {
                return appScript.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
            },
            isAppScriptDisabled(appScript) {
                return appScript.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
            },
            dispatchProgrammingTypeChange(appScript, value) {
                appScript.programming_type = value;
            },
            dispatchProgrammingExecutionChange(appScript, value) {
                appScript.programming_execution = value;
            },
            selectScript(element) {
                this.selectedScript = element;
            },
            dispatchCheckboxScript(id, value) {
                if (value === true && !_.some(this.selectedItems, {value: value, id: id})) {
                    this.selectedItems.push({value: value, id: id});
                } else if (value === false && _.some(this.selectedItems, {value: true, id: id})) {
                    this.selectedItems.splice(this.selectedItems.indexOf({value: true, id: id}), 1)
                }
            },
            deleteSelectedAppScripts() {
                if (this.selectedItems.length > 0) {
                    let items = [];
                    _.forEach(this.selectedItems, item => {
                        items.push(item.id);
                    });
                    this.appInClient.app_scripts = _.filter(_.cloneDeep(this.appInClient.app_scripts),
                            app_script => !_.includes(items, app_script.script.id))
                }
                this.selectedItems = [];
            },
            async addAppScript() {
                let response;
                try {
                    response = await AppScriptDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                console.log(response);
                let appScript = response.data.result;
                appScript.script = this.selectedScript;
                appScript.app = this.appInClient.id;
                this.appInClient.app_scripts.push(appScript);
                this.selectedScript = null;
            },
            async execute() {
                  let response;

                  try {
                      response = await Execute(this.appInClient.id, this.appInClientAccount.id, this.script.id);
                  } catch (e) {
                      return e;
                  }

                  if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                      this.$toast.open({
                          message: response.data.message,
                          type: 'error',
                          position: 'top-right',
                          duration: 5000,
                      });
                      return;
                  }

                  this.$toast.open({
                      message: response.data.message,
                      type: 'success',
                      position: 'top-right',
                      duration: 5000,
                  });
                },
                async execute_debug() {
                  let response;

                  try {
                    response = await ExecuteDebug(this.appInClient.id, this.appInClientAccount.id, this.script.id);
                  } catch (e) {
                    return e;
                  }

                  if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                      message: response.data.message,
                      type: 'error',
                      position: 'top-right',
                      duration: 5000,
                    });
                    return;
                  }

                  this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                  });
              },
              pause() {
                  this.appScript.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
              },
              active() {
                  this.appScript.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
              },
        }
    }
</script>