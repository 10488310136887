import store from "@/store"
let paramTypeFunctions = {};

paramTypeFunctions.isTextParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_TEXT;
}

paramTypeFunctions.isNumberParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_NUMBER;
}

paramTypeFunctions.isUniqueChoiceParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_UNIQUE_CHOICE;
}

paramTypeFunctions.isMultipleChoiceParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_MULTIPLE_CHOICE;
}

paramTypeFunctions.isBooleanParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_BOOLEAN;
}

paramTypeFunctions.isDateTimeParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_DATETIME;
}

paramTypeFunctions.isDateParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_DATE;
}

paramTypeFunctions.isJsonParam = (type) => {
    return type === store.state.constants.PARAM_TYPES.PARAM_TYPE_JSON;
}

paramTypeFunctions.getParamTypes = () => {
    return [store.state.constants.PARAM_TYPES.PARAM_TYPE_TEXT,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_NUMBER,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_DATE,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_DATETIME,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_MULTIPLE_CHOICE,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_UNIQUE_CHOICE,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_BOOLEAN,
            store.state.constants.PARAM_TYPES.PARAM_TYPE_JSON]
}

paramTypeFunctions.getDefaultValueForParamType = (paramType) => {
    let defaultValues = {
        'Boolean': false,
    }
    return defaultValues[paramType] !== undefined ? defaultValues[paramType] : null;
}

export default paramTypeFunctions;