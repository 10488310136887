<template>
    <div class="input-group input-group-sm">
        <input
                :value="value"
                :min="min"
                :step="getStep()"
                :disabled="disabled"
                type="number"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Set a value"
                @wheel="$event.target.blur()"
        >
    </div>
</template>
<script>
    export default {
        name: "InputNumberField",
        props: {
            value: {
                type: [Number, String],
                required: false,
                default: null
            },
            min: {
                type: Number,
                required: false,
                default: 0
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
      methods: {
          getStep() {
              if (this.value === '' || this.value === null || this.value === undefined) {
                  return 0;
              }

              const decimals = this.countDecimals(this.value);
              return parseFloat((1 / Math.pow(10, decimals)).toFixed(decimals));
          },
          countDecimals(value) {
              if (Math.floor(parseFloat(value)) === parseFloat(value)) {
                return 0;
              }
              return parseFloat(value).toString().split('.')[1].length || 0;
          }
      }
    }
</script>