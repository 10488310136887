import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 * @param formData
 */
export function detail_accounts(formData) {
    return AxiosWrapper('/apps_client/draft_accounts/detail/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function execute(appInClientAccountId) {
    const params = {id: appInClientAccountId};
    return AxiosWrapper('/apps_client/accounts/execute/', {params});
}

/**
 * @returns Promise
 */
export function execute_debug(appInClientAccountId) {
    const params = {id: appInClientAccountId};
    return AxiosWrapper('/apps_client/accounts/execute_debug/', {params});
}

/**
 * @returns Promise
 */
export function active(appInClientAccountId) {
    const params = {id: appInClientAccountId};
    return AxiosWrapper('/apps_client/accounts/active/', {params});
}

/**
 * @returns Promise
 */
export function pause(appInClientAccountId) {
    const params = {id: appInClientAccountId};
    return AxiosWrapper('/apps_client/accounts/pause/', {params});
}