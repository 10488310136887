<template>
    <h6>
        <span :class="result.class">
            {{ result.label }}
        </span>
    </h6>
</template>
<script>

    import {mapGetters} from "vuex";
    export default {
        name: "ResultField",
        props: {
            value: {
                type: String,
            }
        },
        data() {
            return {
                result: null
            }
        },
        computed: {
            ...mapGetters(['getConstants'])
        },
        created() {
            this.RESULT_OPTIONS = this.getConstants.RESULT_STATUS_OPTIONS;
            this.loadResult()
        },
        watch: {
            value: {
                handler: function (newValue,) {
                    this.loadResult();
                }
            }
        },
        methods: {
            loadResult() {
                this.result = this.RESULT_OPTIONS[this.value];
            }
        }
    }
</script>