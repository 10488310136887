<template>
    <div>
        <div class="modal-header sticky-top">
            <h6 data-bs-toggle="collapse" data-bs-target="#scripts-app-collapse">Scripts</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#scripts-app-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>

        </div>
        <div class="container mt-3 collapse show" id="scripts-app-collapse">
            <div v-if="app.app_scripts.length === 0" class="text-center">
            <span class="text-muted">
                    No selected scripts
            </span>
            </div>
            <script-wrapper v-for="app_script in app.app_scripts"
                            :key="app_script.script.id"
                            :script="app_script.script"
                            :app_script="app_script"
                            class="card mb-3"
                            @dispatch-checkbox-script="dispatchCheckboxScript(app_script.script.id, $event)"

            />
            <div class="row pb-3">
                <div class="col col-md-6">
                    <button
                            @click="deleteSelectedAppScripts()"
                            type="button"
                            :class="['btn btn-outline-secondary', selectedItems.length === 0 ? 'at-disabled' : null]">
                        Delete selected scripts
                    </button>
                </div>
                <div class="col">
                    <div
                            class="btn-group float-end"
                            role="group"
                            aria-label="Button group with nested dropdown"
                    >
                        <select-search
                                @dispatch-select-element="selectScript"
                                :options="filterScripts"
                                :value="selectedScript"
                                :is-button-group="true"
                                default-print="Script name"
                        />
                        <button
                                @click="addAppScript()"
                                :class="['btn btn-outline-success text-nowrap', !selectedScript ? 'at-disabled' : null]"
                                type="button"
                        >
                            Add Script
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import {mapGetters} from 'vuex'

    import {detail as AppScriptDetail} from "@/services/app-script";
    import SelectSearch from "@/components/commons/select-search";
    import ScriptWrapper from "@/components/wrappers/script-wrapper";

    export default {
        name: "AppScripts",
        components: {ScriptWrapper, SelectSearch},
        props: {
            app: {
                type: Object,
                required: true
            },
            allScripts: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                selectedScript: null,
                inputValue: null,
                selectedItems: [],
                scriptOptions: this.allScripts
            }
        },
        watch: {
            allScripts: {
                handler: function (newValue,) {
                    this.scriptOptions = newValue;
                },
            },
        },
        computed: {
            ...mapGetters(['getModalApp']),
            getScriptIdsByApp() {
                if (this.app) {
                    let ids = [];
                    _.forEach(this.app.app_scripts, app_script => {
                        ids.push(app_script.script.id);
                    });
                    return ids;
                }
                return [];
            },
            filterScripts() {
                let scriptOptions = _.filter(this.scriptOptions, script => !_.includes(this.getScriptIdsByApp, script.id));
                if (!this.inputValue) {
                    return scriptOptions;
                }
                return _.filter(scriptOptions, script => _.toLower(script.name).includes(_.toLower(this.inputValue)));
            }
        },
        methods: {
            selectScript(element) {
                this.selectedScript = element;
            },
            dispatchCheckboxScript(id, value) {
                if (value === true && !_.some(this.selectedItems, {value: value, id: id})) {
                    this.selectedItems.push({value: value, id: id});
                } else if (value === false && _.some(this.selectedItems, {value: true, id: id})) {
                    this.selectedItems.splice(this.selectedItems.indexOf({value: true, id: id}), 1)
                }
            },
            deleteSelectedAppScripts() {
                if (this.selectedItems.length > 0) {
                    let items = [];
                    _.forEach(this.selectedItems, item => {
                        items.push(item.id);
                    });
                    this.app.app_scripts = _.filter(_.cloneDeep(this.app.app_scripts),
                            app_script => !_.includes(items, app_script.script.id))
                }
                this.selectedItems = [];
            },
            async addAppScript() {
                let response;
                try {
                    response = await AppScriptDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                console.log(response);
                let appScript = response.data.result;
                appScript.script = this.selectedScript;
                appScript.app = this.app.id;
                this.app.app_scripts.push(appScript);
                this.selectedScript = null;
            }
        }
    }
</script>