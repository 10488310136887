<template>
    <div>
        <div class="mt-4 pb-4">
            <div v-if="getAdGroups.length > 0">
                <modal-commons-table
                        @dispatch-elements-selected="dispatchElementsSelected"
                        :config="tableConfig"
                        :data="getAdGroups"
                        :selected="adGroupsSelected"
                />
            </div>
            <div class="text-center" v-else>
                <span class="text-muted">No Adgroups</span>
            </div>
        </div>
        <div class="row mx-1 pb-3">
            <div class="col">
                <button
                        @click="deleteAdGroups()"
                        class="btn btn-outline-secondary"
                        :disabled="adGroupsSelected.length === 0"
                >
                    Delete AdGroups
                </button>
            </div>
            <div class="col ">
                <div class="col ">
                    <div class="btn-group float-end"
                         role="group"
                         aria-label="Button group with nested dropdown"
                    >
                        <multi-select-entity-search
                                @dispatch-select-elements="dispatchAdGroupsChange"
                                :options="getNonAssignedAdGroups"
                                :is-button-group="true"
                                default-print="Select AdGroups..."
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import ModalCommonsTable from "@/components/modal/commons/table"
    import {getAdGroupsByCampaign} from "@/services/account";
    import MultiSelectEntitySearch from "@/components/commons/multiselect-entity";

    export default {
        name: "SetUpAdGroupLevel",
        components: {MultiSelectEntitySearch, ModalCommonsTable},
        props: {
            campaign: {
                type: Object,
            },
            appInClientAccount: {
                type: Object,
            },
            accountGroup: {
                type: Object
            },
            platform: {
                type: String
            },
            parentOpened: {
                type: Boolean
            }
        },
        watch: {
            parentOpened: {
                handler: function (newValue,) {
                    if (newValue === true) {
                        this.loadAdGroups();
                    }
                }
            }
        },
        data() {
            return {
                tableConfig: {
                    columns: [
                        {property: 'id', label: 'AdGroup Id', type: 'text'},
                        {property: 'name', label: 'AdGroup Name', type: 'text'},
                    ],
                    hasCheckbox: true,
                    hasFooter: false,
                },
                adGroupsSelected: [],
                adGroupsByCampaign: []
            }
        },
        computed: {
            getAdGroupsIds() {
                let ids = [];
                _.forEach(this.campaign.adGroups, adGroup => {
                    ids.push(adGroup.id);
                });
                return ids;
            },
            getAdGroups() {
                return this.campaign.adGroups ?? [];
            },
            getNonAssignedAdGroups() {
                return _.filter(this.adGroupsByCampaign, adGroup => !_.includes(this.getAdGroupsIds, adGroup.id));
            }
        },
        methods: {
            async loadAdGroups() {
                let response;
                try {
                    response = await getAdGroupsByCampaign(this.appInClientAccount.account.id, this.campaign.id, this.platform)
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                this.adGroupsByCampaign = response.data.result;
            },
            dispatchAdGroupsChange(options) {
                if (!this.campaign.adGroups) {
                    this.campaign.adGroups = []
                }
                _.forEach(options, option => {
                    this.campaign.adGroups.push(option);
                })

                this.updateAccount();
            },
            dispatchElementsSelected(value) {
                this.adGroupsSelected = value;
            },
            deleteAdGroups() {
                this.campaign.adGroups = _.filter(this.campaign.adGroups, adGroup => !_.includes(this.adGroupsSelected, adGroup.id));
                this.updateAccount();
                this.adGroupsSelected = [];
            },
            updateAccount() {
                this.accountGroup.elements = _.map(this.accountGroup.elements, item => {
                    return item.id === this.campaign.id ? this.campaign : item
                });
                this.appInClientAccount.groups = _.map(this.appInClientAccount.groups, item => {
                    return item.id === this.accountGroup.id ? this.accountGroup : item
                });
            }
        }
    }
</script>