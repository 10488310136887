<template>
    <div class="nav-scroller at-nav-scroller bg-body shadow-sm p-2">
        <nav class="nav nav-underline" aria-label="Secondary navigation">
            <div class="at-breadcrumb"
                 style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item">Home</li>
                </ol>
                <h2>Teams</h2>
            </div>

            <div class="at-nav-controls">
                <div class="input-group me-2">
                    <input @input="searchTerm = $event.target.value"
                           :value="searchTerm"
                           type="text"
                           class="form-control"
                           placeholder="Search teams"
                           aria-label="Search teams"
                           aria-describedby="button-addon2">
                    <button
                            v-if="searchTerm === ''"
                            class="btn btn-outline-secondary at-icon-search-sidebar"
                            type="button"
                            id="button-addon2"
                    >
                        <i class="fas fa-search"/>
                    </button>
                    <button @click="eraseSearch"
                            type="button"
                            class="btn btn-outline-secondary at-restore-search-sidebar"
                            id="button-addon3"
                            v-else>
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <button @click="newTeam()"
                        class="btn btn-primary btn-sm text-nowrap"
                >
                    <i class="fas fa-plus"></i>
                    New team
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
    import _ from "lodash";
    import {mapGetters} from "vuex";

    export default {
        name: 'NavTeams',
        data() {
            return {
                searchTerm: ''
            }
        },
        computed: {
            ...mapGetters(['getModalTeam'])
        },
        watch: {
            searchTerm: {
                handler: 'setSearchTermValue',
                immediate: true
            },
        },
        methods: {
            newTeam() {
                _.set(this.getModalTeam, 'show', true);
            },
            eraseSearch() {
                this.searchTerm = '';
            },
            setSearchTermValue() {
                this.$store.dispatch('setSearchTerm', {searchTerm: this.searchTerm});
            },
        }
    }
</script>
