<template>
    <td>
        <div class="at-entity-wrapper">
            <div class="at-entity">
                <div class="at-entity-name">
                    {{ name }}
                </div>
                <div class="at-entity-id">
                    {{ id }}
                </div>
            </div>
        </div>
    </td>
</template>
<script>
    export default {
        name: "EntityField",
        props: {
            name: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: true
            }
        }
    }
</script>