<template>
    <table class="table table-stripped table-hover table-bordered at-table">
        <thead>
        <tr>
            <th
                    v-if="config.hasCheckbox"
                    scope="col"
                    class="at-checkbox-cell"
            >
                <label>
                    <input
                            @input="checkOrUncheckOptions($event.target.checked)"
                            :checked="data.length !== 0 && dataSelected.length === data.length"
                            type="checkbox"
                            class="form-check-input"
                    >
                </label>
            </th>
            <th
                    v-for="column in config.columns"
                    :key="column.property"
                    scope="col"
            >
                {{ column.label }}
            </th>
        </tr>
        </thead>
        <tbody v-if="data.length === 0">
        <tr>
            <td :colspan="config.columns.length + 1" class="text-muted text-center pe-none">
                No data
            </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr
                v-for="row in data"
                :key="row.id"
        >
            <td
                    v-if="config.hasCheckbox"
            >
                <label for="commons-table-check-all"/>
                <input
                        v-model="dataSelected"
                        :value="row.id"
                        id="commons-table-check-all"
                        type="checkbox"
                        class="form-check-input"
                >
            </td>
            <td
                    v-for="column in config.columns"
                    :key="column.property"
            >
                <input-text-field v-if="column.type === 'input'"
                                  :value="getPropertyColumnValue(row, column)"
                                  @input="setPropertyColumnValue(row, column, $event.target.value)"

                />
                <entity-field v-if="column.type === 'entity'"
                              :name="row.name"
                              :id="row.id"
                />
                <select-field v-else-if="column.type === 'select'"
                              :value="getPropertyColumnValue(row, column)"
                              :options="column.options"
                              default-print="Select a value"
                              @dispatch-change="setPropertyColumnValue(row, column, $event)"
                />
                <text-field v-else-if="column.type==='text'"
                            :value="getPropertyColumnValue(row, column)"
                />
                <result-field v-else-if="column.type==='result'"
                              :value="getPropertyColumnValue(row, column)"
                />
                <link-field v-else-if="column.type==='link'"
                            :value="getPropertyColumnValue(row, column)"
                />
                <platform-text-field v-else-if="column.type==='platform-text'"
                                     :platform="getPropertyColumnValue(row, column)"
                />
                <button-field v-else-if="column.type==='button'"
                              :value="getPropertyColumnValue(row, column)"
                              :id="row['id']"
                              @dispatch-button-action="dispatchButtonAction"
                />
                <custom-text-field
                        v-else-if="column.type==='customText'"
                        :type="getPropertyColumnValue(row, column)"
                />
                <result-field
                        v-else-if="column.type==='result'"
                        :value="getPropertyColumnValue(row, column)"
                />
                <input-number-field
                        v-else-if="column.type === 'number'"
                        :value="getPropertyColumnValue(row, column)"
                        :disabled="column.disabled"
                        @input="setPropertyColumnValue(row, column, $event.target.value)"
                />
                <monthly-picker-field
                        v-else-if="column.type === 'monthly-selector'"
                        :date="getPropertyColumnValue(row, column)"
                        :disabled="column.disabled"
                        @dispatch-change="setPropertyColumnValue(row, column, $event)"
                />
            </td>
        </tr>
        </tbody>
        <tfoot v-if="config.hasFooter">
        <tr>
            <th
                    v-if="config.hasCheckbox"
                    scope="col"
                    class="at-checkbox-cell"
            >
                <label>
                    <input
                            @input="checkOrUncheckOptions($event.target.checked)"
                            :checked="data.length !== 0 && dataSelected.length === data.length"
                            type="checkbox"
                            class="form-check-input"
                    >
                </label>
            </th>
            <th
                    v-for="column in config.columns"
                    :key="column.property"
                    scope="col"
            >
                {{ column.label }}
            </th>
        </tr>
        </tfoot>
    </table>
</template>

<script>
    import _ from 'lodash';
    import MonthlyPickerField from "@/components/commons/table-fields/monthly-picker";
    import InputNumberField from "@/components/commons/table-fields/input-number";
    import TextField from "@/components/commons/table-fields/text";
    import CustomTextField from "@/components/commons/table-fields/custom-text";
    import ButtonField from "@/components/commons/table-fields/button";
    import PlatformTextField from "@/components/commons/table-fields/platform-text";
    import ResultField from "@/components/commons/table-fields/result";
    import SelectField from "@/components/commons/table-fields/select";
    import InputTextField from "@/components/commons/table-fields/input-text";
    import EntityField from "@/components/commons/table-fields/entity";
    import LinkField from "@/components/commons/table-fields/link";

    export default {
        name: 'ModalCommonsTable',
        props: {
            config: {
                type: Object,
            },
            data: {
                type: Array,
            },
            selected: {
                type: Array,
            }
        },
        components: {
            LinkField,
            EntityField,
            InputTextField,
            SelectField,
            ResultField,
            PlatformTextField,
            ButtonField,
            CustomTextField,
            InputNumberField,
            MonthlyPickerField,
            TextField,
        },
        watch: {
            dataSelected: {
                handler: function (newValue,) {
                    this.$emit('dispatch-elements-selected', newValue);
                }
            },
            selected: {
                handler: function (newValue,) {
                    this.dataSelected = newValue;
                }
            }
        },
        data() {
            return {
                dataSelected: this.selected
            }
        },
        methods: {
            dispatchButtonAction(value) {
                this.$emit('dispatch-button-clicked', value);
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column.property, row);
            },
            setPropertyColumnValue(row, column, value) {
                _.set(row, column.property, value);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            },
            checkOrUncheckOptions(value) {
                this.dataSelected = [];
                if (value === true) {
                    _.forEach(this.data, (item) => {
                        this.dataSelected.push(item.id);
                    });
                }
            },
        }
    }
</script>