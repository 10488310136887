<template>
    <thead>
    <tr>
        <th
                v-if="hasCheckbox"
                :style="{width: '30px'}"
        >
            <label>
                <input
                        @input="change($event.target.checked)"
                        :checked="data.length !== 0 && dataSelected.length === data.length"
                        type="checkbox"
                        class="form-check-input"
                >
            </label>
        </th>
        <th
                v-for="column in columns"
                :key="'header-' + column.property"
        >
            {{ column.label }}
            <span @click="changeOrderBy(column)">
                 <i :class="getSortClass(getSortOptions, column)" />
            </span>
        </th>
        <th
                v-if="hasActions"
                :style="{width: '180px'}"
        >
            Actions
        </th>
    </tr>
    </thead>
</template>
<script>
    export default {
        name: 'TableHeader',
        props: {
            columns: {
                type: Array
            },
            data: {
                type: Array
            },
            dataSelected: {
                type: Array
            },
            tableSort: {
                type: Object
            },
            hasCheckbox: {
                type: Boolean
            },
            hasActions: {
                type: Boolean
            },
        },
        computed: {
            getSortOptions: {
                cache: false,
                get() {
                    return this.sortOptions;
                }
            }
        },
        data() {
            return {
                sortOptions: this.tableSort,
            }
        },
        watch: {
            tableSort: {
                handler: function (newValue,) {
                    this.sortOptions = newValue;
                },
                deep: true
            }
        },
        methods: {
            change(value) {
                this.$emit('dispatch-header-checked', value)
            },
            getSortClass(sortOptions, column) {
                let icon, direction = 'down';

                icon = column.type === 'string' ? 'alpha' : 'numeric';

                if (column.property === sortOptions.column && sortOptions.order) {
                    direction = sortOptions.order === 'asc' ? 'down' : 'up';
                    return "fas fa-sort-" + icon + "-" + direction + " at-order-icon at-order-icon-selected";
                }
                return "fas fa-sort-" + icon + "-" + direction + " at-order-icon";
            },
            changeOrderBy(column) {
                this.$emit('dispatch-order-by', column)
            }
        }
    }
</script>
<style lang="scss">

</style>