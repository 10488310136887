import _ from 'lodash';

export const getSearchTerm = (state) => state.searchTerm;
export const getMessage = (state) => {
    return _.toUpper(state.message);
};
export const getModalClient = (state) => state.modalClient;
export const getModalScript = (state) => state.modalScript;
export const getModalApp = (state) => state.modalApp;
export const getConstants = (state) => state.constants;
export const getModalAppInClient = (state) => state.modalAppInClient;
export const getModalMarketplace = (state) => state.modalMarketplace;
export const getModalAppsFilter = (state) => state.modalAppsFilter;
export const getModalCredentials = (state) => state.modalCredentials;
export const getModalUser = (state) => state.modalUser;
export const getModalTeam = (state) => state.modalTeam;
export const getModalResetPassword = (state) => state.modalResetPassword;
export const getModalOptionsParameters = (state) => state.modalOptionsParameters;
export const getElementsViewMode = (state) => state.elementsViewMode;
export const getClientTeams = (state) => state.clientTeams;
export const getCurrentClient = (state) => state.currentClient;
export const getAppsFiltered = (state) => state.appsFiltered;
export const getUser = (state) => state.user;
export const getAppPlatform = (state) => state.appPlatform;
export const getAdBlock = (state) => state.adBlock;
