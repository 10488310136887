<template>
    <div class="nav-scroller at-nav-scroller bg-body shadow-sm p-2">

        <nav class="nav nav-underline" aria-label="Secondary navigation">
            <div class="at-breadcrumb"
                 style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item"><a>Home</a></li>
                    <li class="breadcrumb-item">System</li>
                </ol>
                <h2>Apps in Client</h2>
            </div>
            <div class="at-nav-controls">
<!--                <div class="btn-group me-2">
                    <button id="clientsSearch-selector" type="button" class="btn btn-outline-secondary dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        {{ getElementsViewMode }}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupDrop1">
                        <li v-for="mode in getElementsViewModeList()"
                            @click="setElementsViewMode(mode)"
                            :key="mode">
                            <a :class="['dropdown-item', getElementsViewMode === mode ? 'active' : null]" href="#">
                                {{ mode }}
                            </a>
                        </li>
                    </ul>
                </div>-->
                <div class="input-group me-2">
                    <label for="nav-users-search"/>
                    <input
                            :value="searchTerm"
                            @input="searchTerm = $event.target.value"
                            id="nav-users-search"
                            type="text"
                            class="form-control"
                            placeholder="Search apps"
                    >
                    <button
                            v-if="searchTerm === ''"
                            class="btn btn-outline-secondary at-icon-search-sidebar"
                            type="button"
                            id="button-addon2"
                    >
                        <i class="fas fa-search"/>
                    </button>
                    <button @click="eraseSearch"
                            type="button"
                            class="btn btn-outline-secondary at-restore-search-sidebar"
                            id="button-addon3"
                            v-else>
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="btn-group me-2">
                    <span v-for="type in getAppTypes()"
                          @click="setPlatform(type.name)"
                          :key="type.name"
                          :class="['btn btn-sm btn-text-center ', type.class, getAppPlatform === type.name ? 'btn-selected': null]"
                          type="button">
                        {{ type.name }}
                    </span>
                </div>
<!--                <button class="btn btn-outline-secondary text-nowrap"
                        @click="openAppsFilterModal()">
                    <span v-if="this.getAppsFiltered.length === 0">
                        <i class="fas fa-filter me-2"></i>
                    </span>
                    <span v-if="this.getAppsFiltered.length > 0 && isOver === false"
                          @mouseenter="changeOver(true)">
                        <i class="fas fa-filter me-2 at-filter-icon"></i>
                    </span>
                    <span v-if="this.getAppsFiltered.length > 0 && isOver === true"
                          @mouseleave="changeOver(false)"
                          @click.stop="emptyAppsFiltered()">
                        <i class="fas fa-times-circle me-2">
                        </i>
                    </span>
                        Filter by app
                </button>-->
            </div>
        </nav>
    </div>
</template>
<script>
    import _ from 'lodash'
    import {mapGetters} from "vuex";

    export default {
        name: 'NavAppsInClient',
        data() {
            return {
                searchTerm: '',
                isOver: false,
            }
        },
        computed: {
            ...mapGetters(['getModalAppsFilter', 'getCurrentClient', 'getElementsViewMode', 'getAppPlatform', 'getAppsFiltered', 'getConstants'])
        },
        watch: {
            searchTerm: function (newValue) {
                this.$store.dispatch('setSearchTerm', {searchTerm: newValue});
            }
        },
        methods: {
            eraseSearch() {
                this.searchTerm = '';
            },
            openAppsFilterModal() {
                _.set(this.getModalAppsFilter, 'show', true);
            },
            setPlatform(platform) {
                this.$store.dispatch('setAppPlatform', {appPlatform: platform});
            },
            getElementsViewModeList() {
                return _.forOwn(this.getConstants.ELEMENTS_VIEW_MODE_LIST, (value,) => value);
            },
            setElementsViewMode(mode) {
                this.$store.dispatch('setElementsViewMode', {elementsViewMode: mode});
            },
            getAppTypes() {
                return this.getConstants.APP_TYPES;
            },
            changeOver(state) {
                this.isOver = state;
            },
            emptyAppsFiltered() {
                this.$store.dispatch('setAppsFiltered', {appsFiltered: []});
                this.changeOver(false);
            }
        }
    }
</script>
