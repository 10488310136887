<template>
    <div>
        <div class="mt-4 pb-4">
            <div v-if="getCampaigns.length > 0">
                <set-up-campaign-card
                        @dispatch-elements-selected="dispatchElementsSelected"
                        v-for="(campaign, key) in getCampaigns"
                        :key="key"
                        :campaign="campaign"
                        :app-in-client-account="appInClientAccount"
                        :account-group="accountGroup"
                        :campaigns-selected="campaignsSelected"
                        :platform="platform"
                />
            </div>
            <div class="text-center" v-else>
                <span class="text-muted">All campaigns</span>
            </div>
        </div>
        <div class="row mx-1 pb-3">
            <div class="col">
                <button
                        class="btn btn-outline-secondary"
                        @click="deleteCampaigns"
                        :disabled="campaignsSelected.length === 0"
                >
                    Delete campaigns
                </button>
            </div>
            <div class="col">
                <div class="btn-group float-end"
                     role="group"
                     aria-label="Button group with nested dropdown"
                >
                    <multi-select-entity-search
                            @dispatch-select-elements="dispatchCampaignsChange"
                            :options="getNonAssignedCampaigns"
                            :is-button-group="true"
                            default-print="Select campaigns..."
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {getCampaignsByAccount} from "@/services/account";
    import SetUpCampaignCard
        from "@/components/modal/modal-app-in-client/setup/account-level/group-level/campaign-level/campaign-card";
    import MultiSelectEntitySearch from "@/components/commons/multiselect-entity";

    export default {
        name: "SetUpCampaignLevel",
        components: {MultiSelectEntitySearch, SetUpCampaignCard},
        props: {
            appInClientAccount: {
                type: Object
            },
            accountGroup: {
                type: Object
            },
            platform: {
                type: String
            },
            parentOpened: {
                type: Boolean
            }
        },
        data() {
            return {
                campaignsSelected: [],
                campaignsByAccount: []
            }
        },
        watch: {
            parentOpened: {
                handler: function (newValue,) {
                    if (newValue === true) {
                        this.loadCampaigns();
                    }
                }
            }
        },
        computed: {
            getCampaignsIds() {
                let ids = [];
                _.forEach(this.appInClientAccount.groups, group => {
                    _.forEach(group.elements, campaign => {
                        ids.push(campaign.id);
                    });
                });
                return ids;
            },
            getCampaigns() {
                return this.accountGroup.elements ?? [];
            },
            getNonAssignedCampaigns() {
                return _.filter(this.campaignsByAccount, campaign => !_.includes(this.getCampaignsIds, campaign.id));
            }
        },
        methods: {
            async loadCampaigns() {
                let response;

                try {
                    response = await getCampaignsByAccount(this.appInClientAccount.account.id, this.platform)
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                this.campaignsByAccount = response.data.result;
            },
            dispatchCampaignsChange(options) {
                if (!this.accountGroup.elements) {
                    this.accountGroup.elements = [];
                }
                _.forEach(options, option => {
                    option.adGroups = [];
                    this.accountGroup.elements.push(option);
                })

                this.updateAccount();
            },
            dispatchElementsSelected(elements) {
                this.campaignsSelected = elements;
            },
            deleteCampaigns() {
                this.accountGroup.elements = _.filter(this.accountGroup.elements, campaign => !_.includes(this.campaignsSelected, campaign.id));
                this.updateAccount();
                this.campaignsSelected = [];
            },
            updateAccount() {
                this.appInClientAccount.groups = _.map(_.cloneDeep(this.appInClientAccount.groups), item => {
                    return item.id === this.accountGroup.id ? this.accountGroup : item
                });
            },
        }
    }
</script>