<template>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <select-simple
                        @dispatch-change="dispatchProgrammingTypeChange"
                        :options="programmingOptions"
                        :value="programmingOptions.find(item => item.name === programmingType)"
                        :defaultPrint="'Frequency'"/>
            </div>
        </div>
        <div v-if="programmingType && programmingType==='Custom crontab'" class="col">
            <input
                    @blur=dispatchCustomCrontabChange($event.target.value)
                    :value="programmingShow(programmingType, programmingExecution)"
                    class="form-control"
                    type="text"
                    placeholder="Frequency custom cron">
            <div class="mt-1">
                <small class="form-text ml-1 error" v-if="invalidCrontab">
                    <span class="text-danger">
                        Invalid crontab format
                    </span>
                </small>
            </div>
            <div class="mt-1">
                <span class="text-muted">
                    A correct crontab format must be indicated. <a href="/help/crontab"
                                                                   target="_blank">More information</a>
                </span>
            </div>
        </div>
        <div v-else-if="programmingType && programmingType ==='Once per day'" class="col">
            <Datepicker v-model="pickedTime" timePicker :clearable="false"/>
        </div>
        <div v-else-if="programmingType && programmingType ==='Once per hour'" class="col">
            <select-simple
                    @dispatch-change="dispatchCustomProgrammingChange($event, 'minute')"
                    :options="minutes"
                    :value="programmingDayShow(programmingType,programmingExecution)"
                    :defaultPrint="'Select a minute'"/>
        </div>
        <div v-else-if="programmingType && programmingType==='Once per week'" class="col-md-8 row">
            <div class="col">
                <select-simple
                        @dispatch-change="dispatchCustomProgrammingChange($event, 'week')"
                        :options="weekDays"
                        :value="programmingDayShow(programmingType,programmingExecution)"
                        :defaultPrint="'Select a day'"/>
            </div>
            <div class="col">
                <Datepicker v-model="pickedTime" timePicker :clearable="false"/>
            </div>
        </div>
        <div v-else-if="programmingType && programmingType==='Once per month'" class="col-md-8 row">
            <div class="col">
                <select-simple
                        @dispatch-change="dispatchCustomProgrammingChange($event, 'month')"
                        :options="monthDays"
                        :value="programmingDayShow(programmingType,programmingExecution)"
                        :defaultPrint="'Select a day'"/>
            </div>
            <div class="col">
                <Datepicker v-model="pickedTime" timePicker :clearable="false"/>
            </div>
        </div>
        <div v-else class="col">
            <label htmlFor="" class="form-label"></label>
        </div>
    </div>
</template>

<script>
    import SelectSimple from "@/components/commons/select-simple";
    import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue';
    import Datepicker from "@vuepic/vue-datepicker";
    import constants from "@/helpers/constants";
    import _ from 'lodash';
    import {ref} from 'vue';
    import {getProgrammingDayToShow, getProgrammingToShow} from "@/helpers/programming";
    import {isValidCron} from "cron-validator";
    import {mapGetters} from "vuex";

    export default {
        name: 'ScriptFrequency',
        props: {
            programmingType: {
                type: String
            },
            programmingExecution: {
                type: Object
            }
        },
        components: {SelectSimple, Datepicker},
        computed: {
            ...mapGetters(['getConstants']),
        },
        data() {
            return {
                type: this.programmingType,
                execution: this.programmingExecution,
                programmingOptions: [],
                weekDays: constants.WEEKDAYS,
                monthDays: constants.MONTHDAYS,
                minutes: constants.MINUTES,
                customProgramming: {day: 'Monday', hour: '01:00:00'},
                invalidCrontab: false,
                programmingShow: getProgrammingToShow,
                programmingDayShow: getProgrammingDayToShow,
                pickedTime: null
            }
        },
        created() {
            this.programmingOptions = this.getConstants.PROGRAMMING_OPTIONS;
            if (this.type !== 'Custom crontab') {
                this.pickedTime = this.programmingShow(this.type, this.execution);
            } else {
                let date = new Date();
                this.pickedTime = {}
                this.pickedTime.hours = date.getHours();
                this.pickedTime.minutes = date.getMinutes();
            }
            if (typeof this.pickedTime === 'object' && isNaN(this.pickedTime.hours) && isNaN(this.pickedTime.minutes)){
                let date = new Date();
                this.pickedTime = {}
                this.pickedTime.hours = date.getHours();
                this.pickedTime.minutes = date.getMinutes();
            }

        },
        watch: {
            type: {
                handler: function (newValue,) {
                    this.$emit('dispatch-programming-type-change', newValue);
                }
            },
            pickedTime: {
                handler: function (newValue,) {
                    if (_.includes(['Once per day', 'Once per week', 'Once per month'], this.programmingType)) {
                        this.execution['hour'] = newValue.hours;
                        this.execution['minute'] = newValue.minutes;
                    }
                },
                deep: true
            },
            execution: {
                handler: function (newValue,) {
                    this.$emit('dispatch-programming-execution-change', newValue);
                },
                deep: true
            },
            programmingType: {
                handler: function (newValue,) {
                    this.type = newValue;
                    if (_.includes(['Once per day', 'Once per week', 'Once per month'], this.programmingType)) {
                        if (isNaN(this.pickedTime.hours) && isNaN(this.pickedTime.minutes)) {
                            this.execution['hour'] = this.pickedTime.hours;
                            this.execution['minute'] = this.pickedTime.minutes;
                        } else if (this.programmingType === 'Once per hour') {
                            this.execution['hour'] = '*'
                            this.execution['minute'] = this.pickedTime.minutes
                        }
                    }
                }
            },
            programmingExecution: {
                handler: function (newValue,) {
                    this.execution = newValue;
                },
                deep: true
            },
        },
        methods: {
            dispatchProgrammingTypeChange(value) {
                this.type = value.name;
                this.reinitProgramming();
            },
            reinitProgramming() {
                if (this.type !== 'Custom crontab') {
                    Object.keys(this.execution).forEach(key => {
                        this.execution[key] = '*';
                    })
                    this.execution['minute'] = this.pickedTime.minutes
                    if (this.type !== 'Once per hour') {
                        this.execution['hour'] = this.pickedTime.hours
                    }
                } else {
                    Object.keys(this.execution).forEach(key => {
                        this.execution[key] = null;
                    })
                }
            },
            dispatchCustomProgrammingChange(value, type) {
                if (type === 'week') {
                    this.execution['week_day'] = value.id;
                } else if (type === 'month') {
                    this.execution['month_day'] = value.id;
                } else if (type === 'minute') {
                    this.execution['minute'] = value.id;
                }
            },
            dispatchCustomCrontabChange(value) {
                if (isValidCron(value)) {
                    this.invalidCrontab = false;
                    let values = value.split(' ');
                    this.execution = {
                        'minute': values[0].trim(), 'hour': values[1].trim(),
                        'month_day': values[2].trim(), 'month': values[3].trim(), 'week_day': values[4].trim()
                    };
                } else {
                    this.invalidCrontab = true;
                }
            },
        }
    }

</script>

<style scoped>

</style>