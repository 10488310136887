import AxiosWrapper from '@/helpers/axios-wrapper'


/**
 * @returns Promise
 */
export function list() {
    return AxiosWrapper('/clients/account/variables/list/', {});
}

/**
 * @returns Promise
 */
export function detail() {
    return AxiosWrapper('/clients/account/variables/detail/', {});
}

/**
 * @returns Promise
 */
export function remove(formData) {
    return AxiosWrapper('/clients/account/variables/remove/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}