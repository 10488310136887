<template>
    <div
            v-if="!url"
            @dragenter.prevent=""
            @dragover.prevent="activeZone"
            @drop.prevent="handleFile"
            :class="['image-dropzone', active ? 'image-dropzone-active' : null]"
    >
        <span>Drag and Drop Image</span>
        <span>or</span>
        <label for="dropzoneFile">Select Image</label>
        <input
                ref="dropzoneFile"
                @change="handleFile"
                type="file"
                id="dropzoneFile"
                accept="image/*"
        />
    </div>
    <div v-else
         class="image-dropzone-div"
    >
        <button
                @click="deleteImage"
                @mouseover="displayOverlay(true)"
                @mouseleave="displayOverlay(false)"
                id="clear"
                class="btn button-clear"
        >
            <i class="fa fa-times"></i>
        </button>
        <img
                :src="url"
                :class="['lazy-loaded image-dropzone-upload', imageOverlay ? 'image-dropzone-overlay' : null]"
                alt="image"
        />
    </div>
</template>
<script>
    export default {
        name: "ImageDropzone",
        props: {
            fileUrl: {
                type: String,
                required: false,
                default: null
            }
        },
        data() {
            return {
                active: false,
                imageOverlay: false,
                url: null,
            }
        },
        created() {
            if (this.fileUrl) {
                this.url = this.fileUrl;
            }
        },
        watch: {
            fileUrl: {
                handler: function (newValue) {
                    this.url = newValue;
                }
            }
        },
        methods: {
            activeZone() {
                this.active = true;
            },
            handleFile(event) {
                let files = event.target.files || event.dataTransfer.files || this.$refs.dropzoneFile.files;
                let file = files[0];
                if (file.size >= 2 * 1024 * 1024) {
                     this.$toast.open({
                        message: 'The image size must be less than 2MB',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }
                this.url = URL.createObjectURL(file);
                this.$emit('dispatch-file-change', file);
            },
            deleteImage() {
                this.url = null;
                this.active = false;
                this.displayOverlay(false);
                this.$emit('dispatch-file-delete');
            },
            displayOverlay(overlay) {
                this.imageOverlay = overlay;
            }
        }
    };
</script>