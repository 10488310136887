<template>
    <div class="card mb-3">
        <div class="card-header at-group-level">
            <div class="at-entity-wrapper">
                <label class="form-check-label" style="margin-right: 5px">
                    <input
                            v-model="selected"
                            :value="accountGroup.id"
                            type="checkbox"
                            class="form-check-input"
                    >
                </label>
                <div class="at-entity">
                    <div class="input-group w-auto at-input-air">
                        <input
                                ref="accountGroupName"
                                v-model="accountGroupName"
                                @blur="changeName($event.target.value)"
                                type="text"
                                class="form-control"
                        >
                    </div>
                </div>
                <div class="at-nav-controls">
                    <button
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#app-in-client-account-group-'+accountGroup.id+'-params-elements-collapse'"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body collapse show" :id="'app-in-client-account-group-'+accountGroup.id+'-params-elements-collapse'">
            <set-up-app-in-client-scripts-level
                  :app-in-client="appInClient"
                  :app-in-client-account="appInClientAccount"
                  :data="accountGroup.scripts"
            />
            <div class="card mb-3">
                <div class="card-header at-parameters-level">
                    <div class="at-entity-wrapper">
                        <h6 data-bs-toggle="collapse" :data-bs-target="'#app-in-client-account-group-'+accountGroup.id+'-params-collapse'">
                            Parameters</h6>
                        <div class="at-nav-controls">
                            <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#app-in-client-account-group-'+accountGroup.id+'-params-collapse'"
                                    role="button"
                                    class="btn"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                            >
                                <i class="fas fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body collapse show" :id="'app-in-client-account-group-'+accountGroup.id+'-params-collapse'">
                    <modal-app-in-client-table-params
                            :data="accountGroup.params"
                    />
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header at-elements-level">
                    <div class="at-entity-wrapper">
                        <h6 data-bs-toggle="collapse" data-bs-target="#setup-app-in-client-accounts-collapse">
                            Elements</h6>
                        <div class="at-nav-controls">
                            <button
                                    @click="changeElementsCollapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#app-in-client-account-group-'+accountGroup.id+'-elements-collapse'"
                                    role="button"
                                    class="btn"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                            >
                                <i class="fas fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body collapse"
                     :id="'app-in-client-account-group-'+accountGroup.id+'-elements-collapse'">
                    <set-up-campaign-level
                            :app-in-client-account="appInClientAccount"
                            :account-group="accountGroup"
                            :platform="platform"
                            :parent-opened="elementsSectionOpened"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from "lodash";
    import SetUpCampaignLevel
        from "@/components/modal/modal-app-in-client/setup/account-level/group-level/campaign-level";
    import ModalAppInClientTableParams from "@/components/modal/modal-app-in-client/commons/table-params";
    import SetUpAppInClientScriptsLevel from "@/components/modal/modal-app-in-client/setup/account-level/group-level/app-in-client-scripts-level/index.vue";

    export default {
        name: 'SetUpAccountGroupLevel',
        components: {SetUpAppInClientScriptsLevel, SetUpCampaignLevel, ModalAppInClientTableParams},
        props: {
            appInClient: {
                type: Object
            },
            appInClientAccount: {
                type: Object
            },
            accountGroup: {
                type: Object
            },
            platform: {
                type: String
            },
            groupsSelected: {
                type: Array
            }
        },
        data() {
            return {
                selected: this.groupsSelected,
                accountGroupName: this.accountGroup.name,
                elementsSectionOpened: false
            }
        },
        watch: {
            selected: {
                handler: function (newValue,) {
                    this.$emit('dispatch-elements-selected', newValue);
                }
            },
            groupsSelected: {
                handler: function (newValue,) {
                    this.selected = newValue;
                }
            }
        },
        methods: {
            changeName(name) {
                if ((_.find(this.appInClientAccount.groups, group => group.name === name)) !== undefined) {
                    this.accountGroupName = this.accountGroup.name;
                    this.$toast.open({
                        message: 'Group with this name already exists',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                this.accountGroup.name = this.accountGroupName;
                this.$refs.accountGroupName.blur();
            },
            changeElementsCollapsed(){
                this.elementsSectionOpened = !this.elementsSectionOpened;
            }
        }
    }
</script>