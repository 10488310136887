<template>
    <tfoot>
    <tr>
        <th
                v-if="hasCheckbox"
                class="at-checkbox-cell"
        >
            <label>
                <input
                        @input="change($event.target.checked)"
                        :checked="data.length !== 0 && dataSelected.length === data.length"
                        type="checkbox"
                        class="form-check-input"
                >
            </label>
        </th>
        <th
                v-for="column in columns"
                :key="column.property"
        >
            {{ column.label }}
        </th>
        <th
                v-if="hasActions"
                class="at-actions-cell"
        >
            Actions
        </th>
    </tr>
    </tfoot>
</template>

<script>
    export default {
        name: 'TableFooter',
        props: {
            columns: {
                type: Array
            },
            data: {
                type: Array
            },
            dataSelected: {
                type: Array
            },
            tableSort: {
                type: Object
            },
            hasCheckbox: {
                type: Boolean
            },
            hasActions: {
                type: Boolean
            },
        },
        methods: {
            change(value) {
                this.$emit('dispatch-header-checked', value)
            },
            getSortClass(column) {
                let icon, direction = 'down';

                icon = column.type === 'string' ? 'alpha' : 'numeric';

                if (column.property === this.tableSort.column && this.tableSort.order) {
                    direction = this.tableSort.order === 'asc' ? 'down' : 'up';
                    return "fas fa-sort-" + icon + "-" + direction + " at-order-icon at-order-icon-selected";
                }
                return "fas fa-sort-" + icon + "-" + direction + " at-order-icon";
            },
            changeOrderBy(column) {
                this.$emit('dispatch-order-by', column)
            }
        }
    }
</script>