import AxiosWrapper from '@/helpers/axios-wrapper'
import {getCookie} from "@/helpers/get-cookie";

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/teams/list/', {params});
}

/**
 * @returns Promise
 * @param teamId
 */
export function detail(teamId) {
    const params = {teamId: teamId};
    return AxiosWrapper('/teams/detail/', {params});
}

/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/teams/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/teams/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/teams/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/teams/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteUserInTeam(formData) {
    return AxiosWrapper('/teams/delete_user/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}