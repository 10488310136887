<template>
    <div>
        <div
                class="modal-header sticky-top"
        >
            <h6 data-bs-toggle="collapse" data-bs-target="#users-app-collapse">Users</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#users-app-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="users-app-collapse">
            <div class="row pb-2">
                <div class="col-sm-12 offset-sm-0 col-md-6 offset-md-6">
                    <div class="input-group me-2">
                        <input
                                :value="searchUser"
                                @input="searchUser = $event.target.value"
                                type="text"
                                class="form-control"
                                placeholder="Search users"
                        >
                        <button v-if="searchUser === ''"
                                class="btn btn-outline-secondary at-icon-search-sidebar"
                                type="button"
                                id="button-addon2"
                        >
                            <i class="fas fa-search"/>
                        </button>
                        <button @click="eraseUserSearch"
                                type="button"
                                class="btn btn-outline-secondary at-restore-search-sidebar"
                                id="button-addon3"
                                v-else>
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="at-user-client-container mt-4 pb-4">
                <div v-if="getUsersFiltered.length > 0">
                    <user-card
                            @dispatch-element-deleted="deleteUser"
                            v-for="user in getUsersFiltered"
                            :key="user.id"
                            :object="user"
                    />
                </div>
                <div v-else>
                    <span class="text-center text-muted">No users</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from "lodash";
    import UserCard from "@/components/modal/modal-team/team-users/user-card";

    export default {
        name: 'TeamUsers',
        components: {UserCard},
        props: {
            team: {
                type: Object
            }
        },
        computed: {
            getUsersFiltered() {
                if (this.searchUser === '') {
                    return this.team.users;
                }
                return _.filter(this.team.users, user => this.searchValue(user));
            },
        },
        created() {
            this.FIELDS_FILTER_INCLUDED = [
                'username',
                'first_name',
                'last_name'
            ];
        },
        data() {
            return {
                searchUser: '',
            }
        },
        methods: {
            eraseUserSearch() {
                this.searchUser = '';
            },
            searchValue(item) {
                let isItemValid = false;
                _.forOwn(item, (value, key) => {
                    if (_.includes(this.FIELDS_FILTER_INCLUDED, key) && _.includes(_.toLower(value), _.toLower(this.searchUser))) {
                        isItemValid = true;
                        return null;
                    }
                });
                return isItemValid;
            },
            deleteUser(elementId){
                this.team.users = _.filter(this.team.users, user => user.id !== elementId);
            }
        }
    }
</script>