import modalAppsFilter from "@/components/modal/modal-apps-filter";

export const SET_SEARCH_TERM = (state, searchTerm) => {
    state.searchTerm = searchTerm;
};

export const SET_MODAL_CLIENT = (state, modalClient) => {
    state.modalClient = modalClient;
};

export const SET_MODAL_SCRIPT = (state, modalScript) => {
    state.modalScript = modalScript;
};

export const SET_CONSTANTS = (state, constants) => {
    state.constants = constants;
};

export const SET_MODAL_APP = (state, modalApp) => {
    state.modalApp = modalApp;
};

export const SET_MODAL_APP_IN_CLIENT = (state, modalAppInClient) => {
    state.modalAppInClient = modalAppInClient;
};

export const SET_MODAL_MARKETPLACE = (state, modalMarketplace) => {
    state.modalMarketplace = modalMarketplace;
};

export const SET_MODAL_APPS_FILTER = (state, modalAppsFilter) => {
    state.modalAppsFilter = modalAppsFilter;
};

export const SET_MODAL_CREDENTIALS = (state, modalCredentials) => {
    state.modalCredentials = modalCredentials;
};

export const SET_MODAL_USER = (state, modalUser) => {
    state.modalUser = modalUser;
};

export const SET_MODAL_TEAM = (state, modalTeam) => {
    state.modalTeam = modalTeam;
};

export const SET_MODAL_RESET_PASSWORD = (state, modalResetPassword) => {
    state.modalResetPassword = modalResetPassword;
};

export const SET_APPS_FILTERED = (state, appsFiltered) => {
    state.appsFiltered = appsFiltered;
};

export const SET_MODAL_OPTIONS_PARAMETERS = (state, modalOptionsParameters) => {
    state.modalOptionsParameters = modalOptionsParameters;
};

export const SET_ELEMENTS_VIEW_MODE = (state, elementsViewMode) => {
    state.elementsViewMode = elementsViewMode;
};

export const SET_CLIENT_TEAM = (state, clientTeams) => {
    state.clientTeams = clientTeams;
};

export const SET_CURRENT_CLIENT = (state, currentClient) => {
    state.currentClient = currentClient;
};

export const SET_USER = (state, user) => {
    state.user = user;
};

export const SET_APP_PLATFORM = (state, appPlatform) => {
    state.appPlatform = appPlatform;
};

export const SET_AD_BLOCK = (state, adBlock) => {
    state.adBlock = adBlock;
};