<template>
    <div v-if="getUser" class="nav-scroller at-nav-scroller bg-body shadow-sm p-2">
        <nav class="nav nav-underline" aria-label="Secondary navigation">
            <div class="at-breadcrumb"
                 style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item">Home</li>
                </ol>
                <h2>Clients</h2>
            </div>
            <div class="at-nav-controls">
                <div class="input-group me-2">
                    <input @input="inputValue = $event.target.value"
                           :value="inputValue"
                           type="text"
                           class="form-control"
                           placeholder="Search clients"
                           aria-label="Search clients"
                           aria-describedby="button-addon2">
                    <button v-if="this.getUser.teams.length > 1" id="clientsTeamSearch-selector" type="button"
                            class="btn btn-outline-secondary dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        {{ getClientTeams }}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupDrop1">
                        <li v-for="team in getClientTeamsList()"
                            @click="setClientTeam(team)"
                            :key="team">
                            <a v-if="team === allTeams || (team !== allTeams && this.getUser.teams.find(t => t.name === team))"
                               :class="['dropdown-item', getClientTeams === team ? 'active' : null]" href="#">
                                {{ team }}
                            </a>
                        </li>
                    </ul>
                    <button id="clientsSearch-selector" type="button" class="btn btn-outline-secondary dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        {{ getElementsViewMode }}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupDrop1">
                        <li v-for="mode in getElementsViewModeList()"
                            @click="setElementsViewMode(mode)"
                            :key="mode">
                            <a :class="['dropdown-item', getElementsViewMode === mode ? 'active' : null]" href="#">
                                {{ mode }}
                            </a>
                        </li>
                    </ul>
                </div>
                <button v-if="isUserAllowed()" @click="newClient()"
                        class="btn btn-primary btn-sm text-nowrap"
                        data-bs-toggle="modal"
                        data-bs-target="#script-modal">
                    <i class="fas fa-plus"></i>
                    New client
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import Constants from "@/helpers/constants";
    import {isUser} from "@/helpers/user-roles";

    export default {
        name: 'NavClients',
        data() {
            return {
                inputValue: null,
                allTeams: Constants.ALL_TEAMS
            }
        },
        computed: {
            ...mapGetters(['getElementsViewMode', 'getModalClient', 'getUser', 'getClientTeams', 'getConstants'])
        },
        watch: {
            inputValue: {
                handler: 'setSearchTermValue',
                immediate: true
            }
        },
        methods: {
            newClient() {
                _.set(this.getModalClient, 'show', true);
            },
            setSearchTermValue() {
                this.$store.dispatch('setSearchTerm', {searchTerm: this.inputValue});
            },
            getElementsViewModeList() {
                return _.forOwn(this.getConstants.ELEMENTS_VIEW_MODE_LIST, (value,) => value);
            },
            getClientTeamsList() {
                let teams = [Constants.ALL_TEAMS]
                _.forEach(this.getUser.teams, team => {
                    teams.push(team.name);
                });
                return teams;
            },
            setElementsViewMode(mode) {
                this.$store.dispatch('setElementsViewMode', {elementsViewMode: mode});
            },
            setClientTeam(team) {
                this.$store.dispatch('setClientTeam', {clientTeams: team});
            },
            isUserAllowed() {
                return !isUser(this.getUser.role);
            },
        }
    }
</script>
