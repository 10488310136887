<template>
    <div class="form-check form-switch">
        <input
                @input="changeStatus($event.target.checked)"
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                :checked="checked"
                :disabled="disabled"
        />
        <label class="form-check-label" for="flexSwitchCheckChecked"></label>
    </div>
</template>
<script>
    export default {
        name: 'ToggleField',
        props: {
            checked: {
                type: Boolean
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            changeStatus(checked){
                this.$emit('dispatch-toggle-changed', checked);
            }
        }
    }
</script>