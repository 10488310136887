<template>
    <div>
        <div
                class="modal-header sticky-top"
        >
            <h6 data-bs-toggle="collapse" data-bs-target="#team-variables-collapse">Variables</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#team-variables-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="team-variables-collapse">
            <modal-commons-table-params
                    @dispatch-selected-change="dispatchSelectedChange"
                    :config="tableConfig"
                    :data="team.params"
            />
            <div class="row mb-3">
                <div class="col">
                    <button
                            @click="deleteSelectedTeamVariable"
                            type="button"
                            class="btn btn-outline-secondary"
                            :disabled="paramsSelected.length === 0"
                    >
                        Delete variables
                    </button>
                </div>
                <div class="col">
                    <div
                            class="btn-group float-end"
                            role="group"
                            aria-label="Button group with nested dropdown"
                    >
                        <select-search
                                @dispatch-select-element="selectTeamVariable"
                                :options="getTeamVariables"
                                :value="teamVariableSelected"
                                :is-button-group="true"
                                default-print="Team variable name"
                        />
                        <button
                                @click="addTeamVariable()"
                                :class="['btn btn-outline-success text-nowrap', !teamVariableSelected ? 'at-disabled': null]"
                                type="button"
                        >
                            Add variable
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import {mapGetters} from 'vuex'
    import {list} from "@/services/team-variable";
    import {detail as ParamDetail} from "@/services/team-param";
    import SelectSearch from "@/components/commons/select-search";
    import ModalCommonsTableParams from "@/components/modal/commons/table-params";

    export default {
        name: "TeamParams",
        props: {
            team: {
                type: Object
            }
        },
        components: {
            ModalCommonsTableParams,
            SelectSearch
        },
        data() {
            return {
                tableConfig: {
                    columns: {
                        'name': {
                            property: 'team_variable.name',
                            columnType: 'text',
                            label: 'Name'
                        },
                        'type': {
                            property: 'team_variable.type',
                            columnType: 'text',
                            label: 'Type'
                        },
                        'value': {
                            property: 'value',
                            label: 'Value'
                        }
                    },
                    hasCheckbox: true,
                    hasFooter: false,
                    defaultValueProperty: 'team_variable.default_value'
                },
                paramsSelected: [],
                teamVariables: [],
                searchTeamVariable: '',
                teamVariableSelected: null
            }
        },
        computed: {
            ...mapGetters(['getModalTeam']),
            getTeamVariablesIdsByTeam() {
                if (this.team) {
                    let ids = [];
                    _.forEach(this.team.params, param => {
                        ids.push(param.team_variable.id);
                    });
                    return ids;
                }
                return [];
            },
            getTeamVariables() {
                return _.filter(this.teamVariables, element => !_.includes(this.getTeamVariablesIdsByTeam, element.id));
            }
        },
        created() {
            this.loadTeamVariables();
        },
        methods: {
            async loadTeamVariables() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.teamVariables = response.data.result;
            },
            async addTeamVariable() {
                let response;
                try {
                    response = await ParamDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                let param = response.data.result;
                param.team_variable = this.teamVariableSelected;
                this.team.params.push(param);

                this.teamVariableSelected = null;
            },
            deleteSelectedTeamVariable() {
                if (this.paramsSelected.length > 0) {
                    let items = [];
                    _.forEach(this.paramsSelected, item => {
                        items.push(item.id);
                    });
                    this.team.params = _.filter(_.cloneDeep(this.team.params),
                            element => !_.includes(items, element.id))
                    this.paramsSelected = [];
                }
            },
            dispatchSelectedChange(value) {
                this.paramsSelected = value;
            },
            eraseTeamVariableSearch() {
                this.searchTeamVariable = '';
            },
            selectTeamVariable(element) {
                this.teamVariableSelected = element;
            }
        }
    }
</script>