import AxiosWrapper from "@/helpers/axios-wrapper";

/**
 * @returns Promise
 */
export function execute(appInClientId, appInClientAccountId, appInClientScriptId) {
    const params = {app_in_client_id: appInClientId, app_in_client_account_id: appInClientAccountId, app_in_client_account_group_script_id: appInClientScriptId};
    return AxiosWrapper('/apps_client/app_script/execute/', {params});
}

/**
 * @returns Promise
 */
export function execute_debug(appInClientId, appInClientAccountId, appInClientScriptId) {
    const params = {app_in_client_id: appInClientId, app_in_client_account_id: appInClientAccountId, app_in_client_account_group_script_id: appInClientScriptId};
    return AxiosWrapper('/apps_client/app_script/execute_debug/', {params});
}