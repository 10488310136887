import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 * @param id
 * @param app
 * @param client
 */
export function detail(id=null, app, client) {
    const params = {id: id, app: app, client: client};
    return AxiosWrapper('/apps_client/group/detail/', {params});
}
