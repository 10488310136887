<template>
    <div class="at-app-item app-marketplace">
        <div class="card shadow-sm">
            <img class="at-app-icon " :src="app.logos.logo_150_150" alt="">
            <div class="container">
                <div class="row sticky-top">
                    <div class="col">
                        <h6 class="card-text m-0 mb-1 ">
                            <span>{{ app.name }}</span>
                        </h6>
                        <h6>
                            <span v-if="app.type === 'Google'" class="badge bg-warning">Google</span>
                            <span v-else-if="app.type === 'Facebook'" class="badge bg-primary">Facebook</span>
                            <span v-else-if="app.type === 'Twitter'" class="badge bg-info">Twitter</span>
                            <span v-else-if="app.type === 'TikTok'" class="badge bg-dark">Tiktok</span>
                            <span v-else-if="app.type === 'System'" class="badge bg-secondary">System</span>
                            <span v-else-if="app.type === 'Linkedin'" class="badge bg-primary">Linkedin</span>
                            <span v-else-if="app.type === 'Display'" class="badge bg-primary">Display</span>
                            <span v-else-if="app.type === 'Pinterestdisplay'" class="badge bg-primary">Pinterest Display</span>
                            <span v-else-if="app.type === 'Pinterest'" class="badge bg-primary">Pinterest</span>
                        </h6>
                    </div>
                    <div class="col">
                    <div class="d-flex justify-content-between align-items-center float-end">
                        <button @click="openAppInClientModal()"
                                type="button" class="btn btn-sm btn-outline-secondary text-nowrap text-start">
                            <i class="fas fa-check-circle me-2"></i>Install</button>
                    </div>
                </div>
                </div>
                <div class="row">
                    <p class="at-description">
                        {{ app.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";

    export default {
        name: "MarketplaceWrapper",
        props: {
            app: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters(['getModalAppInClient', 'getModalMarketplace'])
        },
        methods: {
            openAppInClientModal() {
                _.set(this.getModalAppInClient, 'appId', this.app.id);
                _.set(this.getModalAppInClient, 'show', true);
                _.set(this.getModalMarketplace, 'show', false);
            }
        }
    }
</script>

<style scoped>

</style>