<template>
    <div v-if="show">
        <transition name="modal">
            <!--        <div class="modal fade" id="script-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"-->
            <!--             aria-labelledby="marketplace-modal-label" aria-hidden="true">-->
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>System</a>
                                        </li>
                                    </ol>
                                    <h2>Credentials</h2>
                                </div>
                                <div class="at-nav-controls">
                                    <button
                                            @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                    ></button>
                                </div>
                            </div>
                            <div class="modal-body p-0">
                                <div class="container p-3">
                                    <div class="row pb-3">
                                        <div class="col col-sm-10">
                                            <label for="" class="form-label">Facebook credentials</label>
                                            <div class="input-group me-2">
                                                <input type="text" class="form-control"
                                                       placeholder="Facebook credentials" :value="credentialFacebook"
                                                       disabled="disabled">
                                                <span class="input-group-text" type="button" id="button-addon2">
                                    <i class="fas fa-check-circle text-success"/>
                                </span>
                                            </div>
                                        </div>
                                        <div class="col d-flex align-items-end">
                                            <button class="btn btn-outline-success" @click="renewFacebookCredential">
                                                Renew
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row pb-3">
                                        <div class="col col-sm-10">
                                            <label for="" class="form-label">Google credentials</label>
                                            <div class="input-group me-2">
                                                <input type="text" class="form-control" placeholder="Google credentials"
                                                       :value="credentialGoogle" disabled="disabled">
                                                <span class="input-group-text" type="button" id="button-addon2">
                                    <i class="fas fa-check-circle text-success"/>
                                </span>
                                            </div>
                                        </div>
                                        <div class="col d-flex align-items-end">
                                            <button class="btn btn-outline-success" @click="renewGoogleCredential">
                                                Renew
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {renewGoogle} from "@/services/credential";
    import {renewFacebook} from "@/services/credential";
    import {detail} from '@/services/credential';
    import _ from "lodash";

    export default {
        name: 'ModalCredentials',
        data() {
            return {
                credentialGoogle: '',
                credentialFacebook: '',
                show: false,
                credentialsTimeout: null,
                loading: false
            }
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalCredentials, (value) => {
                if (value === true) {
                    this.loadCredentials();
                }
            })
        },
        // watch: {
        //   show: {
        //       handler: 'loadCredentials'
        //   }
        // },
        methods: {
            async getCredentialInfo(network) {
                const response = await detail(network);
                let credentialInfo = response.data;
                return credentialInfo['access_token'] + ';' + credentialInfo['refresh_token']
            },
            async loadCredentials() {
                // this.loading = true;
                this.credentialGoogle = await this.getCredentialInfo('GOO');
                this.credentialFacebook = await this.getCredentialInfo('FBK');
                this.show = true
            },
            async renewGoogleCredential() {
                // remove('GOO');
                const response = await renewGoogle();
                let data = response.data;
                window.location = data.url;
            },
            async renewFacebookCredential() {
                /* eslint-disable no-console */
                const response = await renewFacebook();
                let data = response.data;
                window.location = data.url;
                /* eslint-disable no-console */
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            },
            close() {
                this.$store.dispatch('setModalCredentials', {modalCredentials: false});
                this.show = false;
                this.deleteQueryParam('credentials');
            }
        }
    }
</script>

