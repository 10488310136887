<template>
    <div>
        <Popper
                class="light"
                arrow
                placement="right"
        >
            <template #content>
                <div class="align-items-center">
                    <h5>Actions</h5>
                    <div class="btn-group btn-group-sm align-self-center"
                         role="group"
                         aria-label="Button group with nested dropdown">
                        <button
                                @click="openUser()"
                                type="button"
                                class="btn btn-outline-secondary text-nowrap"
                                data-bs-toggle="modal"
                                data-bs-target="#script-modal"
                                data-bs-dismiss="modal"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit App"
                        >
                            <i class="fas fa-arrow-alt-circle-right"/>
                            Go to user
                        </button>
                        <div class="btn-group" role="group">
                            <button
                                    id="btnGroupDrop1"
                                    type="button"
                                    class="btn btn-outline-secondary dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                            />
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupDrop1">
                                <li @click="deleteUserInTeam()">
                                    <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#client-modal"
                                       data-bs-dismiss="modal">
                                        <i class="fas fa-trash me-2"/>
                                        Delete
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </template>
            <div class="card at-user-card rounded mb-2"
                 style="width: 18rem;">
                <div class="card-body stretched-link">
                    <h5 class="card-title">{{ object.name }}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{ object.username }}</h6>
                </div>
            </div>
        </Popper>
    </div>
</template>
<script>
    import Popper from "vue3-popper";

    export default {
        name: 'UserCard',
        components: {
            Popper
        },
        props: {
            object: {
                type: Object
            },
        },
        data() {
            return {
                options: false
            }
        },
        methods: {
            openUser() {
                window.location = '/users/?userId=' + this.object.id;
            },
            deleteUserInTeam(){
                this.$emit('dispatch-element-deleted', this.object.id);
            },
            activateOptions() {
                this.options = !this.options;
            }
        }
    }
</script>
