<template>
    <div>
        <div class="modal-header sticky-top">
            <h6 data-bs-toggle="collapse" data-bs-target="#client-accounts-collapse">Accounts</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#client-accounts-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="client-accounts-collapse">
            <modal-commons-table
                    @dispatch-elements-selected="dispatchSelectedChange"
                    :config="tableConfig"
                    :data="client.accounts"
                    :selected="accountsSelected"
            />
            <div class="row pb-3">
                <div class="col">
                    <button
                            @click="deleteSelectedAccounts()"
                            type="button"
                            class="btn btn-outline-secondary"
                            :disabled="accountsSelected.length === 0"
                    >
                        Delete accounts
                    </button>
                </div>
                <div class="col ">
                    <div class="btn-group float-end" role="group"
                         aria-label="Button group with nested dropdown">
                        <select-simple
                                @dispatch-change="dispatchPlatformChange"
                                :options="getPlatforms()"
                                :value="selectedPlatform"
                                default-print="Account type"
                        />
                        <select-search
                                @dispatch-select-element="dispatchAccountChange"
                                :options="getPlatformAccountsFiltered"
                                :loading="loadingPlatformAccounts"
                                :disabled="!selectedPlatform"
                                :is-button-group="true"
                                :value="selectedAccount"
                                default-print="Select an account"
                        />
                        <button
                                @click="addClientAccount()"
                                id="account-add"
                                type="button"
                                :class="['btn btn-outline-success text-nowrap', !selectedAccount ? 'at-disabled' : null]"
                                aria-expanded="false"
                        >
                            Add account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import _ from 'lodash';
    import {listPlatformAccounts, detail} from '@/services/account';
    import SelectSearch from "@/components/commons/select-search";
    import SelectSimple from "@/components/commons/select-simple";
    import ModalCommonsTable from "@/components/modal/commons/table";

    export default {
        name: "ClientAccounts",
        props: {
            client: {
                type: Object
            }
        },
        components: {ModalCommonsTable, SelectSimple, SelectSearch},
        computed: {
            ...mapGetters(['getModalClient', 'getConstants']),
            getAccountsByClient() {
                let accounts = [];
                if (this.client.accounts) {
                    _.forEach(this.client.accounts, account => {
                        accounts.push(account.identifier);
                    })
                }
                return accounts;
            },
            getPlatformAccountsFiltered() {
                if (this.platformAccounts) {
                    return _.filter(this.platformAccounts, account => !_.includes(this.getAccountsByClient, account.id));
                }
                return [];
            }
        },
        watch: {
            tableData: {
                handler: 'variableChange',
                deep: true
            }
        },
        data() {
            return {
                accounts: [],
                tableConfig: {
                    columns: [
                        {property: 'name', label: 'Name', type: 'text'},
                        {property: 'network', label: 'Platform', type: 'platform-text'},
                        {property: 'identifier', label: 'Identifier', type: 'text'},
                        {property: 'budget_account.budget', label: 'Budget Limit', type: 'number'},
                        {
                            property: 'budget_account.date',
                            label: 'Budget Date',
                            type: 'monthly-selector'
                        },
                    ],
                    hasCheckbox: true,
                    hasFooter: false
                },
                tableData: [],
                platformAccounts: [],
                selectedAccount: null,
                accountToAdd: null,
                selectedPlatform: null,
                accountsSelected: [],
                loadingPlatformAccounts: true,
            }
        },
        methods: {
            async addClientAccount() {
                let response;
                try {
                    response = await detail(this.selectedPlatform.name);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                let account = response.data.result;
                account.identifier = this.selectedAccount.id;
                account.name = this.selectedAccount.name;
                account.network = this.selectedPlatform.name;

                this.client.accounts.push(account);
                this.selectedAccount = null;
            },
            async getPlatformAccounts(platform) {
                this.loadingPlatformAccounts = true;

                let response;
                try {
                    response = await listPlatformAccounts(platform.name);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.platformAccounts = response.data.result;
                this.loadingPlatformAccounts = false;
            },
            async deleteSelectedAccounts() {
                if (this.accountsSelected.length > 0) {
                    let items = [];
                    _.forEach(this.accountsSelected, item => {
                        items.push(item);
                    });
                    this.client.accounts = _.filter(_.cloneDeep(this.client.accounts),
                            element => !_.includes(items, element.id));

                    this.accountsSelected = [];
                }
            },
            getPlatforms() {
                return this.getConstants.PLATFORMS;
            },
            dispatchSelectedChange(value) {
                this.accountsSelected = value;
            },
            variableChange() {
                this.$emit('dispatch-variable-change', this.tableData);
            },
            dispatchAccountChange(option) {
                this.selectedAccount = option;
            },
            dispatchPlatformChange(platform) {
                this.selectedPlatform = platform;
                this.getPlatformAccounts(platform);
            },
        }
    }
</script>