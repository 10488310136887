<template>
    <nav v-if="getUser" :class="['navbar navbar-expand-lg  navbar-dark', isLocalMode ? 'bg-local' : 'bg-dark']" aria-label="Main navigation">
        <div class="container-fluid">
            <a href="/clients" class="at-logo-wrapper" :style="'background-size: 170px 48px !important; background: url(' + altLogoWrapper + ')'"/>
            <div class="navbar-collapse ps-3" id="">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/clients" class="nav-link  at-nav-clients">
                            Clients
                        </router-link>
                    </li>
                    <li v-if="isAdminUser || isSystemUser" class="nav-item">
                        <router-link to="/users" class="nav-link  at-nav-users">
                            Users
                        </router-link>
                    </li>
                    <li v-if="isAdminUser || isSystemUser" class="nav-item">
                        <router-link to="/teams" class="nav-link  at-nav-teams">
                            Teams
                        </router-link>
                    </li>
                    <li v-if="isSystemUser" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle at-nav-system" href="#" id="dropdown01"
                           data-bs-toggle="dropdown" aria-expanded="false">System</a>
                        <ul class="dropdown-menu " aria-labelledby="dropdown01">
                            <li>
                                <router-link to="/apps-system" class="dropdown-item">
                                    Market
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/all-apps-client" class="dropdown-item">
                                    Apps in Client
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/scripts" class="dropdown-item">
                                    Script
                                </router-link>
                            </li>
                            <li>
                                <a class="dropdown-item" @click="showCredentials" data-bs-toggle="modal"
                                   data-bs-target="#credentials-modal">
                                    Credentials
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li v-if="isSystemUser" class="nav-item">
                        <router-link to="/admin" class="nav-link  at-nav-teams">
                            Admin
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="at-username-and-toggle ms-auto">
                <div class="btn-group">
                    <button type="button" class="btn text-light dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        {{ getUser ? getUser.email : '' }}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li @click="openUserModal(getUser.id)">
                            <button class="dropdown-item" type="button" data-bs-toggle="modal"
                                    data-bs-target="#user-modal">
                                <i class="fas fa-user me-2"/>
                                Profile
                            </button>
                        </li>
<!--                        <li @click="openResetPasswordModal(getUser.id)">-->
<!--                            <button class="dropdown-item" type="button" data-bs-toggle="modal"-->
<!--                                    data-bs-target="#user-modal">-->
<!--                                <i class="fas fa-key me-2"/>-->
<!--                                Reset password-->
<!--                            </button>-->
<!--                        </li>-->
                        <li @click="logout()">
                            <button class="dropdown-item" type="button">
                                <i class="fas fa-power-off me-2"/>
                                Logout
                            </button>
                        </li>
                    </ul>
                </div>
                <a class="btn text-light at-toggle-offcanvas" type="button">
                    <i class="fas fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                       aria-controls="offcanvasRight"/>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
    import altLogoWrapper from '@/assets/images/the-platform-logo-w.png';
    // import altLogoWrapper from '@/assets/images/OLD-the-platform-logo-w.svg';
    import {getCookie} from "@/helpers/get-cookie";
    import {logout, getLoggedUser} from "@/services/session";
    import {mapGetters} from "vuex";
    import {isAdmin, isSystem} from "@/helpers/user-roles";
    import _ from "lodash";

    export default {
        name: 'NavGlobal',
        components: {},
        data() {
            return {
                altLogoWrapper: altLogoWrapper,
            }
        },
        created() {
            this.loadUser();
        },
        computed: {
            ...mapGetters(['getUser', 'getModalUser', 'getModalResetPassword', 'getConstants']),
            isAdminUser() {
                return isAdmin(this.getUser.role);
            },
            isSystemUser() {
                return isSystem(this.getUser.role);
            },
            isLocalMode(){
                return _.includes(location.host, 'localhost');
            }
        },
        methods: {
            async loadUser() {
                let response;
                try {
                    response = await getLoggedUser();
                } catch (e) {
                    console.log("Fail loading user...");
                    return null;
                }

                this.$store.dispatch('setUser', {user: response.data.result});
            },
            async logout() {
                let response;
                try {
                    const formData = new FormData();
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                    response = await logout(formData);
                } catch (e) {
                    console.log("Logout error: ", e);
                    return null;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 10000,
                    });
                    return null;
                }

                window.location = '/login/'
            },
            showCredentials() {
                this.$store.dispatch('setModalCredentials', {modalCredentials: true});
                this.addQueryParam('credentials', 'true');
            },
            openUserModal(id) {
                _.set(this.getModalUser, 'id', id);
                _.set(this.getModalUser, 'show', true);
                this.addQueryParam('userId', id);
            },
            openResetPasswordModal(id) {
                _.set(this.getModalResetPassword, 'id', id);
                _.set(this.getModalResetPassword, 'show', true);
            },
            addQueryParam(param, value) {
                const query = Object.assign({}, this.$route.query);
                query[param] = value;
                this.$router.replace({query});
            },
        }
    }
</script>
