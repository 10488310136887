<template>
    <div class="btn-group" role="group">
        <button id="accountType-selector" type="button"
                class="btn btn-outline-secondary dropdown-toggle at-dropdown-toggle"
                data-bs-toggle="dropdown" aria-expanded="false" :disabled="disabled">
            {{ selected ? selected.name : defaultPrint }}
        </button>
        <div class=" at-dropdown-select dropdown-menu dropdown-menu-end" aria-labelledby="accoutType-selector"
             style="z-index:8;">
            <button
                    v-for="option in options"
                    :key="option.name"
                    :value="option.value"
                    :class="isActive(option)"
                    type="button"
                    @click="dispatchChange(option)">
                {{ option.name }}
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SelectField",
        props: {
            options: {
                type: Array,
                required: true,
            },
            value: {
                type: Object,
                required: false,
                default: null,
            },
            defaultPrint: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                selected: this.value
                        ? this.value
                        : null,
            }
        },
        watch: {
            value: {
                handler: 'valueChange',
                immediate: true,
            }
        },
        methods: {
            isActive(item) {
                if (!this.selected)
                    return 'dropdown-item';
                return item.name === this.selected.name ? 'dropdown-item active' : 'dropdown-item';
            },
            dispatchChange(option) {
                this.selected = option;
                this.$emit('dispatch-change', option);
            },
            valueChange(value) {
                this.selected = value;
            }
        }
    }
</script>