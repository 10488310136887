import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 * @param network
 */
export function detail(network) {
    const params = {network: network};
    return AxiosWrapper('/credentials/get_credential_info/', {params});
}

/**
 * @returns Promise
 */
export function renewGoogle() {
    const params = {};
    return AxiosWrapper('/credentials/google/auth/', {params});
}

/**
 * @returns Promise
 */
export function renewFacebook() {
    const params = {};
    return AxiosWrapper('/credentials/facebook/auth/', {params});
}


/**
 * @returns Promise
 * @param network
 */
export function remove(network) {
    const params = {network: network};
    return AxiosWrapper('/credentials/remove/', {params});
}