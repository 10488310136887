<template>
    <table class="table table-stripped table-hover table-bordered at-table">
        <thead>
        <tr>
            <th
                    v-if="tableConfig.hasCheckbox"
                    scope="col"
                    class="at-checkbox-cell"
            >
                <label>
                    <input
                            @input="checked = $event.target.checked"
                            :checked="getHeaderIsChecked"
                            type="checkbox"
                    >
                </label>
            </th>
            <th
                    v-for="column in getColumns"
                    :key="column"
                    scope="col"
            >
                {{ column }}
            </th>
        </tr>
        </thead>
        <tbody v-if="data.length === 0">
        <tr>
            <td :colspan="getColumns.length + 1" class="text-muted text-center pe-none">
                No data
            </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr
                v-for="row in data"
                :key="row.id"
        >
            <td v-if="tableConfig.hasCheckbox">
                <checkbox-field
                        @dispatch-checkbox-change="dispatchCheckboxChange(row.id, $event)"
                        :tableHeaderCheckbox="tableHeaderCheckbox"
                />
            </td>
            <td>
                <input-text-field v-if="getConfig(row).columns.label.columnType === 'input'"
                                  :value="getPropertyColumnValue(row,getConfig(row).columns.label.property)"
                                  @input="setPropertyColumnValue(row, getConfig(row).columns.label, $event.target.value)"

                />
                <text-field v-else
                            :value="getPropertyColumnValue(row, getConfig(row).columns.label.property)"
                />
            </td>
            <td>
                <input-text-field v-if="getConfig(row).columns.name.columnType === 'input'"
                                  :value="getPropertyColumnValue(row,getConfig(row).columns.name.property)"
                                  @input="setPropertyColumnValue(row, getConfig(row).columns.name, $event.target.value)"

                />
                <text-field v-else
                            :value="getPropertyColumnValue(row, getConfig(row).columns.name.property)"
                />
            </td>
            <td>
                <select-simple-field v-if="getConfig(row).columns.type.columnType === 'select'"
                                     :value="getPropertyColumnValue(row, getConfig(row).columns.type.property)"
                                     :options="paramTypeHelper.getParamTypes()"
                                     default-print="Select a value"
                                     @dispatch-change="changeParamType(row, getConfig(row).columns.type, $event)"

                />
                <text-field v-else
                            :value="getPropertyColumnValue(row, getConfig(row).columns.type.property)"
                />
            </td>
            <td>
                <input-text-field
                        v-if="paramTypeHelper.isTextParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                />
                <toggle-field
                        v-else-if="paramTypeHelper.isBooleanParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                        :checked="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @dispatch-toggle-changed="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"

                />
                <input-number-field
                        v-else-if="paramTypeHelper.isNumberParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                />
                <datetime-field
                        v-else-if="paramTypeHelper.isDateTimeParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                />
                <date-field
                        v-else-if="paramTypeHelper.isDateParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                />
                <multiselect-field
                        v-else-if="paramTypeHelper.isMultipleChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))
                            && row.account_variable.id !== null && row.account_variable.id !== ''"
                        :options="getPropertyColumnValue(row, getConfig(row).columns.value.property)"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @dispatch-multiple-select="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"
                />
                <select-field
                        v-else-if="paramTypeHelper.isUniqueChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))
                            && row.account_variable.id !== null && row.account_variable.id !== ''"
                        :options="getPropertyColumnValue(row, getConfig(row).columns.value.property)"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        default-print="Select a value"
                        @dispatch-change="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"
                />
              <input-json-field
                        v-else-if="paramTypeHelper.isJsonParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                        :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                        @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                />
                <div v-else-if="paramTypeHelper.isMultipleChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property)) ||
                 paramTypeHelper.isUniqueChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                     class="d-flex align-items-center">
                    <button @click="openOptionsParametersModal(row.id, row.label, getPropertyColumnValue(row, getMainConfig(row).columns.value.property))"
                            class="btn" type="button">
                        <i class="fas fa-external-link"></i>
                    </button>
                    <span>
                        Show elements
                    </span>
                </div>
            </td>
            <td>
                <toggle-field
                        :checked="getPropertyColumnValue(row, getConfig(row).columns.is_mandatory.property)"
                        :disabled="row.account_variable.id !== null && row.account_variable.id !== ''"
                        @dispatch-toggle-changed="setPropertyColumnValue(row, getConfig(row).columns.is_mandatory, $event)"
                />
            </td>
        </tr>
        </tbody>
        <tfoot v-if="tableConfig.hasFooter">
        <tr>
            <th
                    v-if="tableConfig.hasCheckbox"
                    scope="col"
                    class="at-checkbox-cell"
            >
                <label><input
                        :checked="getFooterIsChecked"
                        @input="checked = $event.target.checked"
                        type="checkbox"
                ></label>
            </th>
            <th
                    v-for="column in getColumns"
                    :key="column"
                    scope="col"
            >
                {{ column }}
            </th>
        </tr>
        </tfoot>
    </table>

</template>

<script>
    import _ from 'lodash';
    import moment from 'vue-moment';
    import CheckboxField from "@/components/commons/table-fields/checkbox";
    import InputTextField from "@/components/commons/table-fields/input-text";
    import TextField from "@/components/commons/table-fields/text";
    import paramTypeFunctions from "@/helpers/param-type";
    import ToggleField from "@/components/commons/table-fields/toggle";
    import InputNumberField from "@/components/commons/table-fields/input-number";
    import DatetimeField from "@/components/commons/table-fields/datetime";
    import DateField from "@/components/commons/table-fields/date";
    import SelectSimpleField from "@/components/commons/table-fields/select-simple";
    import SelectField from "@/components/commons/table-fields/select";
    import MultiselectField from "@/components/commons/table-fields/multiple-select";
    import InputJsonField from "@/components/commons/table-fields/input-json";
    import {mapGetters} from "vuex";

    export default {
        name: 'ModalAppGlobalVariablesTableParams',
        props: {
            data: {
                type: Array,
            },
            origin: {
                type: String
            }
        },
        components: {
            MultiselectField,
            SelectField,
            SelectSimpleField,
            InputNumberField,
            ToggleField,
            TextField,
            InputTextField,
            CheckboxField,
            DatetimeField,
            DateField,
            InputJsonField
        },
        watch: {
            checked: {
                handler: 'change'
            }
        },
        data() {
            return {
                tableConfig: {
                    appParamConfig: {
                        columns: {
                            'label': {
                                property: 'label',
                                columnType: 'input',
                            },
                            'name': {
                                property: 'name',
                                columnType: 'input',
                            },
                            'type': {
                                property: 'type',
                                columnType: 'select',
                            },
                            'is_mandatory': {
                                property: 'is_mandatory',
                                columnType: 'text',
                            },
                            'value': {
                                property: 'default_value',
                            }
                        },
                    },
                    accountVariableConfig: {
                        columns: {
                            'label': {
                                property: 'account_variable.label',
                                columnType: 'text',
                            },
                            'name': {
                                property: 'account_variable.name',
                                columnType: 'text',
                            },
                            'type': {
                                property: 'account_variable.type',
                                columnType: 'text',
                            },
                            'is_mandatory': {
                                property: 'account_variable.is_mandatory',
                                columnType: 'text',
                            },
                            'value': {
                                property: 'account_variable.default_value',
                            }
                        },
                    },
                    headers: [
                        'Label',
                        'Name',
                        'Type',
                        'Default value',
                        'Is Mandatory',
                    ],
                    hasCheckbox: true,
                    hasFooter: false
                },
                selectedItems: [],
                checked: false,
                tableHeaderCheckbox: false,
                typeOptions: [
                    {name: 'Text', value: 'text'},
                    {name: 'Boolean', value: 'boolean'},
                    {name: 'Number', value: 'number'}
                ],
                paramTypeHelper: paramTypeFunctions
            }
        },
        computed: {
            ...mapGetters(['getModalOptionsParameters']),
            getHeaderIsChecked() {
                return this.checked;
            },
            getFooterIsChecked() {
                return this.checked;
            },
            getColumns() {
                return this.tableConfig.headers;
            },
        },
        mounted() {
            this.$store.watch(state => state.modalOptionsParameters, (value) => {
                if (value.result !== null && value.origin === 'app_global_params') {
                    let result = value.result;
                    _.forEach(this.data, row => {
                        if (row.id === result.id) {
                            this.setPropertyColumnValue(row, this.getMainConfig().columns.value, result.options);
                        }
                    });

                    _.set(this.getModalOptionsParameters, 'result', null);
                }
            }, {deep: true});
        },
        methods: {
            getConfig(row) {
                if (row.account_variable.id !== null && row.account_variable.id !== '') {
                    return this.tableConfig.accountVariableConfig;
                }
                return this.tableConfig.appParamConfig;
            },
            getMainConfig() {
                return this.tableConfig.appParamConfig;
            },
            dispatchCheckboxChange(id, value) {
                if (value === true && !_.some(this.selectedItems, {value: value, id: id})) {
                    this.selectedItems.push({value: value, id: id});
                } else if (value === false && _.some(this.selectedItems, {value: true, id: id})) {
                    this.selectedItems.splice(this.selectedItems.indexOf({value: true, id: id}), 1)
                }
                this.$emit('dispatch-selected-change', this.selectedItems);
            },
            change(value) {
                this.tableHeaderCheckbox = value;
            },
            dispatchButtonAction(value) {
                this.$emit('dispatch-button-clicked', value);
            },
            dispatchValueChange(id, value) {
                this.$emit('dispatch-input-value-change', {id: id, value: value});
            },
            getDate(value) {
                if (value !== '') {
                    return moment(value);
                } else {
                    return new Date();
                }
            },
            getDynamicProperty(value) {
                let possibleOptions = ['text', 'float', 'number', 'boolean'];
                return _.includes(possibleOptions, value)
            },
            getDynamicDateProperty(value) {
                let possibleOptions = ['date', 'datetime'];
                return _.includes(possibleOptions, value)
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column, row);
            },
            changeParamType(row, column, value) {
                this.setPropertyColumnValue(row, column, value);
                this.setPropertyColumnValue(row, this.getConfig(row).columns.value, paramTypeFunctions.getDefaultValueForParamType(value));
            },
            setPropertyColumnValue(row, column, value) {
                _.set(row, column.property, value);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            },
            openOptionsParametersModal(id, parameter, options) {
                console.log('SOMOS LAS OPTIONS', options)
                _.set(this.getModalOptionsParameters, 'data', {
                    id: id,
                    options: options,
                    parameter: parameter
                });
                _.set(this.getModalOptionsParameters, 'origin', this.origin);
                _.set(this.getModalOptionsParameters, 'show', true);
            }
        }
    }
</script>