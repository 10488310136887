<template>
    <div class="container p-3">
        <div class="row">
            <div class="col at-image-col">
                <image-dropzone
                        @dispatch-file-change="dispatchFileChange"
                        @dispatch-file-delete="dispatchFileDelete"
                        :file-url="app.logos.logo_150_150"
                />
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-7">
                        <label for="basic-url" class="form-label">App Name</label>
                        <div class="input-group mb-3">
                            <input
                                    :value="app.name"
                                    @input="app.name=$event.target.value"
                                    name="app[name]"
                                    type="text"
                                    class="form-control"
                                    aria-describedby="basic-addon3"
                            />
                            <small class="form-text ml-1 error"
                                   v-if="submitted && v$.app.name.required.$invalid">
                                            <span class="text-danger">
                                                App name is mandatory.
                                            </span>
                            </small>
                        </div>
                    </div>
                    <div class="col">
                        <label for="basic-url" class="form-label">Debuggable</label>
                        <toggle-field
                                @dispatch-toggle-changed="dispatchToggleChangedDebuggable($event)"
                                :checked="app.is_debuggable"
                        />
                    </div>
                    <div class="col">
                        <label for="basic-url" class="form-label">Automatic</label>
                        <toggle-field
                                @dispatch-toggle-changed="dispatchToggleChangedAutomatic($event)"
                                :checked="app.is_automatic"
                        />
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="" class="form-label">Type</label>
                            <div class="input-group mb-3">
                                <div class="btn-group w-100" role="group">
                                    <button
                                            id="role-selector"
                                            type="button"
                                            class="btn btn-outline-secondary dropdown-toggle text-end at-dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                    >
                                                <span :class="['badge rounded-pill ', getTypeSelected.class]">{{
                                                        getTypeSelected.name
                                                    }}</span>
                                    </button>
                                    <div
                                            tabindex="-1"
                                            role="menu"
                                            aria-hidden="true"
                                            class="at-dropdown-menu  dropdown-menu dropdown-menu-end"
                                            style="z-index:9;"
                                    >
                                        <div class="scrollbar-container ps ">
                                            <section class="at-dropdown-items_listWrap">
                                                <ul class="nav flex-column">
                                                    <li
                                                            @click="selectType(type.name)"
                                                            v-for="type in typeOptions"
                                                            :key="type.name"
                                                            class=""
                                                    >
                                                        <a class="dropdown-item">
                                                                <span class="at-dropdown-items-item">
                                                                    <span :class="['badge rounded-pill', type.class]">
                                                                        {{ type.name }}
                                                                    </span>
                                                                </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="basic-url" class="form-label">Link to Documentation</label>
                            <div class="input-group mb-3">
                                <input
                                        :value="app.documentation"
                                        @input="app.documentation=$event.target.value"
                                        name="app[documentation]"
                                        type="text"
                                        class="form-control"
                                        id="basic-url"
                                        aria-describedby="basic-addon3"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="basic-url" class="form-label">Description</label>
                            <textarea
                                    :value="app.description"
                                    @input="app.description=$event.target.value"
                                    name="text area desc"
                                    class="w-100 form-control description-area"
                            >
                                    At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
                                </textarea>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {required} from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import ImageDropzone from "@/components/modal/commons/image-dropzone";
    import ToggleField from "@/components/commons/table-fields/toggle";


    export default {
        name: "AppCustomDetail",
        components: {ToggleField, ImageDropzone},
        setup: () => ({
            v$: useVuelidate()
        }),
        props: {
            app: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                submitted: false,
                typeOptions: [
                    {'name': 'Facebook', 'class': 'bg-primary'},
                    {'name': 'Google', 'class': 'bg-warning'},
                    {'name': 'TikTok', 'class': 'bg-dark'},
                    {'name': 'Twitter', 'class': 'bg-info'},
                    {'name': 'System', 'class': 'bg-secondary'},
                    {'name': 'Linkedin', 'class': 'bg-primary'},
                    {'name': 'Display', 'class': 'bg-primary'},
                    {'name': 'Pinterest', 'class': 'bg-primary'},
                    {'name': 'Pinterestdisplay', 'class': 'bg-primary'},
                ],
                file: null,
                fileChanged: false
            }

        },
        computed: {
            getTypeSelected() {
                if (this.app) {
                    let type = _.find(this.typeOptions, type => type.name === this.app.type);
                    if (type !== undefined) {
                        return type;
                    }
                }
                return this.typeOptions[0];
            }
        },
        validations() {
            return {
                app: {
                    name: {
                        required,
                    },
                },
            }
        },
        methods: {
            selectType(type) {
                this.app.type = type;
            },
            dispatchFileChange(file) {
                this.file = file;
                this.fileChanged = true;
            },
            dispatchFileDelete() {
                this.file = null;
                this.fileChanged = true;
            },
            dispatchToggleChangedDebuggable(isDebuggable) {
                this.app.is_debuggable = isDebuggable;
            },
            dispatchToggleChangedAutomatic(isAutomatic) {
                this.app.is_automatic = isAutomatic;
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            }
        }

    }
</script>