<template>
    <div v-if="getModalTeam.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div v-if="!data" class="loader at-inner-loader" id="preload"></div>
                        <div v-if="data" class="modal-content">
                            <div v-if="saving" class="modal-overlay"></div>
                            <div :class="['modal-header', saving ? 'modal-wt-pointer' : null]">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>Teams</a>
                                        </li>
                                    </ol>
                                    <div class="d-flex align-items-baseline">
                                        <h2 class="at-truncate">{{ data.name ? data.name : 'Team' }}</h2>
                                    </div>
                                </div>
                                <div class="at-nav-controls">
                                    <button
                                            @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                    ></button>
                                </div>
                            </div>
                            <div :class="['modal-body p-0', saving ? 'modal-wt-pointer' : null]">
                                <form id="team-form" @submit.prevent="send()">
                                    <div class="container p-3">
                                        <div class="row">
                                            <div class="col col-sm-4">
                                                <label for="modal-name-input" class="form-label">Name</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="data.name = $event.target.value"
                                                            v-model.trim="v$.data.name.$model"
                                                            type="text"
                                                            class="form-control"
                                                            id="modal-name-input"
                                                            name="team_form[name]"
                                                            aria-describedby="basic-addon3"
                                                    >
                                                </div>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && v$.data.name.required.$invalid">
                                                    <span class="text-danger">
                                                        Name is mandatory.
                                                    </span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <team-params
                                            class="container mt-3 collapse show"
                                            :team="data"
                                    />
                                    <team-users
                                            class="container mt-3 collapse show"
                                            :team="data"
                                    />
                                </form>
                            </div>
                            <div :class="['modal-footer', saving ? 'modal-wt-pointer' : null]">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="team-form"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                            <div v-if="saving">
                                <div class="loader at-inner-loader" id="preload"></div>
                                <span class="loading" style="">Saving changes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>

    import {detail, store, edit} from "@/services/team";
    import _ from 'lodash'
    import {mapGetters} from "vuex";
    import useVuelidate from '@vuelidate/core'
    import {required} from '@vuelidate/validators'
    import {getCookie} from "@/helpers/get-cookie";
    import TeamUsers from "@/components/modal/modal-team/team-users";
    import TeamParams from "@/components/modal/modal-team/team-params";

    export default {
        name: 'ModalTeam',
        components: {TeamParams, TeamUsers},
        setup: () => ({
            v$: useVuelidate()
        }),
        data() {
            return {
                id: null,
                data: null,
                submitted: false,
                saving: false
            }
        },
        validations() {
            return {
                data: {
                    name: {
                        required,
                    },
                    status: {
                        required,
                    }
                },
            }
        },
        computed: {
            ...mapGetters(['getModalTeam', 'getConstants']),
            isActive() {
                if (this.data) {
                    return this.data.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
                }
                return false;
            },
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalTeam, (value) => {
                if (value.show) {
                    this.id = value.id;
                    this.loadTeam();
                }
            }, {deep: true});
        },
        methods: {
            async loadTeam() {
                let response;
                try {
                    response = await detail(this.id);
                } catch (e) {
                    return;
                }

                if (this.id !== null && response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering team details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }

                this.data = response.data.result;
            },
            send() {
                if (this.validateForm()) {
                    this.save();
                }
            },
            async save() {
                this.saving = true;
                let response;
                try {
                    let formElement = document.querySelector('#team-form');
                    let formData = new FormData(formElement);
                    formData.append('id', this.id);
                    formData.append('team_form[status]', this.data.status);
                    formData.append('params', JSON.stringify(this.data.params));
                    formData.append('users', JSON.stringify(this.data.users));
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));

                    response = this.id === null ? await store(formData) : await edit(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed applying the changes",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERRO) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                _.set(this.getModalTeam, 'result', response.data.result);
                this.close();
                this.saving = false;

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            },
            changeStatus(checked) {
                this.data.status = checked
                        ? this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED
                        : this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
            },
            close() {
                this.reset();
                _.set(this.getModalTeam, 'id', null);
                _.set(this.getModalTeam, 'show', false);
                this.deleteQueryParam('teamId');
            },
            reset() {
                this.data = null;
                this.submitted = false;
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            },
        }
    }
</script>