<template>
    <div class="container p-3">
        <div class="row">
            <div class="col at-image-col">
                <img
                        class="at-app-icon sticky-top"
                        :src="appInClient.app.logos.logo_150_150"
                        alt=""
                >
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <label
                                for="app-in-client-name"
                                class="form-label"
                        >
                            App name
                        </label>
                        <input
                                :value="appInClient.app.name"
                                type="text"
                                class="form-control"
                                aria-describedby="basic-addon3"
                                id="app-in-client-name"
                                disabled
                        />
                    </div>
                    <div class="col">
                        <label
                                for="app-in-client-suffix"
                                class="form-label"
                        >
                            App Suffix
                        </label>
                        <div class="input-group mb-3">
                            <input
                                    :value="appInClient.suffix"
                                    @input="appInClient.suffix=$event.target.value"
                                    id="app-in-client-suffix"
                                    type="text"
                                    class="form-control"
                                    aria-describedby="basic-addon3"
                                    placeholder="Write an specific app suffix if you want"
                            />
                        </div>
                        <small class="form-text ml-1 error" v-if="submitted && v$.appInClient.suffix.required.$invalid">
                            <span class="text-danger">
                                App in client suffix is mandatory.
                            </span>
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="form-label">Documentation</label>
                        <div class="input-group mb-3">
                            <input
                                    :value="appInClient.app.documentation"
                                    type="text"
                                    class="form-control"
                                    aria-describedby="basic-addon3"
                                    disabled
                            />
                            <button
                                    @click="goToUrl"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id=""
                                    :disabled="appInClient.app.documentation === '' || appInClient.app.documentation === null"
                            >
                                <i class="fas fa-external-link-alt fa-w-20" :title="appInClient.app.documentation"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col">
                        <label class="form-label">Type</label>
                        <h6 class="mt-2">
                            <span
                                    :class="['badge', getAppTypeClass()]"
                            >
                                {{ appInClient.app.type }}
                            </span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from "lodash"
    import {required} from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";

    export default {
        name: "AppInClientDetail",
        props: {
            appInClient: {
                type: Object,
            },
        },
        setup: () => ({
            v$: useVuelidate()
        }),
        validations() {
            return {
                appInClient: {
                    suffix: {
                        required
                    }
                },
            }
        },
        data() {
            return {
                submitted: false
            }
        },
        methods: {
            getAppTypeClass() {
                return 'bg-' + _.toLower(this.appInClient.app.type)
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
            goToUrl() {
                window.open(this.appInClient.app.documentation, '_blank');
            }
        },
    }
</script>