<template>
    <div class="card mb-3">
        <div class="card-header at-account-level">
            <div class="at-entity-wrapper d-flex align-items-center">
                <label class="form-check-label" style="margin-right: 5px">
                    <input
                            v-model="selected"
                            :value="appInClientAccount.id"
                            type="checkbox"
                            class="form-check-input"
                    >
                </label>
                <div class="at-entity">
                    <div class="at-entity-name d-flex align-items-center">
                        {{ appInClientAccount.account.name }}
                        <div class="at-entity-status">
                    <span :class="getStatusProperties.class">
                        <div class="mr-2 badge badge-dot badge-dot-lg">{{
                                getStatusProperties.status
                            }}</div>
                    </span>
                        </div>
                    </div>
                    <div class="at-entity-id">
                        {{ appInClientAccount.account.identifier }}
                    </div>
                </div>
                <div class="at-nav-controls">
                    <div v-if="isSystemUser" class="ms-2">
                        <a :class="['dropdown-item', (!appInClientAccountExists || !isAppDebuggable) ? 'at-execute-disabled': null]"
                           @click="execute_debug()">
                            <i class="fas fa-bug" style="color: #499C54"/>
                        </a>
                    </div>
                    <div class="ms-2">
                        <a :class="['dropdown-item', !appInClientAccountExists ? 'at-execute-disabled': null]"
                           @click="execute()">
                            <i class="fas fa-bolt me-2" style="color: #0a53be"/>
                        </a>
                    </div>
                    <div v-if="isAppInClientAccountDisabled">
                        <a class="dropdown-item" @click="active">
                            <i class="fas fa-play me-2" style="color: #3A7734;"/>
                        </a>
                    </div>
                    <div v-if="isAppInClientAccountEnabled">
                        <a class="dropdown-item" @click="pause">
                            <i class="fas fa-pause me-2" style="color: #880000"/>
                        </a>
                    </div>
                    <button type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#ac-' + appInClientAccount.account.id +'-groups-elements-collapse'"
                            role="button"
                            class="btn btn-left-margin-chevron"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body collapse show" :id="'ac-' + appInClientAccount.account.id +'-groups-elements-collapse'">
            <div class="card mb-3">
                <div class="card-header at-groups-level">
                    <div class="at-entity-wrapper">
                        <h6 data-bs-toggle="collapse"
                            :data-bs-target="'#ac-'+appInClientAccount.account.id+'-account-params-collapse'">
                            Global Account Parameters</h6>
                        <div class="at-nav-controls">
                            <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#ac-'+appInClientAccount.account.id+'-account-params-collapse'"
                                    role="button"
                                    class="btn"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                            >
                                <i class="fas fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body collapse"
                     :id="'ac-' + appInClientAccount.account.id +'-account-params-collapse'">
                    <modal-app-in-client-table-params
                            :data="appInClientAccount.app_in_client_account_params"
                    />
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header at-groups-level">
                    <div class="at-entity-wrapper">
                        <h6 data-bs-toggle="collapse" :data-bs-target="'#setup-app-in-client-account-'+appInClientAccount.account.id+'-group-list-collapse'">
                            Groups</h6>
                        <div class="at-nav-controls">
                            <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#setup-app-in-client-account-'+appInClientAccount.account.id+'-group-list-collapse'"
                                    role="button"
                                    class="btn"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                            >
                                <i class="fas fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body collapse show" :id="'setup-app-in-client-account-'+appInClientAccount.account.id+'-group-list-collapse'">
                    <set-up-account-group-level
                            @dispatch-elements-selected="dispatchElementsSelected"
                            v-for="accountGroup in appInClientAccount.groups"
                            :key="accountGroup.id"
                            :app-in-client="appInClient"
                            :app-in-client-account="appInClientAccount"
                            :account-group="accountGroup"
                            :groups-selected="groupsSelected"
                            :platform="platform"
                    />
                    <div class="row mx-1 pb-3">
                        <div class="col p-0">
                            <button
                                    class="btn btn-outline-secondary"
                                    @click="deleteGroups"
                                    :disabled="groupsSelected.length === 0 || groupsSelected.length === appInClientAccount.groups.length"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="You must select a group but remember that there must be at least one configured group"
                            >
                                Delete groups
                            </button>
                        </div>
                        <div class="col p-0">
                            <div class="btn-group float-end"
                                 role="group"
                                 aria-label="Button group with nested dropdown"
                            >
                                <input
                                        @input="newAppInClientGroupName = $event.target.value"
                                        :value="newAppInClientGroupName"
                                        type="text"
                                        class="form-control input-button-radius-left"
                                        placeholder="Group name"
                                        aria-describedby="basic-addon2"
                                >
                                <button
                                        @click="addAppInClientGroup()"
                                        type="button"
                                        :class="['btn btn-outline-success text-nowrap', (!newAppInClientGroupName) ? 'at-disabled' : null]"
                                        aria-expanded="false"
                                >
                                    <i class="fas fa-plus me-1"></i>
                                    Add Group
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-in-client-account-scheduling
                    :app-in-client-account="appInClientAccount"
            />
        </div>
    </div>
</template>
<script>
    import {detail as DetailAppInClientGroup} from "@/services/app-in-client-group"
    import SetUpAccountGroupLevel
        from "@/components/modal/modal-app-in-client/setup/account-level/group-level";
    import ModalAppInClientTableParams
        from "@/components/modal/modal-app-in-client/commons/table-params";
    import _ from "lodash";
    import AppInClientAccountScheduling from "@/components/modal/modal-app-in-client/setup/account-level/scheduling";
    import {execute as Execute, pause as Pause, active as Active, execute_debug} from "@/services/app-in-client-account";
    import {mapGetters} from "vuex";
    import {isSystem} from "@/helpers/user-roles";

    export default {
        name: 'SetUpAppInClientAccountLevel',
        components: {AppInClientAccountScheduling, SetUpAccountGroupLevel, ModalAppInClientTableParams},
        props: {
            appInClientAccount: {
                type: Object,
            },
            appInClient: {
                type: Object
            },
            accountsSelected: {
                type: Array
            },
            platform: {
                type: String
            }
        },
        data() {
            return {
                selected: this.accountsSelected,
                groupsSelected: [],
                newAppInClientGroupName: null,
            }
        },
        watch: {
            selected: {
                handler: function (newValue,) {
                    this.$emit('dispatch-elements-selected', newValue);
                }
            },
            accountsSelected: {
                handler: function (newValue,) {
                    this.selected = newValue;
                }
            }
        },
        computed: {
            ...mapGetters(['getConstants', 'getUser']),
            appInClientAccountExists() {
                return Number.isInteger(this.appInClientAccount.id);
            },
            isAppDebuggable() {
                return this.appInClient.app.is_debuggable;
            },
            isAppInClientAccountEnabled() {
                return this.appInClientAccount.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
            },
            isAppInClientAccountDisabled() {
                return this.appInClientAccount.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
            },
            getStatusProperties() {
                return this.getConstants.STATUS_CLASSES[this.appInClientAccount.status];
            },
            isSystemUser() {
                return isSystem(this.getUser.role);
            },
        },
        methods: {
            async addAppInClientGroup() {
                if (_.find(this.appInClientAccount.groups, group => group.name === this.newAppInClientGroupName)) {
                    this.$toast.open({
                        message: 'Group with this name already exists',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                let response;
                try {
                    response = await DetailAppInClientGroup(null, this.appInClient.app.id, this.appInClient.client.id);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                let appInClientGroup = response.data.result;
                appInClientGroup.name = this.newAppInClientGroupName;

                this.appInClientAccount.groups.push(appInClientGroup);
                this.newAppInClientGroupName = null;
            },
            async execute() {
                let response;

                try {
                    response = await Execute(this.appInClientAccount.id);
                } catch (e) {
                    return e;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
             async execute_debug() {
                let response;

                try {
                    response = await execute_debug(this.appInClientAccount.id);
                } catch (e) {
                    return e;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
            pause() {
                this.appInClientAccount.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
            },
            active() {
                this.appInClientAccount.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
            },
            dispatchElementsSelected(selected) {
                this.groupsSelected = selected;
            },
            deleteGroups() {
                this.appInClientAccount.groups = _.filter(this.appInClientAccount.groups, group => !_.includes(this.groupsSelected, group.id));
                this.groupsSelected = [];
            },
        },
    }

</script>