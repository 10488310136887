<template>
    <div>
        <div
                class="modal-header sticky-top"
        >
            <h6 data-bs-toggle="collapse" data-bs-target="#client-accounts-variables-collapse">Variables</h6>
            <small class="form-text ml-1 error" v-if="submitted && !v$.client.accounts.allMandatoryFilled">
                <span class="text-danger">
                    Some fields are required
                </span>
            </small>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#client-variables-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="client-accounts-variables-collapse">
            <div v-if="client.accounts.length > 0">
                <account-params
                        v-for="account in client.accounts"
                        :key="account.id"
                        :account="account"
                />
            </div>
            <div class="text-center" v-else>
                <span class="text-muted">No variables</span>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import {mapGetters} from 'vuex'
    import {list} from "@/services/account-variable";
    import {detail as ParamDetail} from "@/services/account-param";
    import AccountParams from "@/components/modal/modal-client/client-account-params/account-params";
    import useVuelidate from "@vuelidate/core";

    export default {
        name: "ClientAccountParams",
        components: {AccountParams},
        props: {
            client: {
                type: Object
            }
        },
        setup: () => ({
            v$: useVuelidate()
        }),
        validations() {
            return {
                client: {
                    accounts: {
                        allMandatoryFilled: function () {
                            let mandatory = true;
                            _.forEach(this.client.accounts, account => {
                                _.forEach(account.params, param => {
                                    if (param.value === null || param.value === '') {
                                        mandatory = false;
                                        return false;
                                    }
                                });
                            });
                            return mandatory;
                        }
                    }
                },
            }
        },
        data() {
            return {
                tableConfig: {
                    columns: {
                        'name': {
                            property: 'account_variable.name',
                            columnType: 'text',
                            label: 'Name'
                        },
                        'type': {
                            property: 'account_variable.type',
                            columnType: 'text',
                            label: 'Type'
                        },
                        'value': {
                            property: 'value',
                            label: 'Value'
                        }
                    },
                    hasCheckbox: true,
                    hasFooter: false,
                    defaultValueProperty: 'account_variable.default_value'
                },
                paramsSelected: [],
                accountVariables: [],
                searchAccountVariable: '',
                accountVariableSelected: null,
                submitted: false
            }
        },
        computed: {
            ...mapGetters(['getModalClient']),
            getAccountVariablesIdsByClient() {
                if (this.client) {
                    let ids = [];
                    _.forEach(this.client.params, param => {
                        ids.push(param.account_variable.id);
                    });
                    return ids;
                }
                return [];
            },
            getAccountVariables() {
                return _.filter(this.accountVariables, element => !_.includes(this.getAccountVariablesIdsByClient, element.id));
            }
        },
        created() {
            this.loadAccountVariables();
        },
        methods: {
            async loadAccountVariables() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.accountVariables = response.data.result;
            },
            async addAccountVariable() {
                let response;
                try {
                    response = await ParamDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                let param = response.data.result;
                param.account_variable = this.accountVariableSelected;
                this.client.params.push(param);

                this.accountVariableSelected = null;
            },
            deleteSelectedAccountVariable() {
                if (this.paramsSelected.length > 0) {
                    let items = [];
                    _.forEach(this.paramsSelected, item => {
                        items.push(item.id);
                    });
                    this.client.params = _.filter(_.cloneDeep(this.client.params),
                            element => !_.includes(items, element.id))
                    this.paramsSelected = [];
                }
            },
            dispatchSelectedChange(value) {
                this.paramsSelected = value;
            },
            eraseAccountVariableSearch() {
                this.searchAccountVariable = '';
            },
            selectAccountVariable(element) {
                this.accountVariableSelected = element;
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
        }
    }
</script>