<template>
    <div>
        <div class="table-responsive mt-4">
            <table id="" class="table table-striped table-hover table-bordered at-table w-100">
                <table-header
                        @dispatch-header-checked="checkOrUncheckOptions"
                        @dispatch-order-by="changeOrderBy"
                        :data="data"
                        :data-selected="dataSelected"
                        :columns="config.columns"
                        :has-checkbox="config.hasCheckbox"
                        :has-actions="config.hasActions"
                        :table-sort="tableSort"
                />
                <tbody v-if="filtering">
                <tr>
                    <td :colspan="getColspanLength(config)" style="text-align: center;">
                        <i class="fas fa-spinner fa-spin"/> Filtering data
                    </td>
                </tr>
                </tbody>
                <tbody v-else-if="block.length === 0">
                <tr v-if="dataFiltered.length === 0">
                    <td :colspan="getColspanLength(config)" class="text-center">No data</td>
                </tr>
                </tbody>
                <tbody
                        v-else-if="block.length > 0"
                >
                <tr
                        v-for="row in block"
                        :key="row.id"
                >
                    <th
                            v-if="config.hasCheckbox"
                            class="at-th-checkbox"
                    >
                        <label for="commons-table-check-all"/>
                        <input
                                v-model="dataSelected"
                                :value="row.id"
                                id="commons-table-check-all"
                                type="checkbox"
                                class="form-check-input"
                        >
                    </th>
                    <slot name="content" v-bind:row="row">
                        <td
                                v-for="column in config.columns"
                                :key="'body-' + getPropertyColumnValue(row, column)"
                        >
                            <span>{{ getPropertyColumnValue(row, column) }}</span>
                        </td>
                    </slot>
                    <td
                            v-if="config.hasActions"
                    >
                        <slot name="actions" v-bind:row="row"/>
                    </td>
                </tr>
                </tbody>
                <table-footer
                        @dispatch-header-checked="checkOrUncheckOptions"
                        @dispatch-order-by="changeOrderBy"
                        :data="data"
                        :data-selected="dataSelected"
                        :columns="config.columns"
                        :has-checkbox="config.hasCheckbox"
                        :has-actions="config.hasActions"
                        :table-sort="tableSort"
                />
            </table>
        </div>
        <div class="row">
            <div class="col">
                <nav aria-label="Page navigation example" class="float-end">
                    <ul class="pagination">
                        <li
                                @click="previousPage()"
                                :class="['page-item', !allowPrevious ? 'button-no-clickable' : null]"
                        >
                            <a class="page-link" href="#">Previous</a>
                        </li>
                        <li
                                id="page-selector"
                                :class="['page-item', isCurrentPageClass(page)]"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="..."
                        >
                            <button class="page-link dropdown-toggle" href="#">
                              {{ page + 1}}
                            </button>
                        </li>
                        <div
                            tabindex="-1"
                            role="menu"
                            aria-hidden="true"
                            class="dropdown-menu at-dropdown-menu-pages overflow-auto">
                            <div class="page-scrollbar-container ps ">
                                <section class="at-dropdown-items_listWrap">
                                    <div class="col-xs-12">
                                    </div>
                                    <ul class="nav flex-column">
                                        <li v-for="page in getNextPages"
                                            @click="setPage(page)"
                                            :key="page"
                                        >
                                            <a :class="['dropdown-item', getNextPages === page ? 'active' : null]">
                                                {{ page }}
                                            </a>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <li
                                @click="nextPage()"
                                :class="['page-item', !allowNext ? 'button-no-clickable' : null]"
                        >
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {mapGetters} from "vuex";
    import TableHeader from "@/components/commons/table/components/header";
    import TableFooter from "@/components/commons/table/components/footer";
    import Constants from "@/helpers/constants"

    export default {
        name: 'ModalAppTable',
        components: {
            TableFooter,
            TableHeader,
        },
        props: {
            config: {
                type: Object,
                required: true,
            },
            data: {
                type: Array,
                required: true,
            }
        },
        data() {
            return {
                page: 0,
                pageSize: 8,
                dataFiltered: this.data,
                dataSelected: [],
                filtering: false,
                tableSort: {column: '', order: false},
                eventsByElementAction: {}
            }
        },
        created() {
            this.eventsByElementAction[Constants.ELEMENTS_ACTIONS.ACTION_ENABLE] = 'dispatch-elements-enable';
            this.eventsByElementAction[Constants.ELEMENTS_ACTIONS.ACTION_DISABLE] = 'dispatch-elements-disable';
            this.eventsByElementAction[Constants.ELEMENTS_ACTIONS.ACTION_DELETE] = 'dispatch-elements-delete';
        },
        mounted() {
            this.$store.watch(state => [state.searchTerm, state.elementsViewMode], () => this.makeFiltration());
        },
        watch: {
            data: {
                handler: function (newValue,) {
                    this.dataFiltered = newValue;
                    this.makeFiltration();
                },
                deep: true
            },
        },
        computed: {
            ...mapGetters(['getSearchTerm', 'getElementsViewMode', 'getConstants']),
            items() {
                return _.chunk(this.dataFiltered, this.pageSize);
            },
            block() {
                return this.items.length === 0 ? [] : this.items[this.page];
            },
            currentPageSize() {
                return this.block.length;
            },
            start() {
                if (this.items.length === 0) {
                    return 0;
                }
                let normalStart = (this.page * this.currentPageSize) + 1;
                let optimalStart = (this.page * this.pageSize) + 1;
                if (normalStart < optimalStart) {
                    return ((this.page - 1) * this.pageSize) + this.pageSize + 1;
                }
                return normalStart;
            },
            end() {
                if (this.items.length === 0) {
                    return 0;
                }

                let end = (this.start - 1) + this.currentPageSize;
                return this.total < end ? this.total : end;
            },
            total() {
                return this.dataFiltered ? this.dataFiltered.length : 0;
            },
            allowNext() {
                return ((this.page + 1) * this.pageSize) < this.total;
            },
            allowPrevious() {
                return this.page > 0;
            },
            getNextPages() {
                let page = 1;
                let pages = [];
                while (page <= this.items.length) {
                    pages.push(page);
                    page++;
                }
                return pages;
            },
            getFilterFieldsValid() {
                let fields = [];
                _.forEach(this.config.columns, field => {
                    fields.push(field.property);
                });
                return fields;
            },
            getActionSelected() {
                return this.commonActionSelected ? this.commonActionSelected : "Choose an action...";
            }
        },
        methods: {
            nextPage() {
                if (this.allowNext) {
                    this.page = this.page + 1;
                }
            },
            previousPage() {
                if (this.allowPrevious) {
                    this.page = this.page - 1;
                }
            },
            setPage(page) {
                this.page = page - 1;
            },
            isCurrentPageClass(page) {
                return this.page === (page - 1) ? 'active' : null;
            },
            getColspanLength(config) {
                let colspan = config.columns.length;
                if (config.hasCheckbox) {
                    colspan++;
                }
                if (config.hasActions) {
                    colspan++;
                }
                return colspan;
            },
            searchValue(item, val) {
                let isItemValid = false;
                _.forOwn(item, (value, key) => {
                    if (_.includes(this.getFilterFieldsValid, key) && _.includes(_.toLower(value), _.toLower(val))) {
                        isItemValid = true;
                        return null;
                    }
                });
                return isItemValid;
            },
            makeFiltration() {
                this.page = 0;
                this.filtering = true;
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout);
                }
                this.searchTimeout = setTimeout(() => {
                    this.dataFiltered = _.cloneDeep(this.data);
                    if (this.getSearchTerm) {
                        this.dataFiltered = _.filter(this.dataFiltered, item => {
                            return this.searchValue(item, this.getSearchTerm)
                        });
                    }
                    if (this.getElementsViewMode !== this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ALL) {
                        this.dataFiltered = _.filter(this.dataFiltered, item => item.status === this.getElementsViewMode);
                    }
                    this.filtering = false;
                }, 400);
            },
            changeOrderBy(column) {
                if (column.property !== this.tableSort.column) {
                    this.tableSort = {column: '', order: false};
                }

                let order;
                if (!this.tableSort.order) {
                    order = 'asc';
                } else if (this.tableSort.order === 'asc') {
                    order = 'desc';
                } else if (this.tableSort.order === 'desc') {
                    order = false;
                }

                if (order) {
                    this.dataFiltered = _.orderBy(this.dataFiltered, [column.property], [order]);
                } else {
                    this.dataFiltered = _.orderBy(this.dataFiltered, ['id'], ['asc']);
                }

                this.tableSort = {column: column.property, order: order};
            },
            checkOrUncheckOptions(value) {
                this.dataSelected = [];
                if (value === true) {
                    _.forEach(this.dataFiltered, (item) => {
                        this.dataSelected.push(item.id);
                    });
                } else {
                    this.setCommonAction(null);
                }
            },
            setCommonAction(action) {
                this.commonActionSelected = action;
            },
            applyAction(){
                this.$emit(this.eventsByElementAction[this.commonActionSelected], this.dataSelected);
                this.dataSelected = [];
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column.property, row);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            }
        },
    }
</script>