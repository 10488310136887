<template>
    <div>
        <div class="nav-scroller at-nav-scroller bg-body shadow-sm p-2">
            <nav class="nav nav-underline" aria-label="Secondary navigation">
                <div class="at-breadcrumb">
                    <ol class="breadcrumb ">
                        <li class="breadcrumb-item">
                            <span>
                                Home
                            </span>
                        </li>
                    </ol>
                    <h2>Users</h2>
                </div>
                <div class="at-nav-controls">
                    <div class="input-group me-2">
                        <label for="nav-users-search"/>
                        <input
                                :value="searchTerm"
                                @input="searchTerm = $event.target.value"
                                id="nav-users-search"
                                type="text"
                                class="form-control"
                                placeholder="Search users"
                        >
                        <button
                                v-if="searchTerm === ''"
                                class="btn btn-outline-secondary at-icon-search-sidebar"
                                type="button"
                                id="button-addon2"
                        >
                            <i class="fas fa-search"/>
                        </button>
                        <button @click="eraseSearch"
                                type="button"
                                class="btn btn-outline-secondary at-restore-search-sidebar"
                                id="button-addon3"
                                v-else>
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                    <button
                            @click="openUserModal()"
                            class="btn btn-primary btn-sm text-nowrap"
                    >
                        <i class="fas fa-plus"/>
                        New user
                    </button>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import {getCredentialsFromDom} from "../../services/page-context";
    import _ from 'lodash'
    import {mapGetters} from "vuex";

    export default {
        name: 'NavUsers',
        updated() {
            this.$nextTick(function () {
                let credentials = getCredentialsFromDom();
                this.$store.dispatch('setModalCredentials', {modalCredentials: credentials.credentials});
                console.log(this.$route.query);
                console.log("Credentials: ", credentials);
            })
        },
        data() {
            return {
                searchTerm: '',
            }
        },
        computed:{
          ...mapGetters(['getModalUser'])
        },
        watch: {
            searchTerm: function (newValue) {
                this.$store.dispatch('setSearchTerm', {searchTerm: newValue});
            }
        },
        methods: {
            eraseSearch() {
                this.searchTerm = '';
            },
            openUserModal() {
                _.set(this.getModalUser, 'show', true);
            },
        }
    }
</script>


<style lang="scss" scoped>
.at-breadcrumb {
    --bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns= 'http://www.w3.org/2000/svg' width= '8' height= '8' %3E%3Cpath d= 'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill= 'currentColor' /%3E%3C/svg%3E&#34;);
}
</style>
