<template>
    <select
            v-model="selected"
            class="form-select form-select-sm"
            aria-label="Default select example"
            multiple
            :disabled="disabled"
    >
        <option
                v-for="option in options"
                :key="option.value"
                :value="option"
        >
            {{ option.name }}
        </option>
    </select>
</template>
<script>
    import _ from "lodash";

    export default {
        name: "MultiselectField",
        props: {
            value: {
                type: Array,
                required: false,
                default: function () {
                    return [];
                }
            },
            options: {
                type: Array,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data(){
            return {
                selected: _.cloneDeep(this.value)
            }
        },
        watch: {
            value: {
                handler: function (newValue,) {
                    this.selected = _.cloneDeep(newValue);
                },
            },
            selected: {
                handler: function (newValue, oldValue) {
                    if (!_.isEqual(newValue, oldValue)) {
                        this.$emit('dispatch-multiple-select', _.cloneDeep(newValue));
                    }
                },
            }
        }
    }
</script>