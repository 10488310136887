import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/marketplace/scripts/list/', {params});
}

export function listAllScripts(appId) {
    const params = {appId: appId};
    return AxiosWrapper('/marketplace/scripts/list_app/', {params});
}

/**
 * @returns Promise
 * @param scriptId
 */
export function detail(scriptId) {
    const params = {scriptId: scriptId};
    return AxiosWrapper('/marketplace/scripts/detail/', {params});
}

/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/marketplace/scripts/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/marketplace/scripts/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/marketplace/scripts/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/marketplace/scripts/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function executeScripts(id, all, currentMonth) {
    const params = {id: id, all: all, current_month: currentMonth};
    return AxiosWrapper('/marketplace/scripts/execute/', {params});
}

