<template>
    <div class='adblocker-config-wrapper show' id="adblocker-config">
        <div class="adblocker-config-panel ">
            <div class="adblocker-config-panel-header">
                <div class="logo-img">
                    <img :src="appIcon">
                </div>
            </div>
            <div class="adblocker-config-panel-content mt-2">
                <div class="mb-3">
                    <p>
                        You are using an ad blocker. For the correct functioning of this page, you will need to disable
                        it. Follow the steps below depending on the adblocker you are using.
                    </p>
                </div>
                <div id="" class="collapse show" aria-labelledby="headingOne">
                    <ul class="nav nav-tabs">
                        <li class="nav-item ">
                            <a class="nav-link active" data-toggle="tab" href="#ab-1">
                                <img :src="abIcon" alt="">
                                <span>AdBlock</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#ab-2">
                                <img :src="abpIcon" alt="">
                                <span>AdBlock Plus</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#ab-3">
                                <img :src="uoIcon" alt="">
                                <span>uBlock Origin</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#ab-4">
                                <div class="ab-other-icon">...</div>
                                <span>Other</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content ">
                        <div class="tab-pane active" id="ab-1">
                            <img class="ab-browser-image" :src="abBrowserIcon" alt="">
                            <ol class="ab-instructions">
                                <li>
                                    Click on the AdBlock icon <img :src="abIcon" alt="">, located in
                                    the browser extensions area (in the upper-right corner of the screen). You may see a
                                    small number covering part of the icon.
                                </li>
                                <li>
                                    Select <strong>Do not run on this website</strong>.
                                </li>
                                <li>
                                    In the Don't run AdBlock on... dialog box, select <strong>Exclude</strong>. The
                                    AdBlock icon will change to a thumb-raised fist.
                                </li>
                            </ol>
                        </div>
                        <div class="tab-pane" id="ab-2">
                            <img class="ab-browser-image" :src="abpBrowserIcon" alt="">
                            <ol class="ab-instructions">
                                <li>
                                    Click on the AdBlock Plus icon <img :src="abpIcon" alt="">, located
                                    in the extensions area of the browser (in the upper-right corner of the screen). You
                                    may see a small number covering part of the icon.
                                </li>
                                <li>
                                    Click the activation button <img :src="abpPowerIcon" alt=""> so
                                    that it slides to the left.

                                </li>
                                <li>
                                    Click the <strong>Update</strong> button.
                                </li>
                            </ol>

                        </div>
                        <div class="tab-pane" id="ab-3">
                            <img class="ab-browser-image" :src="uoBrowser" alt="">
                            <ol class="ab-instructions">
                                <li>
                                    Click on the uBlock Origin icon <img :src="uoIcon" alt="">, located in
                                    the extensions area of the browser (in the upper-right corner of the screen). You
                                    may see a small number covering part of the icon.
                                </li>
                                <li>
                                    Click the activation button <img :src="uoPowerIcon" alt="">. It
                                    will be grayed out, indicating that ads from that website will no longer be blocked.

                                </li>
                                <li>
                                    Click the <strong>Update</strong> <img :src="uoRefreshIcon" alt="">button.
                                </li>
                            </ol>
                        </div>
                        <div class="tab-pane" id="ab-4">
                            <ol class="ab-instructions">
                                <li>
                                    Click on the icon of the ad blocker extension installed in your browser.
                                    <span class="text-lt">It's usually located in the upper-right corner of the screen. You may have more than one ad blocker installed. </span>
                                </li>
                                <li>
                                    Follow the instructions to disable the ad blocker on the website you are viewing.
                                    <span class="text-lt">You may have to select an option from a menu or click a button.</span>
                                </li>
                                <li>
                                    Refresh the page by following the prompts or by clicking the Refresh or Reload
                                    button in your browser.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div id="collapse-manageCookies-footer" class="adblocker-config-panel-footer collapse">
                <div class="biddeo-ml-auto">

                    <a role='button' class='biddeo-action-button btn-outline' onclick="location.reload();">Refresh</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import uoRefreshIcon from '@/assets/images/sweet_home_alabama/uo_refresh_icon-1.svg'
    import uoPowerIcon from '@/assets/images/sweet_home_alabama/uo_power_icon-1.svg'
    import uoIcon from '@/assets/images/sweet_home_alabama/uo_icon-1.svg'
    import uoBrowser from '@/assets/images/sweet_home_alabama/browser_uo-2.png'
    import abpPowerIcon from '@/assets/images/sweet_home_alabama/abp_power_icon-1.svg'
    import abpIcon from '@/assets/images/sweet_home_alabama/abp_icon-1.svg'
    import abpBrowserIcon from '@/assets/images/sweet_home_alabama/browser_abp-2.png'
    import abIcon from '@/assets/images/sweet_home_alabama/ab_icon-1.svg'
    import abBrowserIcon from '@/assets/images/sweet_home_alabama/browser_ab-2.png'
    import appIcon from '@/assets/images/the-platform-logo-w-powered.svg'


    export default {
        name: 'AdBlocker',
        data(){
            return {
                uoRefreshIcon: uoRefreshIcon,
                uoPowerIcon: uoPowerIcon,
                uoIcon: uoIcon,
                uoBrowser: uoBrowser,
                abpPowerIcon: abpPowerIcon,
                abpIcon: abpIcon,
                abpBrowserIcon: abpBrowserIcon,
                abIcon: abIcon,
                abBrowserIcon: abBrowserIcon,
                appIcon: appIcon
            }
        }
    }
</script>