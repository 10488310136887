<template>
    <div v-if="getModalOptionsParameters.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="small-modal modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">
                                    {{ parameter ? 'Options for: ' + parameter : 'Options' }}
                                </h5>
                            </div>
                            <div class="modal-body">
                                <div class="at-modal-options-container">
                                    <div class="row">
                                        <div class="col col-md-7">
                                            <input
                                                    :value="newOption"
                                                    @input="newOption = $event.target.value"
                                                    @keyup.enter="addOption()"
                                                    type="text"
                                                    class="form-control"
                                            >
                                        </div>
                                        <div class="col">
                                            <button
                                                    @click="addOption()"
                                                    class="btn btn-outline-success"
                                            >
                                                <i class="fas fa-plus-circle"></i>
                                                Add option
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="options.length > 0" class="row mt-4 options-scroll">
                                    <div class="row" v-for="option in options"
                                         :key="option.name">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a @click="deleteOption(option)" href="#">
                                                    <i class="fas fa-times"></i>
                                                </a>
                                            </div>
                                            <div class="ms-2">
                                                {{ option.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row mt-3">
                                    <span class="text-center text-muted">No options configured</span>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                        @click="saveChanges()"
                                        type="button"
                                        class="btn btn-primary"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import _ from 'lodash'

    export default {
        name: 'ModalOptionsParameters',
        computed: {
            ...mapGetters(['getModalOptionsParameters'])
        },
        data() {
            return {
                id: null,
                parameter: null,
                options: [],
                newOption: null
            }
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalOptionsParameters, (value) => {
                if (value.show === true && value.result === null) {
                    this.id = value.data.id;
                    this.parameter = value.data.parameter;
                    if (value.data.options !== null) {
                        this.options = value.data.options;
                    }
                }
            }, {deep: true});
        },
        methods: {
            addOption() {
                if ((_.find(this.options, option => option.name === this.newOption)) !== undefined) {
                    this.$toast.open({
                        message: "Option is already in the list",
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }
                this.options.push({name: this.newOption, value: this.newOption});
                this.newOption = null;
            },
            deleteOption(option) {
                this.options = _.filter(this.options, opt => opt.name !== option.name);
            },
            close() {
                _.set(this.getModalOptionsParameters, 'data', null);
                _.set(this.getModalOptionsParameters, 'show', false);
                this.id = null;
                this.parameter = null;
                this.options = [];
            },
            saveChanges() {
                _.set(this.getModalOptionsParameters, 'result', {
                    id: this.id,
                    parameter: this.parameter,
                    options: this.options
                });
                this.close();
            }
        }
    }
</script>