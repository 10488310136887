export const setSearchTerm = ({commit}, {searchTerm}) => {
    commit('SET_SEARCH_TERM', searchTerm);
};

export const setModalClient = ({commit}, {modalClient}) => {
    commit('SET_MODAL_CLIENT', modalClient);
};

export const setModalScript = ({commit}, {modalScript}) => {
    commit('SET_MODAL_SCRIPT', modalScript);
};

export const setConstants = ({commit}, {constants}) => {
    commit('SET_CONSTANTS', constants);
};

export const setModalApp = ({commit}, {modalApp}) => {
    commit('SET_MODAL_APP', modalApp);
};

export const setModalAppInClient = ({commit}, {modalAppInClient}) => {
    commit('SET_MODAL_APP_IN_CLIENT', modalAppInClient);
};

export const setModalAppsFilter = ({commit}, {modalAppsFilter}) => {
    commit('SET_MODAL_APPS_FILTER', modalAppsFilter);
};

export const setModalMarketplace = ({commit}, {modalMarketplace}) => {
    commit('SET_MODAL_MARKETPLACE', modalMarketplace);
};

export const setModalCredentials = ({commit}, {modalCredentials}) => {
    commit('SET_MODAL_CREDENTIALS', modalCredentials);
};

export const setModalUser = ({commit}, {modalUser}) => {
    commit('SET_MODAL_USER', modalUser);
};

export const setModalTeam = ({commit}, {modalTeam}) => {
    commit('SET_MODAL_TEAM', modalTeam);
};

export const setModalResetPassword = ({commit}, {modalResetPassword}) => {
    commit('SET_MODAL_RESET_PASSWORD', modalResetPassword);
};

export const setModalOptionsParameters = ({commit}, {modalOptionsParameters}) => {
    commit('SET_MODAL_OPTIONS_PARAMETERS', modalOptionsParameters);
};

export const setAppsFiltered = ({commit}, {appsFiltered}) => {
    commit('SET_APPS_FILTERED', appsFiltered);
};

export const setElementsViewMode = ({commit}, {elementsViewMode}) => {
    commit('SET_ELEMENTS_VIEW_MODE', elementsViewMode);
};

export const setClientTeam = ({commit}, {clientTeams}) => {
    commit('SET_CLIENT_TEAM', clientTeams);
};

export const setCurrentClient = ({commit}, {currentClient}) => {
    commit('SET_CURRENT_CLIENT', currentClient);
};

export const setUser = ({commit}, {user}) => {
    commit('SET_USER', user);
};

export const setAppPlatform = ({commit}, {appPlatform}) => {
    commit('SET_APP_PLATFORM', appPlatform);
};

export const setAdBlock = ({commit}, {adBlock}) => {
    commit('SET_AD_BLOCK', adBlock);
};