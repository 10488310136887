<template>
    <div class="container p-3">
        <div class="row">
            <div class="col at-image-col">
                <image-dropzone
                        @dispatch-file-change="dispatchFileChange"
                        @dispatch-file-delete="dispatchFileDelete"
                        :file-url="client.logos.logo_150_150"
                />
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <label for="client-name" class="form-label">Client Name</label>
                        <div class="input-group mb-3">
                            <input
                                    @input="client.name=$event.target.value"
                                    v-model.trim="v$.client.name.$model"
                                    name="client_form[name]"
                                    type="text"
                                    class="form-control"
                                    id="client-name"
                                    aria-describedby="basic-addon3"
                            />
                        </div>
                        <small class="form-text ml-1 error" v-if="submitted && v$.client.name.required.$invalid">
                            <span class="text-danger">
                                Client name is mandatory.
                            </span>
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <label for="client-team" class="form-label">Team</label>
                            <select-simple
                                    @dispatch-change="selectTeam"
                                    :options="teamOptions"
                                    :value="client.team"
                                    position-class="text-end"
                                    default-print="Select a team"
                                    id="client-team"
                            />
                            <small class="form-text ml-1 error" v-if="submitted && v$.client.team.required.$invalid">
                                <span class="text-danger">
                                    Client team is mandatory.
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {list as TeamsList} from '@/services/team';
    import useVuelidate from '@vuelidate/core'
    import {required} from '@vuelidate/validators'
    import SelectSimple from "@/components/commons/select-simple";
    import ImageDropzone from "@/components/modal/commons/image-dropzone";

    export default {
        name: 'ClientDetail',
        components: {ImageDropzone, SelectSimple},
        setup: () => ({
            v$: useVuelidate()
        }),
        props: {
            client: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                submitted: false,
                file: null,
                fileChanged: false,
                teamOptions: []
            }
        },
        created() {
            this.loadTeamOptions();
        },
        validations() {
            return {
                client: {
                    name: {
                        required,
                    },
                    team: {
                        required
                    }
                },
            }
        },
        methods: {
            async loadTeamOptions() {
                let response;
                try {
                    response = await TeamsList()
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.teamOptions = response.data.result;
            },
            selectTeam(team) {
                this.client.team = team;
            },
            dispatchFileChange(file) {
                this.file = file;
                this.fileChanged = true;
            },
            dispatchFileDelete() {
                this.file = null;
                this.fileChanged = true;
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
        }
    }
</script>