<template>
    <div>
        <div
                class="modal-header sticky-top"
                style="background-color: white"
        >
            <h6 data-bs-toggle="collapse" data-bs-target="#setup-app-in-client-accounts-collapse">Set up</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#setup-app-in-client-accounts-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"></i>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="setup-app-in-client-accounts-collapse">
            <small v-if="submitted && v$.appInClient.accounts.atLeastOneAccount.$invalid" class="form-text ml-1 error">
                <span class="text-danger">
                    You must have at least one account
                </span>
            </small>
            <div class="mt-4 pb-4">
                <div v-if="appInClient.accounts.length > 0">
                    <set-up-app-in-client-account-level
                            @dispatch-elements-selected="dispatchElementsSelected"
                            v-for="appInClientAccount in appInClient.accounts"
                            :key="appInClientAccount.id"
                            :app-in-client="appInClient"
                            :app-in-client-account="appInClientAccount"
                            :accounts-selected="accountsSelected"
                            :platform="appInClient.app.type"
                    />
                </div>
                <div class="text-center" v-else>
                    <span class="text-muted">No accounts</span>
                </div>
            </div>
            <div class="row mx-1 pb-3">
                <div class="col p-0">
                    <button
                            class="btn btn-outline-secondary"
                            @click="deleteAccounts"
                            :disabled="accountsSelected.length === 0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="You must select a account but remember that there must be at least one configured account"
                    >
                        Delete accounts
                    </button>
                </div>
                <div class="col p-0">
                    <div class="btn-group float-end"
                         role="group"
                         aria-label="Button group with nested dropdown"
                    >
                        <multi-select-entity-search
                                @dispatch-select-elements="addAppInClientAccounts($event)"
                                :options="getNonAssignedAccounts"
                                :is-button-group="true"
                                default-print="Select accounts..."
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {detail_accounts as DetailAppInClientAccounts} from "@/services/app-in-client-account"
    import _ from "lodash"
    import useVuelidate from "@vuelidate/core";
    import SetUpAppInClientAccountLevel from "@/components/modal/modal-app-in-client/setup/account-level";
    import MultiSelectEntitySearch from "@/components/commons/multiselect-entity";
    import {getCookie} from "@/helpers/get-cookie";
    import Constants from "@/helpers/constants";
    import {mapGetters} from "vuex";

    export default {
        name: "SetUp",
        components: {MultiSelectEntitySearch, SetUpAppInClientAccountLevel},
        props: {
            appInClient: {
                type: Object,
            },
        },
        setup: () => ({
            v$: useVuelidate()
        }),
        validations() {
            return {
                appInClient: {
                    accounts: {
                        atLeastOneAccount: function () {
                            return this.appInClient.accounts.length > 0;
                        }
                    }
                },
            }
        },
        data() {
            return {
                searchAccountOption: '',
                selectedAccount: null,
                accountsSelected: [],
                submitted: false
            }
        },
        computed: {
            ...mapGetters(['getConstants']),
            getAccountIds() {
                let ids = [];
                _.forEach(this.appInClient.accounts, app_in_client_account => {
                    ids.push(app_in_client_account.account.id);
                });
                return ids;
            },
            getNonAssignedAccounts() {
                let accounts = _.cloneDeep(this.appInClient.client.accounts);
                if (this.appInClient.app.type !== this.getConstants.APP_TYPE_SYSTEM) {
                    accounts = this.appInClient.client.accounts_by_platform[this.appInClient.app.type];
                }
                return _.filter(accounts, account => !_.includes(this.getAccountIds, account.id));
            },
        },
        methods: {
            async addAppInClientAccounts(accounts) {

                let response;
                try {
                    const formData = new FormData();
                    formData.append('app', this.appInClient.app.id);
                    formData.append('client', this.appInClient.client.id);
                    formData.append('accounts', JSON.stringify(accounts));
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                    response = await DetailAppInClientAccounts(formData);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                _.forEach(response.data.result, account => {
                    this.appInClient.accounts.push(account);
                });
            },
            dispatchElementsSelected(selected) {
                this.accountsSelected = selected;
            },
            deleteAccounts() {
                this.appInClient.accounts = _.filter(this.appInClient.accounts, account => !_.includes(this.accountsSelected, account.id));
                this.accountsSelected = [];
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
        }
    }
</script>
<style>
.form-control.input-button-radius-left {
    border-radius: 0.25rem 0 0 0.25rem;
}
</style>