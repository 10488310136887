<template>
    <div v-if="getModalScript.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div v-if="!script" class="loader at-inner-loader" id="preload"></div>
                        <div v-if="script" class="modal-content">
                            <div v-if="saving" class="modal-overlay"></div>
                            <div :class="['modal-header', saving ? 'modal-wt-pointer' : null]">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>Scripts</a>
                                        </li>
                                    </ol>
                                    <h2 v-if="script && script.name!==''">{{ script.name }}</h2>
                                    <h2 v-else-if="script.name===''">{{ script.name }}</h2>
                                    <h2 v-else>Script name</h2>
                                </div>
                                <div class="at-nav-controls">
                                    <button @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close">
                                    </button>
                                </div>


                            </div>
                            <div :class="['modal-body', saving ? 'modal-wt-pointer' : null]">
                                <form id="script-form" @submit.prevent="send()">
                                    <div class="row">
                                        <div class="col">
                                            <label>Script Name</label>
                                            <div class="input-group mb-3">
                                                <input
                                                        :value="script.name"
                                                        @input="script.name=$event.target.value"
                                                        name="script[name]"
                                                        type="text"
                                                        class="form-control"
                                                        id="basic-url"
                                                        aria-describedby="basic-addon3"
                                                />
                                            </div>
                                            <small class="form-text ml-1 error"
                                                   v-if="submitted && v$.script.name.required.$invalid">
                                                <span class="text-danger">
                                                    Script name is mandatory.
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <label>Path</label>
                                            <div class="input-group mb-3">
                                                <input
                                                        :value="script.path"
                                                        @input="script.path=$event.target.value"
                                                        type="text"
                                                        class="form-control"
                                                        id="asdf2"
                                                        aria-describedby="basic-addon3"
                                                />
                                            </div>
                                            <small class="form-text ml-1 error"
                                                   v-if="submitted && v$.script.path.required.$invalid">
                                <span class="text-danger">
                                    Script path is mandatory.
                                </span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="d-flex mt-3 ms-4 form-check form-switch">
                                                <input @change="isMain($event.target.checked)"
                                                       :checked="script.main"
                                                       class="form-check-input"
                                                       type="checkbox"
                                                       role="switch"
                                                       id="flexSwitchCheckChecked"/>
                                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                                    Main script
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="row">
                                                <label for="script-queue" class="form-label">Queue</label>
                                                <select-simple
                                                        @dispatch-change="selectQueue"
                                                        :options="queueOptions"
                                                        :value="getPriority()"
                                                        position-class="text-end"
                                                        default-print="Select a queue"
                                                        id="script-queue"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="at-tree mb-2">
                                                <tree-view
                                                        id="my-tree"
                                                        :initial-model="script['path_tree']"
                                                        :model-defaults="modelDefaults"
                                                        @treeNodeDblclick="setPath($event)"
                                                >
                                                    <template v-slot:text="{ model, customClasses }">
                                                        <div class="d-flex align-items-center">
                                                            <div class="ms-1 mt-1 mb-1">
                                                                <i :class="model.icon"></i>
                                                                <span class="ms-1">{{ model.label }}</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </tree-view>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div :class="['modal-footer', saving ? 'modal-wt-pointer' : null]">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal">
                                    <i class="fas fa-times-circle me-2"></i>
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="script-form"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                            <div v-if="saving">
                                <div class="loader at-inner-loader" id="preload"></div>
                                <span class="loading" style="">Saving changes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {detail} from '@/services/script';
    import {store} from '@/services/script';
    import {edit} from '@/services/script';
    import SelectSimple from "@/components/commons/select-simple";
    import _ from "lodash";
    import useVuelidate from "@vuelidate/core";
    import {getCookie} from "@/helpers/get-cookie";
    import {email, required} from "@vuelidate/validators";
    import TreeView from "@grapoza/vue-tree"


    export default {
        name: 'ModalScript',
        components: {SelectSimple, TreeView},
        setup: () => ({
            v$: useVuelidate()
        }),
        data() {
            return {
                id: null,
                script: null,
                queueOptions: [],
                submitted: false,
                saving: false,
                modelDefaults: {
                    state: {
                        expanded: false,
                    },
                    customizations: {
                        classes: {
                            treeViewNodeSelf: 'large-line',
                            treeViewNodeSelfText: 'big-text'
                        },
                    }
                },
            }
        },
        created() {
            this.queueOptions = this.getConstants.PRIORITY_OPTIONS;
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalScript, (value) => {
                if (value.show) {
                    this.id = value.id;
                    this.loadScript();
                }
            }, {deep: true});
        },
        validations() {
            return {
                script: {
                    name: {
                        required,
                    },
                    path: {
                        required
                    },
                },
            }
        },
        computed: {
            ...mapGetters(['getModalScript', 'getConstants'])
        },
        methods: {
            async loadScript() {
                let response;
                try {
                    response = await detail(this.id);
                } catch (e) {
                    return;
                }

                if (this.id !== null && response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering script details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }

                this.script = response.data.result;
            },
            close() {
                _.set(this.getModalScript, 'id', null);
                _.set(this.getModalScript, 'show', false);
                this.deleteQueryParam('scriptId');
                this.script = null;
                this.submitted = false;
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            },
            send() {
                let validForm = [];
                validForm.push(this.validateForm());

                if (!_.includes(validForm, false)) {
                    this.save();
                }
            },
            async save() {
                this.saving = true;
                let response;
                let formElement = document.querySelector('#script-form');
                let formData = new FormData(formElement);
                formData.append('id', this.id);
                formData.append('name', this.script.name);
                formData.append('main', this.script.main);
                formData.append('path', this.script.path);
                formData.append('queue', this.script.queue);
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));

                try {
                    response = this.id === null ? await store(formData) : await edit(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed applying the changes",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                _.set(this.getModalScript, 'result', response.data.result);
                this.close();
                this.saving = false;

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
            selectQueue(queue) {
                this.script.queue = queue.name;
            },
            isMain(value) {
                this.script.main = value;
            },
            getPriority() {
                return this.queueOptions.find(item => item.name === this.script.queue);
            },
            setPath(element){
                this.script.path = element.id;
            }
        }
    }
</script>
