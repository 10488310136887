<template>
    <header class="at-header sticky-top">
        <NavGlobal/>
        <NavClients v-if="currentRouteName === 'Clients'"/>
        <NavUsers v-else-if="currentRouteName === 'Users'"/>
        <NavApps v-else-if="currentRouteName === 'AppsSystem'"/>
        <NavAppsInClient v-else-if="currentRouteName === 'AppsClient'"/>
        <NavAllAppsInClient v-else-if="currentRouteName === 'AllAppsClient'"/>
        <NavScripts v-else-if="currentRouteName === 'Scripts'"/>
        <NavTeams v-else-if="currentRouteName === 'Teams'"/>
        <NavHelp v-else-if="currentRouteName === 'CrontabHelp'"/>
    </header>
</template>

<script>
    import NavGlobal from '@/components/nav/NavGlobal'
    import NavClients from '@/components/nav/NavClients'
    import NavUsers from '@/components/nav/NavUsers'
    import NavApps from '@/components/nav/NavApps'
    import NavAppsInClient from '@/components/nav/NavAppsInClient'
    import NavAllAppsInClient from "@/components/nav/NavAllAppsInClient";
    import NavScripts from '@/components/nav/NavScripts'
    import NavTeams from "@/components/nav/NavTeams";
    import NavHelp from "@/components/nav/NavHelp";

    export default {
        name: 'Header',
        components: {
            NavHelp,
            NavTeams,
            NavGlobal,
            NavClients,
            NavUsers,
            NavApps,
            NavAppsInClient,
            NavAllAppsInClient,
            NavScripts
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        }
    }
</script>
