<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="at-entity-wrapper">
                <checkbox-field
                        @dispatch-checkbox-change="dispatchCheckboxChange"
                />
                <div class="at-entity">
                    <div class="at-entity-name">
                        {{ script.name }}
                    </div>
                </div>
                <div class="at-nav-controls">
                    <button
                            type="button"
                            data-bs-toggle="collapse"
                            href="#script2-app-collapse"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body collapse show" id="script2-app-collapse">
            <div class="container">
                <div v-if="script.main" class="row">
                    <div class="col">
                        <span class="badge bg-primary">Main Script</span>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input
                                    :value="'Programming execution must be in the app instances'"
                                    name="main script warning"
                                    type="text"
                                    class="form-control"
                                    id="basic-url"
                                    aria-describedby="basic-addon3"
                                    readonly
                            />
                        </div>
                    </div>
                </div>
                <script-frequency v-else
                                  @dispatch-programming-type-change="dispatchProgrammingTypeChange"
                                  @dispatch-programming-execution-change="dispatchProgrammingExecutionChange"
                                  :programming-type="app_script.programming_type"
                                  :programming-execution="app_script.programming_execution"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CheckboxField from "@/components/commons/table-fields/checkbox";
    import ScriptFrequency from "@/components/modal/commons/script-frequency/index";

    export default {
        name: "ScriptWrapper",
        components: {
            ScriptFrequency, CheckboxField
        },
        props: {
            script: {
                type: Object,
                required: true
            },
            app_script: {
                type: Object,
                required: true
            },
        },
        data() {
            return {}
        },
        methods: {
            dispatchProgrammingTypeChange(value) {
                this.app_script.programming_type = value;
            },
            dispatchProgrammingExecutionChange(value) {
                this.app_script.programming_execution = value;
            },
            dispatchCheckboxChange(value) {
                this.$emit('dispatch-checkbox-script', value);
            }
        }
    }
</script>