<template>
    <input
            type="datetime-local"
            id="table-datetime"
            name="meeting-time"
            :value="value">
</template>
<script>
    export default {
        name: 'DatetimeField',
        props: {
            value: {
                type: String,
            }
        }
    }
</script>

<style scoped>


  #table-datetime {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#table-datetime:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>