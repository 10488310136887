import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/marketplace/apps/list/', {params});
}

/**
 * @returns Promise
 */
export function list_marketplace() {
    const params = {};
    return AxiosWrapper('/marketplace/apps/list_marketplace/', {params});
}

/**
 * @returns Promise
 * @param appId
 */
export function detail(appId) {
    const params = {appId: appId};
    return AxiosWrapper('/marketplace/apps/detail/', {params});
}

/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/marketplace/apps/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/marketplace/apps/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/marketplace/apps/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/marketplace/apps/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElement(formData) {
    return AxiosWrapper('/marketplace/apps/enable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElements(formData) {
    return AxiosWrapper('/marketplace/apps/enable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElement(formData) {
    return AxiosWrapper('/marketplace/apps/disable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElements(formData) {
    return AxiosWrapper('/marketplace/apps/disable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

