<template>
    <div class="card mb-3">
        <div class="card-header at-group-level">
            <div class="at-entity-wrapper">
                <div class="at-entity">
                    <div class="at-entity-name">
                        {{ account.name }}
                    </div>
                    <div class="at-entity-id">
                        {{ account.identifier }}
                    </div>
                </div>
                <div class="at-nav-controls">
                    <button
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#variables-account-'+account.id+'-collapse'"
                            role="button"
                            class="btn"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                    >
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body collapse" :id="'variables-account-'+account.id+'-collapse'">
            <modal-commons-table-params
                    @dispatch-selected-change="dispatchSelectedChange"
                    :config="tableConfig"
                    :data="account.params"
            />
            <div class="row mb-3">
                <div class="col">
                    <button
                            @click="deleteSelectedAccountVariable"
                            type="button"
                            class="btn btn-outline-secondary"
                            :disabled="paramsSelected.length === 0"
                    >
                        Delete variables
                    </button>
                </div>
                <div class="col">
                    <div
                            class="btn-group float-end"
                            role="group"
                            aria-label="Button group with nested dropdown"
                    >
                        <select-label-search
                                @dispatch-select-element="selectAccountVariable"
                                :options="getAccountVariables"
                                :value="accountVariableSelected"
                                :is-button-group="true"
                                default-print="Account variable name"
                        />
                        <button
                                @click="addAccountVariable()"
                                :class="['btn btn-outline-success text-nowrap', !accountVariableSelected ? 'at-disabled' : null]"
                                type="button"
                        >
                            Add variable
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {mapGetters} from "vuex";
    import _ from "lodash";
    import {list} from "@/services/account-variable";
    import {detail as ParamDetail} from "@/services/account-param";
    import ModalCommonsTableParams from "@/components/modal/commons/table-params";
    import SelectLabelSearch from "@/components/commons/select-label-search";

    export default {
        name: 'AccountParams',
        props: {
            account: {
                type: Object
            },
        },
        components: {
            SelectLabelSearch,
            ModalCommonsTableParams
        },
        data() {
            return {
                tableConfig: {
                    columns: {
                        'name': {
                            property: 'account_variable.label',
                            columnType: 'text',
                            label: 'Name'
                        },
                        'type': {
                            property: 'account_variable.type',
                            columnType: 'text',
                            label: 'Type'
                        },
                        'value': {
                            property: 'value',
                            label: 'Value'
                        },
                        'overwrite': {
                            property: 'overwrite',
                            columnType: 'boolean',
                            label: 'Overwrite'
                        }
                    },
                    hasCheckbox: true,
                    hasFooter: false,
                    defaultValueProperty: 'account_variable.default_value'
                },
                paramsSelected: [],
                accountVariables: [],
                searchAccountVariable: '',
                accountVariableSelected: null
            }
        },
        computed: {
            ...mapGetters(['getModalClient']),
            getAccountVariablesIdsByClient() {
                if (this.account) {
                    let ids = [];
                    _.forEach(this.account.params, param => {
                        ids.push(param.account_variable.id);
                    });
                    return ids;
                }
                return [];
            },
            getAccountVariables() {
                return _.filter(this.accountVariables, element => !_.includes(this.getAccountVariablesIdsByClient, element.id));
            }
        },
        created() {
            this.loadAccountVariables();
        },
        methods: {
            async loadAccountVariables() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.accountVariables = response.data.result;
            },
            async addAccountVariable() {
                let response;
                try {
                    response = await ParamDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                let param = response.data.result;
                param.account_variable = this.accountVariableSelected;
                this.account.params.push(param);

                this.accountVariableSelected = null;
            },
            deleteSelectedAccountVariable() {
                if (this.paramsSelected.length > 0) {
                    let items = [];
                    _.forEach(this.paramsSelected, item => {
                        items.push(item.id);
                    });
                    this.account.params = _.filter(_.cloneDeep(this.account.params),
                            element => !_.includes(items, element.id))
                    this.paramsSelected = [];
                }
            },
            dispatchSelectedChange(value) {
                this.paramsSelected = value;
            },
            eraseAccountVariableSearch() {
                this.searchAccountVariable = '';
            },
            selectAccountVariable(element) {
                this.accountVariableSelected = element;
            }
        }
    }
</script>