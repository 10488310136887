<template>
    <div class="input-group input-group-sm">
        <input
                :value="value"
                type="text" class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Set a value"
        >
    </div>
</template>
<script>
    export default {
        name: "InputTextField",
        props: {
            value: {
                type: String,
                required: false,
                default: null
            }
        }
    }
</script>