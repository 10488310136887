<template>
    <table class="table table-stripped table-hover table-bordered at-table">
        <thead>
        <tr>
            <th
                    v-for="header in getHeaders"
                    :key="header"
                    scope="col"
            >
                {{ header }}
            </th>
        </tr>
        </thead>
        <tbody v-if="data.length === 0">
        <tr>
            <td :colspan="getHeaders.length + 1" class="text-muted text-center pe-none">
                No data
            </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr
                v-for="row in data"
                :key="row.id"
        >
            <td>
                <div>
                    <text-field
                            :value="getPropertyColumnValue(row, getConfig(row).columns.label.property)"
                            :is-mandatory="row.app_param.is_mandatory"
                    />
                </div>
            </td>
            <td>
                <text-field
                        :value="getPropertyColumnValue(row, getConfig(row).columns.type.property)"
                />
            </td>
            <td>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <input-text-field
                                v-if="paramTypeHelper.isTextParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                        />
                        <toggle-field
                                v-else-if="paramTypeHelper.isBooleanParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                :checked="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @dispatch-toggle-changed="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"

                        />
                        <input-number-field
                                v-else-if="paramTypeHelper.isNumberParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                        />
                        <datetime-field
                                v-else-if="paramTypeHelper.isDateTimeParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                        />
                        <date-field
                                v-else-if="paramTypeHelper.isDateParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                        />
                        <select-field
                                v-else-if="paramTypeHelper.isUniqueChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))
                         && (row['app_param'].account_variable.id !== null && getAccountParam(row) !== undefined)"
                                :options="getPropertyColumnValue(row, getOptionsProperty(row))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                default-print="Select a value"
                                @dispatch-change="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"
                        />
                        <multiselect-field
                                v-else-if="paramTypeHelper.isMultipleChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))
                          && (row['app_param'].account_variable.id !== null && getAccountParam(row) !== undefined)"
                                :options="getPropertyColumnValue(row, getOptionsProperty(row))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @dispatch-multiple-select="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"
                        />
                        <select-field
                                v-else-if="paramTypeHelper.isUniqueChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))
                          && (row['app_param'].account_variable.id === null && getAccountParam(row) === null)"
                                :options="getPropertyColumnValue(row, getOptionsProperty(row))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                default-print="Select a value"
                                @dispatch-change="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"
                        />
                        <multiselect-field
                                v-else-if="paramTypeHelper.isMultipleChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))
                         && (row['app_param'].account_variable.id === null && getAccountParam(row) === null)"
                                :options="getPropertyColumnValue(row, getOptionsProperty(row))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @dispatch-multiple-select="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event)"
                        />
                        <input-json-field
                                v-else-if="paramTypeHelper.isJsonParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                @input="setPropertyColumnValue(row, getMainConfig(row).columns.value, $event.target.value)"
                        />
                    </div>
                    <span v-if="enableReset(row)" @click="resetDefault(row)">
                        <i class="fas fa-undo"></i>
                        Reset to default
                    </span>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</template>
<script>
    import _ from 'lodash';
    import InputTextField from "@/components/commons/table-fields/input-text";
    import TextField from "@/components/commons/table-fields/text";
    import SelectField from "@/components/commons/table-fields/select";
    import paramTypeFunctions from "@/helpers/param-type";
    import ToggleField from "@/components/commons/table-fields/toggle";
    import InputNumberField from "@/components/commons/table-fields/input-number";
    import MultiselectField from "@/components/commons/table-fields/multiple-select";
    import DatetimeField from "@/components/commons/table-fields/datetime";
    import DateField from "@/components/commons/table-fields/date";
    import InputJsonField from "@/components/commons/table-fields/input-json";

    export default {
        name: 'ModalAppInClientTableParams',
        props: {
            data: {
                type: Array,
            },
        },
        components: {
            MultiselectField,
            InputNumberField,
            ToggleField,
            SelectField,
            TextField,
            InputTextField,
            DatetimeField,
            DateField,
            InputJsonField
        },
        data() {
            return {
                value: true,
                tableConfig: {
                    appInClientParamConfig: {
                        columns: {
                            'value': {
                                property: 'value',
                            },
                        }
                    },
                    appParamConfig: {
                        columns: {
                            'label': {
                                property: 'app_param.label',
                                columnType: 'input',
                            },
                            'type': {
                                property: 'app_param.type',
                                columnType: 'select',
                            },
                            'value': {
                                property: 'app_param.default_value',
                            },
                        },
                    },
                    accountVariableConfig: {
                        columns: {
                            'label': {
                                property: 'app_param.account_variable.label',
                                columnType: 'text',
                            },
                            'type': {
                                property: 'app_param.account_variable.type',
                                columnType: 'text',
                            },
                            'value': {
                                property: 'app_param.account_variable.default_value',
                            }
                        },
                    },
                    headers: [
                        'Name',
                        'Type',
                        'Value'
                    ],
                },
                typeOptions: [
                    {name: 'Text', value: 'text'},
                    {name: 'Boolean', value: 'boolean'},
                    {name: 'Number', value: 'number'}
                ],
                paramTypeHelper: paramTypeFunctions
            }
        },
        created() {
            _.forEach(this.data, row => {
                if (Number.isInteger(row.id) === false) {
                    if (row.value === null || row.value === undefined) {
                        if (this.paramTypeHelper.isUniqueChoiceParam(this.getPropertyColumnValue(row, this.getConfig(row).columns.type.property))) {
                            if (this.getAccountParam(row) !== null && this.getAccountParam(row) !== undefined) {
                                row.value = this.getDefaultValueProperty(row);
                            } else {
                                row.value = this.getDefaultValueProperty(row)[0];
                            }
                        } else if (this.paramTypeHelper.isMultipleChoiceParam(this.getPropertyColumnValue(row, this.getConfig(row).columns.type.property))) {
                            row.value = [this.getDefaultValueProperty(row)[0]];
                        } else {
                            row.value = this.getDefaultValueProperty(row);
                        }
                    }
                }
            });
        },
        computed: {
            getHeaders() {
                return this.tableConfig.headers;
            },
        },
        methods: {
            enableReset(row) {
                if (this.paramTypeHelper.isUniqueChoiceParam(this.getPropertyColumnValue(row, this.getConfig(row).columns.type.property))) {
                    if (row.value) {
                        if (this.getAccountParam(row) !== null && this.getAccountParam(row) !== undefined) {
                            if (this.getDefaultValueProperty(row).value !== row.value.value) {
                                return true;
                            }
                        } else {
                            if (row.app_param.account_variable.id !== null) {
                                if (this.getDefaultValueProperty(row).value !== row.value.value) {
                                    return true;
                                }
                            } else {
                                if (this.getDefaultValueProperty(row)[0].value !== row.value.value) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                } else if (this.paramTypeHelper.isMultipleChoiceParam(this.getPropertyColumnValue(row, this.getConfig(row).columns.type.property))) {
                    if (row.value) {
                        let defaultValueOptions = []
                        if (this.getAccountParam(row) !== null && this.getAccountParam(row) !== undefined) {
                            _.forEach(this.getDefaultValueProperty(row), option => {
                                defaultValueOptions.push(option.value)
                            });
                        } else {
                            if (row.app_param.account_variable.id !== null) {
                                _.forEach(this.getDefaultValueProperty(row), option => {
                                    defaultValueOptions.push(option.value)
                                });
                            } else {
                                defaultValueOptions.push(this.getDefaultValueProperty(row)[0].value);
                            }
                        }
                        let same = true;
                        if (row.value.length === 0) {
                            return true;
                        }

                        _.forEach(row.value, option => {
                            if (!_.includes(defaultValueOptions, option.value)) {
                                same = false;
                                return;
                            }
                        });
                        console.log("Row value length: ", row.value, row.value.length);
                        return same === false;
                    }
                    return false;
                } else {
                    if (this.getDefaultValueProperty(row) !== row.value) {
                        return true;
                    }
                }

                return false;
            },
            resetDefault(row) {
                if (this.paramTypeHelper.isMultipleChoiceParam(this.getPropertyColumnValue(row, this.getConfig(row).columns.type.property))) {
                    this.setPropertyColumnValue(row, this.getMainConfig(row).columns.value, [this.getDefaultValueProperty(row)[0]]);
                } else if (this.paramTypeHelper.isUniqueChoiceParam(this.getPropertyColumnValue(row, this.getConfig(row).columns.type.property))) {
                    if (this.getAccountParam(row) !== null && this.getAccountParam(row) !== undefined) {
                        this.setPropertyColumnValue(row, this.getMainConfig(row).columns.value, this.getDefaultValueProperty(row));
                    } else {
                        if (row.app_param.account_variable.id !== null) {
                            this.setPropertyColumnValue(row, this.getMainConfig(row).columns.value, this.getDefaultValueProperty(row));
                        } else {
                            this.setPropertyColumnValue(row, this.getMainConfig(row).columns.value, this.getDefaultValueProperty(row)[0]);
                        }
                    }
                } else {
                    this.setPropertyColumnValue(row, this.getMainConfig(row).columns.value, this.getDefaultValueProperty(row));
                }
            },
            getAccountParam(row) {
                if (row['app_param'].account_variable.id !== null) {
                    return row['app_param']['account_param'];
                }
                return null;
            },
            getConfig(row) {
                if (row['app_param'].account_variable.id !== null) {
                    return this.tableConfig.accountVariableConfig;
                }
                return this.tableConfig.appParamConfig;
            },
            getMainConfig() {
                return this.tableConfig.appInClientParamConfig;
            },
            getDefaultValueProperty(row) {
                if (row['app_param'].account_variable.id !== null && this.getAccountParam(row) !== null
                        && this.getAccountParam(row) !== undefined) {
                    return this.getAccountParam(row).value;
                }
                return this.getPropertyColumnValue(row, 'app_param.default_value')
            },
            getOptionsProperty(row) {
                if (row['app_param'].account_variable.id !== null) {
                    return 'app_param.account_variable.default_value';
                }
                return 'app_param.default_value';
            },
            dispatchValueChange(id, value) {
                this.$emit('dispatch-input-value-change', {id: id, value: value});
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column, row);
            },
            setPropertyColumnValue(row, column, value) {
                _.set(row, column.property, value);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            },
        }
    }
</script>
<style lang="scss">
.toggle-custom {
    .toggle {
        min-width: 20px;
    }

    .toggle-on {
        .toggle-label {
            text-align: left;
            padding-left: 5px;
        }
    }

    .toggle-off {
        justify-content: unset;

        .toggle-label {
            padding-left: 25px;
        }
    }
}

//.switch {
//  position: relative;
//  display: inline-block;
//  width: 60px;
//  height: 34px;
//}
//
//.switch input {
//  opacity: 0;
//  width: 0;
//  height: 0;
//}
//
//.slider {
//  position: absolute;
//  cursor: pointer;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background-color: #ccc;
//  -webkit-transition: .4s;
//  transition: .4s;
//}
//
//.slider:before {
//  position: absolute;
//  content: "";
//  height: 26px;
//  width: 26px;
//  left: 4px;
//  bottom: 4px;
//  background-color: white;
//  -webkit-transition: .4s;
//  transition: .4s;
//}
//
//input:checked + .slider {
//  background-color: #2196F3;
//}
//
//input:focus + .slider {
//  box-shadow: 0 0 1px #2196F3;
//}
//
//input:checked + .slider:before {
//  -webkit-transform: translateX(26px);
//  -ms-transform: translateX(26px);
//  transform: translateX(26px);
//}
//
///* Rounded sliders */
//.slider.round {
//  border-radius: 34px;
//}
//
//.slider.round:before {
//  border-radius: 50%;
//}
</style>