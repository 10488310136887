<template>
    <label class="form-check-label" style="margin-right: 5px">
        <input
                @input="checked = $event.target.checked"
                :checked="getIsChecked"
                type="checkbox"
                class="form-check-input"
                :disabled="disabled">
    </label>
</template>

<script>
    export default {
        name: "CheckboxField",
        props: {
            tableHeaderCheckbox: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        watch: {
            checked: {
                handler: 'change'
            },
            tableHeaderCheckbox: {
                handler: 'headerChange'
            }
        },
        data() {
            return {
                checked: false
            }
        },
        computed: {
            getIsChecked() {
                return this.checked;
            }
        },
        methods: {
            change(value) {
                this.$emit('dispatch-checkbox-change', value);
            },
            headerChange(value) {
                if (!this.disabled) {
                    this.checked = value;
                }
            }
        }
    }
</script>