<template>
    <div>
        <div
                class="modal-header sticky-top"
                style="background-color: white"
        >
            <h6 data-bs-toggle="collapse" data-bs-target="#executions-app-in-client-collapse">Executions</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#executions-app-in-client-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"></i>
                </button>
            </div>
        </div>
        <div class="container mt-3 mb-3 collapse show" id="executions-app-in-client-collapse">
            <div class="row pb-2">
                <div class="col-md-5">
                    <div class="align-items">
                        <label for="statusFilter-selector" class="form-label align-self-center">Filter by
                            result:</label>
                        <button id="statusFilter-selector" type="button"
                                class="btn btn-outline-secondary dropdown-toggle ms-2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                              <span :class="['badge', statusFilter ? getstatusResultList()[statusFilter].class : getstatusResultList()['ALL'].class]">
                                {{
                                      statusFilter ? getstatusResultList()[statusFilter].label : getstatusResultList()['ALL'].label
                                  }}
                              </span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="btnGroupDrop1">
                            <li v-for="status in Object.entries(getstatusResultList())"
                                @click="statusFilter = status[0] === 'ALL' ? null : status[0]"
                                :key="status[0]">
                                <a :class="['dropdown-item']" href="#">
                                  <span class="at-dropdown-items-item">
                                      <span :class="['badge', status[1].class]">
                                          {{ status[1].label }}
                                      </span>
                                  </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="input-group me-2">
                        <input
                                @input="inputValue = $event.target.value"
                                :value="inputValue"
                                type="text" class="form-control"
                                placeholder="Search"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2">
                    </div>
                </div>
            </div>
            <modal-commons-table
                    :config="tableConfig"
                    :data="block"
            />
            <div class="row">
                <div class="col">
                    <nav aria-label="Page navigation example" class="float-end">
                        <ul class="pagination">
                            <li
                                    @click="previousPage()"
                                    :class="['page-item', !allowPrevious ? 'button-no-clickable' : null]"
                            >
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li
                                    id="page-selector"
                                    :class="['page-item', isCurrentPageClass(page)]"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style="..."
                            >
                                <button class="page-link dropdown-toggle" href="#">
                                    {{ page + 1 }}
                                </button>
                            </li>
                            <div
                                    tabindex="-1"
                                    role="menu"
                                    aria-hidden="true"
                                    class="dropdown-menu at-dropdown-menu-pages overflow-auto">
                                <div class="page-scrollbar-container ps ">
                                    <section class="at-dropdown-items_listWrap">
                                        <div class="col-xs-12">
                                        </div>
                                        <ul class="nav flex-column">
                                            <li v-for="page in getNextPages"
                                                @click="setPage(page)"
                                                :key="page"
                                            >
                                                <a :class="['dropdown-item', getNextPages === page ? 'active' : null]">
                                                    {{ page }}
                                                </a>
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <li
                                    @click="nextPage()"
                                    :class="['page-item', !allowNext ? 'button-no-clickable' : null]"
                            >
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import ModalCommonsTable from "@/components/modal/commons/table";
    import {mapGetters} from "vuex";


    export default {
        name: "AppInClientResults",
        components: {ModalCommonsTable},
        props: {
            appInClient: {
                type: Object,
            }
        },
        data() {
            return {
                page: 0,
                pageSize: 10,
                tableConfig: {
                    columns: [
                        {property: 'app_in_client_account.account.name', label: 'Account', type: 'text'},
                        {property: 'script.name', label: 'Script', type: 'text'},
                        {property: 'script.queue', label: 'Queue', type: 'text'},
                        {property: 'start_date', label: 'Starts', type: 'text'},
                        {property: 'finish_date', label: 'Ends', type: 'text'},
                        {property: 'status', label: 'Results', type: 'result'}
                    ],
                    hasCheckbox: false,
                    hasFooter: false
                },
                inputValue: null,
                statusFilter: null,
            }
        },
        computed: {
            ...mapGetters(['getConstants']),
            items() {
                return _.chunk(this.filterResults, this.pageSize);
            },
            block() {
                return this.items.length === 0 ? [] : this.items[this.page];
            },
            currentPageSize() {
                return this.block.length;
            },
            start() {
                if (this.items.length === 0) {
                    return 0;
                }
                let normalStart = (this.page * this.currentPageSize) + 1;
                let optimalStart = (this.page * this.pageSize) + 1;
                if (normalStart < optimalStart) {
                    return ((this.page - 1) * this.pageSize) + this.pageSize + 1;
                }
                return normalStart;
            },
            end() {
                if (this.items.length === 0) {
                    return 0;
                }

                let end = (this.start - 1) + this.currentPageSize;
                return this.total < end ? this.total : end;
            },
            total() {
                return this.filterResults ? this.filterResults.length : 0;
            },
            allowNext() {
                return ((this.page + 1) * this.pageSize) < this.total;
            },
            allowPrevious() {
                return this.page > 0;
            },
            getNextPages() {
                let page = 1;
                let pages = [];
                while (page <= this.items.length) {
                    pages.push(page);
                    page++;
                }
                return pages;
            },
            filterResults() {
                let filteredResults = this.appInClient['execution_results'];
                if (this.inputValue) {
                    filteredResults = _.filter(this.appInClient['execution_results'], option => this.searchValue(option));
                }
                if (this.statusFilter) {
                    filteredResults = _.filter(this.appInClient['execution_results'], option => this.filterByStatus(option));
                }
                return filteredResults
            }
        },
        methods: {
            nextPage() {
                if (this.allowNext) {
                    this.page = this.page + 1;
                }
            },
            previousPage() {
                if (this.allowPrevious) {
                    this.page = this.page - 1;
                }
            },
            setPage(page) {
                this.page = page - 1;
            },
            isCurrentPageClass(page) {
                return this.page === (page - 1) ? 'active' : null;
            },
            searchValue(item) {
                let isItemValid = false;
                _.forEach(this.tableConfig.columns, column => {
                    if (_.includes(_.toLower(this.getPropertyColumnValue(item, column)), _.toLower(this.inputValue.trim()))) {
                        isItemValid = true;
                        return null;
                    }
                });
                return isItemValid;
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column.property, row);
            },
            setPropertyColumnValue(row, column, value) {
                _.set(row, column.property, value);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            },
            getstatusResultList() {
                return this.getConstants.RESULT_STATUS_OPTIONS;
            },
            filterByStatus(item) {
                return _.includes(item.status, this.statusFilter);

            }
        }
    }
</script>