import store from "@/store"

export function isAdmin(role) {
    return role === store.state.constants.USER_ROLES.ROLE_ADMIN;
}

export function isSystem(role) {
    return role === store.state.constants.USER_ROLES.ROLE_SYSTEM;
}

export function isUser(role) {
    return role === store.state.constants.USER_ROLES.ROLE_USER;
}