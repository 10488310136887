<template>
    <div class="at-client-card me-2 mb-2">
        <div class="at-client-card-checkbox p-2">
            <input v-model="selected"
                   type="checkbox"
                   :value="team.id"
            >
        </div>
        <div class="at-client-card-entity p-2">
            <div class="at-truncate ps-2">{{ team.name }}</div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: 'UserTeam',
        props: {
            team: {
                type: Object
            },
            itemsSelected: {
                type: Array
            }
        },
        data(){
            return {
                selected: this.itemsSelected
            }
        },
        watch: {
            itemsSelected: {
                handler: function (newValue,) {
                    this.selected = newValue;
                },
            },
            selected: {
                handler: function (newValue, oldValue) {
                    if (!_.isEqual(newValue, oldValue)) {
                        this.$emit('dispatch-items-selected', newValue);
                    }
                },
            }
        }
    }
</script>
