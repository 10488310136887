<template>
    <div v-if="getModalResetPassword.show && data">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="small-modal modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <h2>Reset password for <span class="at-truncate">{{ data.username }}</span></h2>
                                </div>
                                <div class="at-nav-controls">
                                    <button
                                            @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                    ></button>
                                </div>
                            </div>
                            <div class="modal-body p-0">
                                <form id="reset-password-form" @submit.prevent="send()">
                                    <div class="container p-3">
                                        <div class="row">
                                            <div class="col col-sm-8">
                                                <label for="modal-reset-password-password-input" class="form-label">New
                                                    password</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="password = $event.target.value"
                                                            :value="password"
                                                            type="password"
                                                            class="form-control"
                                                            id="modal-reset-password-password-input"
                                                            name="reset_password_form[password]"
                                                            aria-describedby="basic-addon3"
                                                    >
                                                </div>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && !isPasswordValueValid()">
                                                    <span class="text-danger">
                                                        User new password is mandatory.
                                                    </span>
                                                </small>
                                            </div>
                                            <div v-if="password !== '' " class="col d-flex align-items-center">
                                                <div v-if="!isPasswordConfirmationEqual()"
                                                     class="d-flex align-items-center ms-2">
                                                    <i class="fa fa-times-circle" style="color: red"></i>
                                                </div>
                                                <div v-if="isPasswordConfirmationEqual()"
                                                     class="d-flex align-items-center ms-2">
                                                    <i class="fa fa-check-circle" style="color: green"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col col-sm-8">
                                                <label for="modal-reset-password-confirmation-password-input"
                                                       class="form-label">Confirm new password</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="confirmationPassword = $event.target.value"
                                                            :value="confirmationPassword"
                                                            type="password"
                                                            class="form-control"
                                                            id="modal-reset-password-confirmation-password-input"
                                                            name="user_form[confirmation_password]"
                                                            aria-describedby="basic-addon3"
                                                    >
                                                </div>
                                            </div>
                                            <div v-if="password !== '' " class="col d-flex align-items-center">
                                                <div v-if="!isPasswordConfirmationEqual()"
                                                     class="d-flex align-items-center ms-2">
                                                    <i class="fa fa-times-circle" style="color: red"></i>
                                                </div>
                                                <div v-if="isPasswordConfirmationEqual()"
                                                     class="d-flex align-items-center ms-2">
                                                    <i class="fa fa-check-circle" style="color: green"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="reset-password-form"
                                >
                                    <i class="fas fa-key me-2"/>
                                    Change password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import {detail, resetPassword} from "@/services/user";
    import _ from "lodash";
    import {getCookie} from "@/helpers/get-cookie";
    import {mapGetters} from "vuex";

    export default {
        name: 'ModalResetPassword',
        data() {
            return {
                id: null,
                password: '',
                confirmationPassword: '',
                submitted: false,
                data: null,
            }
        },
        computed: {
            ...mapGetters(['getModalResetPassword', 'getConstants'])
        },
        mounted() {
            this.$store.watch(state => state.modalResetPassword, (value) => {
                if (value.show) {
                    this.id = value.id;
                    this.loadUser(value.id);
                }
            }, {deep: true});
        },
        methods: {
            async loadUser() {
                let response;
                try {
                    response = await detail(this.id);
                } catch (e) {
                    return;
                }

                if (this.id !== null && response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering user details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }

                this.data = response.data.result;
            },
            async save() {
                let response;
                try {
                    let formElement = document.querySelector('#reset-password-form');
                    let formData = new FormData(formElement);
                    formData.append('id', this.id);
                    formData.append('password', this.password);
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));

                    response = await resetPassword(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed resetting the password",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                this.close();

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            },
            send() {
                this.submitted = true;

                let validations = [];
                validations.push(this.isPasswordValueValid());
                validations.push(this.isPasswordConfirmationEqual());

                if (!_.includes(validations, false)) {
                    this.save();
                }
            },
            isPasswordValueValid() {
                return this.password !== ''
            },
            isPasswordConfirmationEqual() {
                return this.password === this.confirmationPassword && this.password !== '';
            },
            close() {
                this.reset();
                _.set(this.getModalResetPassword, 'id', null);
                _.set(this.getModalResetPassword, 'show', false);
            },
            reset() {
                this.data = null;
                this.password = '';
                this.confirmationPassword = '';
                this.submitted = false;
            },
        }
    }
</script>