<template>
    <div v-if="getModalAppInClient.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div v-if="!data" class="loader at-inner-loader" id="preload"></div>
                        <div v-if="data" class="modal-content">
                            <div v-if="saving" class="modal-overlay"></div>
                            <div :class="['modal-header', saving ? 'modal-wt-pointer' : null]">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>Apps in client</a>
                                        </li>
                                    </ol>
                                    <div class="d-flex align-items-baseline">
                                        <Popper
                                                class="light"
                                                arrow
                                                placement="right"
                                                :show="showPopper"
                                        >
                                        <!-- <h2 class="at-truncate"
                                                @mouseover="addShowPopper()"
                                                @mouseleave="removeShowPopper()"
                                            >-->
                                          <h2 class=""
                                                @mouseover="addShowPopper()"
                                                @mouseleave="removeShowPopper()"
                                            >

                                                {{ getName }}
                                            </h2>
                                            <template #content>
                                                <div>{{ getName }}</div>
                                            </template>
                                        </Popper>
                                        <div class="d-flex ms-4 form-check form-switch">
                                            <input @input="changeStatus($event.target.checked)"
                                                   :checked="getAppInClientChecked"
                                                   class="form-check-input"
                                                   type="checkbox"
                                                   role="switch"
                                                   id="flexSwitchCheckChecked"
                                                   :disabled="isClientDisabled()"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="at-nav-controls align-items-center">
                                    <button v-if="isSystemUser" @click="executeDebug()"
                                            type="button"
                                            :class="['btn btn-warning', !isAppDebuggable ? 'at-disabled' : null]"
                                    >
                                        Execute debug
                                    </button>
                                    <button @click="executeNow()"
                                            type="button"
                                            class="btn btn-warning ms-1">
                                        Execute now
                                    </button>
                                    <button @click="close()"
                                            type="button"
                                            class="btn-close ms-4"
                                            data-bs-dismiss="modal">
                                    </button>
                                </div>
                            </div>
                            <div :class="['modal-body p-0', saving ? 'modal-wt-pointer' : null]">
                                <form id="app-in-client-form" @submit.prevent="send()">
                                    <app-in-client-detail
                                            ref="detail"
                                            :app-in-client="data"
                                    />
                                    <set-up
                                            ref="setup"
                                            :app-in-client="data"
                                    />
                                    <app-in-client-results
                                            :app-in-client="data"
                                    />
                                </form>
                            </div>
                            <div :class="['modal-footer', saving ? 'modal-wt-pointer' : null]">

                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="app-in-client-form"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                            <div v-if="saving">
                                <div class="loader at-inner-loader" id="preload"></div>
                                <span class="loading" style="">Saving changes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import {detail as DetailAppInClient, store, edit, executeNow, executeDebug} from '@/services/app-in-client';
    import AppInClientDetail from "@/components/modal/modal-app-in-client/detail";
    import {mapGetters} from "vuex";
    import {getCookie} from "@/helpers/get-cookie";
    import _ from "lodash"
    import AppInClientResults from "@/components/modal/modal-app-in-client/app-in-client-results";
    import SetUp from "@/components/modal/modal-app-in-client/setup";
    import {isSystem} from "@/helpers/user-roles";

    export default {
        name: "ModalAppInClient",
        components: {
            SetUp,
            AppInClientResults,
            AppInClientDetail
        },
        data() {
            return {
                data: null,
                id: null,
                appId: null,
                client: null,
                appInClientStatus: null,
                showPopper: false,
                saving: false,
            }
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        computed: {
            ...mapGetters(['getModalAppInClient', 'getCurrentClient', 'getConstants', 'getUser']),
            isSystemAppType() {
                return this.data.app.type === this.getConstants.APP_TYPE_SYSTEM;
            },
            isSystemUser() {
                return isSystem(this.getUser.role);
            },
            isAppDebuggable() {
                return this.data.app.is_debuggable;
            },
            getName() {
                // return this.data.app.name + ' - ' + this.data.suffix;
              return this.data.suffix;
            },
            getAppInClientChecked() {
                if (this.data.status === this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        mounted() {
            this.$store.watch(state => state.modalAppInClient, (value) => {
                if (value.show) {
                    this.id = value.id;
                    this.loadAppInClient(value.id);
                }
            }, {deep: true});
        },
        methods: {
            setAppInClientStatus(value) {
                if (value) {
                    this.data.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_ENABLED;
                } else {
                    this.data.status = this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED;
                }
                _.forEach(this.data.accounts, account => {
                    _.set(account, 'status', this.data.status);
                });
            },
            async loadAppInClient(id) {
                let response;
                try {
                    if (this.getCurrentClient !== null) {
                        response = await DetailAppInClient(id, this.getCurrentClient.id, this.getModalAppInClient.appId);
                    } else {
                        response = await DetailAppInClient(id, null, null);
                    }
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                this.data = response.data.result;
            },
            async save() {
                this.saving = true;
                let response;
                let formElement = document.querySelector('#app-in-client-form');
                let formData = new FormData(formElement);
                formData.append('id', this.id);
                formData.append('status', this.data.status);
                formData.append('suffix', this.data.suffix);
                formData.append('app', this.data.app.id);
                formData.append('client', this.data.client.id);
                formData.append('accounts', JSON.stringify(this.data.accounts));
                formData.append('app_scripts', JSON.stringify(this.data.app_scripts));
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));

                try {
                    response = this.id === null ? await store(formData) : await edit(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed applying the changes",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                _.set(this.getModalAppInClient, 'result', response.data.result);
                this.close();
                this.saving = false;

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            },
            close() {
                _.set(this.getModalAppInClient, 'id', null);
                _.set(this.getModalAppInClient, 'show', false);
                this.data = null;
                this.id = null;
                this.deleteQueryParam('appInClientId');
            },
            send() {
                let validForm = [];
                validForm.push(this.$refs.detail.validateForm());
                validForm.push(this.$refs.setup.validateForm());
                // validForm.push(this.$refs.globalVariables.validateForm());
                // validForm.push(this.$refs.groupVariables.validateForm());

                if (!_.includes(validForm, false)) {
                    this.save();
                } else {
                    this.$toast.open({
                        message: 'Please, for saving fill the mandatory variables and the app in client suffix',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                }
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            },
            async executeNow() {
                let response;

                try {
                    response = await executeNow(this.id);
                } catch (e) {
                    return e;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                this.close()

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
            async executeDebug() {
                let response;

                try {
                    response = await executeDebug(this.id);
                } catch (e) {
                    return e;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                this.close()

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
            isClientDisabled() {
                return this.getConstants.ELEMENTS_VIEW_MODE_LIST.ELEMENTS_VIEW_MODE_DISABLED === this.data.client.status;
            },
            addShowPopper() {
                this.showPopper = true;
            },
            removeShowPopper() {
                this.showPopper = false;
            },
            changeStatus(status) {
                this.setAppInClientStatus(status);
            }
        }
    }
</script>