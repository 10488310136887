<template>
    <div>
        <div class="modal-header sticky-top">
            <h6 data-bs-toggle="collapse" data-bs-target="#client-apps-collapse">Apps</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#client-apps-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="client-apps-collapse">
            <div class="row">
                <div class="col">
                    <div class="at-tree mb-2">
                        <tree-view
                                id="my-tree"
                                :initial-model="client['apps_tree']"
                                :model-defaults="modelDefaults"
                        >
                            <template v-slot:text="{ model, customClasses }">
                                <div class="d-flex align-items-center">
                                    <div class="ms-1 mt-1 mb-1">
                                        <i :class="model.icon"></i>
                                        <span class="ms-1">{{ model.label }}</span>
                                    </div>
                                </div>
                            </template>
                        </tree-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import TreeView from "@grapoza/vue-tree"

    export default {
        name: 'ClientApps',
        components: {
            TreeView,
        },
        props: {
            client: {
                type: Object
            }
        },
        data() {
            return {
                modelDefaults: {
                    state: {
                        expanded: true,
                    },
                    customizations: {
                        classes: {
                            treeViewNodeSelfExpandedIndicator: 'fas fa-chevron-right',
                            treeViewNodeSelf: 'large-line',
                            treeViewNodeSelfText: 'big-text'
                        },
                    }
                },
            };
        },
    }
</script>