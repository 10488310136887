import {createApp} from 'vue';

import App from '@/components/Index';

import store from './store';
import router from './router';

import Popper from "vue3-popper";
import VueToast from 'vue-toast-notification';
import VueTimepicker from 'vue3-timepicker'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {MonthPicker} from 'vue-month-picker'
import {MonthPickerInput} from 'vue-month-picker'
import 'vue3-timepicker/dist/VueTimepicker.css'

import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-toast-notification/dist/theme-sugar.css';

import '@fortawesome/fontawesome-free/js/all.js'
import vue3GoogleLogin from 'vue3-google-login'


createApp(App)
    .use(store)
    .use(router)
    .use(VueToast)
    .use(VueTimepicker)
    .use(MonthPicker)
    .use(MonthPickerInput)
    .use(Datepicker)
    .use(Popper)
    .use(vue3GoogleLogin, {
        clientId: '249357993466-0dnrk6gpak7lsq0b0dhc1dnt47dm1unh.apps.googleusercontent.com',
    })
    .mount('#app');