import {createWebHistory, createRouter} from "vue-router";
import AxiosWrapper from "@/helpers/axios-wrapper";
import _ from 'lodash';

const constants =  Object.freeze({
RESPONSE_CODE_SUCCESS: 0,
ROLE_USER: 'User',
ROLE_ADMIN:'Admin',
})


const routes = [
    {
        path: '/',
        redirect: {name: 'Clients'},
        meta: { excludeRoles: [] }
    },
    {
        path: '/apps-client',
        name: 'AppsClient',
        component: () => import('@/pages/AppsClient'),
        meta: { excludeRoles: [] }
    },
    {
        path: '/all-apps-client',
        name: 'AllAppsClient',
        component: () => import('@/pages/AllAppsClient'),
        meta: { excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN] }
    },
    {
        path: '/apps-system',
        name: 'AppsSystem',
        component: () => import('@/pages/AppsSystem'),
        meta: { excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/teams',
        name: 'Teams',
        component: () => import('@/pages/Teams'),
        meta: { excludeRoles: [constants.ROLE_USER]}
    },
    {
        path: '/clients',
        name: 'Clients',
        component: () => import('@/pages/Clients'),
        meta: { excludeRoles: [] }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('@/pages/Users'),
        meta: { excludeRoles: [constants.ROLE_USER]}
    },
    {
        path: '/scripts',
        name: 'Scripts',
        component: () => import('@/pages/Scripts'),
        meta: { excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/login/',
        name: 'Login',
        component: () => import('@/pages/Login'),
        meta: { excludeRoles: [] }
    },
    {
        path: '/help/crontab',
        name: 'CrontabHelp',
        component: () => import('@/pages/CrontabHelp'),
        meta: { excludeRoles: [] }
    },
    {
        path: '/admin',
        name: 'Admin',
        beforeEnter() {location.href = 'admin/'}
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.path === '/login/' || to.path === '/login') {
        next()
    } else {
        AxiosWrapper('/users/session/user/', {})
            .then(function (response) {
                if (response.data.status === constants.RESPONSE_CODE_SUCCESS) {
                    if (_.includes(to.meta.excludeRoles, response.data.result.role)) {
                        next('/');
                    } else {
                        next();
                    }
                } else {
                    next('/login/')
                }
            })
            .catch(function (error) {
                console.log("Error: ", error)
                next('/login/')
            });
    }
});


export default router;