<template>
    <button @click="action()" class="btn btn-outline-secondary btn-sm">
        <i class="fas fa-eye me-2"></i>
        {{ value }}
    </button>
</template>

<script>
    export default {
        name: "ButtonField",
        props: {
            value: {
                type: String,
                required: true
            },
            id: {
                type: Number,
                required: true
            }
        },
        methods: {
            action() {
                this.$emit('dispatch-button-action', this.id);
            }
        }
    }
</script>