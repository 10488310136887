<template>

    <table class="table table-stripped table-hover table-bordered at-table">
        <thead>
        <tr>
            <th
                    v-if="config.hasCheckbox"
                    scope="col"
                    class="at-checkbox-cell"
            >
                <label>
                    <input
                            @input="checked = $event.target.checked"
                            :checked="getHeaderIsChecked"
                            type="checkbox"
                    >
                </label>
            </th>
            <th
                    v-for="column in getColumns"
                    :key="config.columns[column].property"
                    scope="col"
            >
                {{ config.columns[column].label }}
            </th>
        </tr>
        </thead>
        <tbody v-if="data.length === 0">
        <tr>
            <td :colspan="getColumns.length + 1" class="text-muted text-center pe-none">
                No data
            </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr
                v-for="row in data"
                :key="row.id"
        >
            <td v-if="config.hasCheckbox">
                <checkbox-field
                        @dispatch-checkbox-change="dispatchCheckboxChange(row.id, $event)"
                        :tableHeaderCheckbox="tableHeaderCheckbox"
                />
            </td>
            <td>
                <input-text-field v-if="config.columns.name.columnType === 'input'"
                                  :value="getPropertyColumnValue(row, config.columns.name.property)"
                                  @input="setPropertyColumnValue(row, config.columns.name, $event.target.value)"

                />
                <text-field v-else
                            :value="getPropertyColumnValue(row, config.columns.name.property)"
                />
            </td>
            <td>
                <select-field v-if="config.columns.type.columnType === 'select'"
                              :value="getPropertyColumnValue(row, config.columns.type.property)"
                              :options="typeOptions"
                              default-print="Select a value"
                />
                <text-field v-else
                            :value="getPropertyColumnValue(row, config.columns.type.property)"
                />
            </td>
            <td>
                <input-text-field
                        v-if="paramTypeHelper.isTextParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        @input="setPropertyColumnValue(row, config.columns.value, $event.target.value)"
                />
                <toggle-field
                        v-else-if="paramTypeHelper.isBooleanParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :checked="getPropertyColumnValue(row, config.columns.value.property)"
                        @dispatch-toggle-changed="setPropertyColumnValue(row, config.columns.value, $event)"
                />
                <input-number-field
                        v-else-if="paramTypeHelper.isNumberParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        @input="setPropertyColumnValue(row, config.columns.value, $event.target.value)"
                />
                <datetime-field
                        v-else-if="paramTypeHelper.isDateTimeParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        @input="setPropertyColumnValue(row, config.columns.value, $event.target.value)"
                />
                <date-field
                        v-else-if="paramTypeHelper.isDateParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        @input="setPropertyColumnValue(row, config.columns.value, $event.target.value)"
                />
                <select-field
                        v-else-if="paramTypeHelper.isUniqueChoiceParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :options="getPropertyColumnValue(row, config.defaultValueProperty)"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        default-print="Select a value"
                        @dispatch-change="setPropertyColumnValue(row, config.columns.value, $event)"
                />
                <multiselect-field
                        v-else-if="paramTypeHelper.isMultipleChoiceParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :options="getPropertyColumnValue(row, config.defaultValueProperty)"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        @dispatch-multiple-select="setPropertyColumnValue(row, config.columns.value, $event)"
                />
              <input-json-field
                        v-else-if="paramTypeHelper.isJsonParam(getPropertyColumnValue(row, config.columns.type.property))"
                        :value="getPropertyColumnValue(row, config.columns.value.property)"
                        @input="setPropertyColumnValue(row, config.columns.value, $event.target.value)"
                />
            </td>
            <td class="text-center">
                <checkbox-field
                        @dispatch-checkbox-change="row.overwrite=$event"
                        :tableHeaderCheckbox="tableHeaderCheckbox"
                />
            </td>
        </tr>
        </tbody>
        <tfoot v-if="config.hasFooter">
        <tr>
            <th
                    v-if="config.hasCheckbox"
                    scope="col"
                    class="at-checkbox-cell"
            >
                <label>
                    <input
                            :checked="getFooterIsChecked"
                            @input="checked = $event.target.checked"
                            type="checkbox"
                    >
                </label>
            </th>
            <th
                    v-for="column in getColumns"
                    :key="config.columns[column].property"
                    scope="col"
            >
                {{ config.columns[column].label }}
            </th>
        </tr>
        </tfoot>
    </table>
</template>

<script>
    import _ from 'lodash';
    import moment from 'vue-moment';
    import CheckboxField from "@/components/commons/table-fields/checkbox";
    import InputTextField from "@/components/commons/table-fields/input-text";
    import TextField from "@/components/commons/table-fields/text";
    import SelectField from "@/components/commons/table-fields/select";
    import paramTypeFunctions from "@/helpers/param-type";
    import ToggleField from "@/components/commons/table-fields/toggle";
    import InputNumberField from "@/components/commons/table-fields/input-number";
    import MultiselectField from "@/components/commons/table-fields/multiple-select";
    import DatetimeField from "@/components/commons/table-fields/datetime";
    import DateField from "@/components/commons/table-fields/date";
    import InputJsonField from "@/components/commons/table-fields/input-json";

    export default {
        name: 'ModalCommonsTableParams',
        props: {
            config: {
                type: Object,
                required: true,
            },
            data: {
                type: Array,
                required: true,
            },
        },
        components: {
            DateField,
            DatetimeField,
            MultiselectField,
            InputNumberField,
            ToggleField,
            SelectField,
            TextField,
            InputTextField,
            CheckboxField,
            InputJsonField
        },
        watch: {
            checked: {
                handler: 'change'
            }
        },
        data() {
            return {
                selectedItems: [],
                checked: false,
                tableHeaderCheckbox: false,
                typeOptions: [
                    {name: 'Text', value: 'text'},
                    {name: 'Boolean', value: 'boolean'},
                    {name: 'Number', value: 'number'}
                ],
                paramTypeHelper: paramTypeFunctions
            }
        },
        computed: {
            getHeaderIsChecked() {
                return this.checked;
            },
            getFooterIsChecked() {
                return this.checked;
            },
            getColumns() {
                return _.keys(this.config.columns);
            },
        },
        methods: {
            dispatchCheckboxChange(id, value) {
                if (value === true && !_.some(this.selectedItems, {value: value, id: id})) {
                    this.selectedItems.push({value: value, id: id});
                } else if (value === false && _.some(this.selectedItems, {value: true, id: id})) {
                    this.selectedItems.splice(this.selectedItems.indexOf({value: true, id: id}), 1)
                }
                this.$emit('dispatch-selected-change', this.selectedItems);
            },
            change(value) {
                this.tableHeaderCheckbox = value;
            },
            dispatchButtonAction(value) {
                this.$emit('dispatch-button-clicked', value);
            },
            dispatchValueChange(id, value) {
                this.$emit('dispatch-input-value-change', {id: id, value: value});
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column, row);
            },
            setPropertyColumnValue(row, column, value) {
                _.set(row, column.property, value);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            }
        }
    }
</script>