<template>
    <div>
        <ModalClient />
        <ModalCredentials />
        <ModalMarketplace />
        <ModalAppsFilter />
        <ModalScript />
        <ModalUser />
        <ModalApp />
        <ModalAppInClient />
        <ModalTeam />
        <ModalResetPassword />
        <ModalOptionsParameters />
    </div>
</template>

<script>
    import ModalClient from '@/components/modal/modal-client'
    import ModalCredentials from '@/components/modal/modal-credential'
    import ModalMarketplace from '@/components/modal/modal-marketplace'
    import ModalAppsFilter from '@/components/modal/modal-apps-filter'
    import ModalScript from '@/components/modal/modal-script'
    import ModalApp from '@/components/modal/modal-app'
    import ModalAppInClient from '@/components/modal/modal-app-in-client'
    import ModalUser from "@/components/modal/modal-user";
    import ModalTeam from "@/components/modal/modal-team";
    import ModalResetPassword from "@/components/modal/modal-reset-password";
    import ModalOptionsParameters from "@/components/modal/modal-options-parameters";

    export default {
        name: 'Modal',
        components: {
            ModalOptionsParameters,
            ModalResetPassword,
            ModalTeam,
            ModalUser,
            ModalClient,
            ModalCredentials,
            ModalMarketplace,
            ModalAppsFilter,
            ModalScript,
            ModalApp,
            ModalAppInClient,
        },
    }
</script>
