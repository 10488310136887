<template>
  <div class="input-group input-group-sm">
        <textarea
            rows="1"
            :value="value"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            placeholder="Set a value"
        >
          </textarea>
  </div>
</template>
<script>
export default {
  name: "InputJSONField",
  props: {
    value: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>