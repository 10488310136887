<template>
    <div>
        <div class="modal-header sticky-top">
            <h6 data-bs-target="#variables-app-collapse" data-bs-toggle="collapse">Variables</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#variables-app-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="variables-app-collapse">
            <modal-app-variables-table-params
                    @dispatch-selected-change="dispatchSelectedChange"
                    :data="app.params"
                    origin="app_params"
            />
            <div class="row mb-3">
                <div class="col col-md-5">
                    <button @click="deleteSelectedAppVariable()"
                            type="button"
                            :class="['btn btn-outline-secondary', paramsSelected.length === 0 ? 'at-disabled' : null]"
                    >
                        Delete selected variables
                    </button>
                </div>
                <div class="col col-md-2">
                    <button
                            @click="addAppParam()"
                            class="btn btn-outline-success text-nowrap ms-auto"
                            type="button"
                    >
                        Add variable
                    </button>
                </div>
                <div class="col col-md-5">
                    <div
                            class="btn-group float-end"
                            role="group"
                            aria-label="Button group with nested dropdown"
                    >
                        <select-label-search
                                @dispatch-select-element="selectAccountVariable"
                                :options="getAccountVariables"
                                :value="accountVariableSelected"
                                :is-button-group="true"
                                default-print="Account variable name"
                        />
                        <button
                                @click="addAccountVariable()"
                                :class="['btn btn-outline-success text-nowrap', !accountVariableSelected ? 'at-disabled' : null]"
                                type="button"
                        >
                            Add account variable
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import _ from 'lodash';
    import {list} from "@/services/account-variable";
    import {detail as ParamDetail} from "@/services/app-param";
    import ModalAppVariablesTableParams from "@/components/modal/modal-app/app-variables/table-params";
    import SelectLabelSearch from "@/components/commons/select-label-search";

    export default {
        name: "AppVariables",
        props: {
            app: {
                type: Object,
                required: true
            }
        },
        components: {
            SelectLabelSearch,
            ModalAppVariablesTableParams,
        },
        data() {
            return {
                paramsSelected: [],
                accountVariables: [],
                searchAccountVariable: '',
                accountVariableSelected: null,
                tableConfig: {
                    appParamConfig: {
                        columns: {
                            'label': {
                                property: 'label',
                                columnType: 'input',
                            },
                            'name': {
                                property: 'name',
                                columnType: 'input',
                            },
                            'type': {
                                property: 'type',
                                columnType: 'select',
                            },
                            'is_mandatory': {
                                property: 'is_mandatory',
                                columnType: 'text',
                            },
                            'value': {
                                property: 'default_value',
                            }
                        },
                    },
                    accountVariableConfig: {
                        columns: {
                            'label': {
                                property: 'account_variable.label',
                                columnType: 'text',
                            },
                            'name': {
                                property: 'account_variable.name',
                                columnType: 'text',
                            },
                            'type': {
                                property: 'account_variable.type',
                                columnType: 'text',
                            },
                            'is_mandatory': {
                                property: 'account_variable.is_mandatory',
                                columnType: 'text',
                            },
                            'value': {
                                property: 'account_variable.default_value',
                            }
                        },
                    },
                }
            }
        },
        computed: {
            ...mapGetters(['getModalApp']),
            getAccountVariablesIdsByApp() {
                if (this.app) {
                    let ids = [];
                    _.forEach(this.app.params, param => {
                        ids.push(param.account_variable.id);
                    });
                    return ids;
                }
                return [];
            },
            getAccountVariables() {
                return _.filter(this.accountVariables, element => !_.includes(this.getAccountVariablesIdsByApp, element.id));
            }
        },
        created() {
            this.loadAccountVariables();
        },
        methods: {
            async loadAccountVariables() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                this.accountVariables = response.data.result;
            },
            async addAccountVariable() {
                let response;
                try {
                    response = await ParamDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                let param = response.data.result;
                param.account_variable = this.accountVariableSelected;
                this.app.params.push(param);

                this.accountVariableSelected = null;
            },
            async addAppParam() {
                let response;
                try {
                    response = await ParamDetail();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                let param = response.data.result;
                this.app.params.push(param);
            },
            dispatchSelectedChange(value) {
                this.paramsSelected = value;
            },
            deleteSelectedAppVariable() {
                if (this.paramsSelected.length > 0) {
                    let items = [];
                    _.forEach(this.paramsSelected, item => {
                        items.push(item.id);
                    });
                    this.app.params = _.filter(_.cloneDeep(this.app.params),
                            element => !_.includes(items, element.id))
                    this.paramsSelected = [];
                }
            },
            selectAccountVariable(element) {
                this.accountVariableSelected = element;
            },
            setPropertyColumnValue(row, column, value) {
                _.set(row, column.property, value);
            },
            dispatchTableChanged(data){
                this.app.params = data;
            }
        }

    }

</script>

<style lang="scss" scoped>

</style>